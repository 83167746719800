import axios from 'axios';

const ZORO_BASE_URL = 'https://consumet-api-omega-one.vercel.app/anime/zoro';

/**
 * Fetch anime information from Zoro
 * @param {string} id - The Zoro ID of the anime
 * @returns {Promise<Object>} Anime information including episodes
 */
export const fetchZoroAnimeInfo = async (id) => {
  try {
    const { data } = await axios.get(`${ZORO_BASE_URL}/info`, {
      params: { id }
    });

    // Transform and validate the response
    return {
      id: data.id || '',
      title: data.title || '',
      url: data.url || '',
      image: data.image || '',
      releaseDate: data.releaseDate || null,
      description: data.description || '',
      genres: data.genres || [],
      subOrDub: data.subOrDub || 'sub',
      type: data.type || '',
      status: data.status || 'Unknown',
      otherName: data.otherName || '',
      totalEpisodes: data.totalEpisodes || 0,
      episodes: (data.episodes || []).map(episode => ({
        id: episode.id || '',
        number: episode.number || 0,
        url: episode.url || ''
      }))
    };
  } catch (error) {
    console.error('Error fetching Zoro anime info:', error);
    throw new Error(`Failed to fetch anime info: ${error.message}`);
  }
};

/**
 * Search for anime on Zoro
 * @param {string} query - Search query
 * @param {number} page - Page number (optional)
 * @returns {Promise<Array>} Search results
 */
export const searchZoroAnime = async (query, page = 1) => {
  try {
    const { data } = await axios.get(`${ZORO_BASE_URL}/search`, {
      params: {
        query,
        page
      }
    });
    return data.results || [];
  } catch (error) {
    console.error('Error searching Zoro anime:', error);
    return [];
  }
};

/**
 * Get streaming sources for a specific episode
 * @param {string} episodeId - The episode ID
 * @returns {Promise<Object>} Streaming sources
 */
export const getZoroEpisodeSources = async (episodeId) => {
  try {
    const { data } = await axios.get(`${ZORO_BASE_URL}/watch`, {
      params: { episodeId }
    });
    return data;
  } catch (error) {
    console.error('Error fetching episode sources:', error);
    throw new Error(`Failed to fetch episode sources: ${error.message}`);
  }
};

/**
 * Get episode servers for a specific episode
 * @param {string} episodeId - The episode ID
 * @returns {Promise<Array>} Available servers
 */
export const getZoroEpisodeServers = async (episodeId) => {
  try {
    const { data } = await axios.get(`${ZORO_BASE_URL}/servers`, {
      params: { episodeId }
    });
    return data;
  } catch (error) {
    console.error('Error fetching episode servers:', error);
    return [];
  }
};

/**
 * Safe wrapper for API calls
 * @param {Function} apiCall - The API call function to execute
 * @param {any} defaultValue - Default value to return on error
 * @returns {Promise<any>} The API response or default value
 */
export const safeZoroApiCall = async (apiCall, defaultValue = null) => {
  try {
    return await apiCall();
  } catch (error) {
    console.error('Zoro API call failed:', error);
    return defaultValue;
  }
};

// Example usage:
/*
const getAnimeWithEpisodes = async (animeId) => {
  const animeInfo = await fetchZoroAnimeInfo(animeId);
  if (animeInfo && animeInfo.episodes.length > 0) {
    const firstEpisode = animeInfo.episodes[0];
    const sources = await getZoroEpisodeSources(firstEpisode.id);
    const servers = await getZoroEpisodeServers(firstEpisode.id);
    return {
      ...animeInfo,
      firstEpisode: {
        ...firstEpisode,
        sources,
        servers
      }
    };
  }
  return animeInfo;
};
*/
