// src/components/LoaderSpinner.jsx
import React from 'react';
import { motion } from 'framer-motion';

const LoaderSpinner = ({ 
  type = 'default', 
  fullScreen = true, 
  color = 'gold' 
}) => {
  // Color variants
  const colorVariants = {
    gold: {
      primary: 'text-gold-500',
      secondary: 'text-gold-300',
      background: 'bg-gold-500/10'
    },
    white: {
      primary: 'text-white',
      secondary: 'text-gray-200',
      background: 'bg-white/10'
    },
    black: {
      primary: 'text-black',
      secondary: 'text-gray-700',
      background: 'bg-background/10'
    }
  };

  const currentColors = colorVariants[color] || colorVariants.gold;

  // Spinner Variations
  const spinnerTypes = {
    default: (
      <motion.div 
        className={`${fullScreen ? 'fixed inset-0 flex items-center justify-center z-50 bg-background/30 backdrop-blur-sm' : ''}`}
      >
        <motion.div
          className={`w-24 h-24 border-4 ${currentColors.primary} border-opacity-30 rounded-full border-t-4 border-t-current`}
          animate={{ rotate: 360 }}
          transition={{ 
            duration: 1, 
            repeat: Infinity, 
            ease: "linear" 
          }}
        />
      </motion.div>
    ),

    cosmic: (
      <motion.div 
        className={`${fullScreen ? 'fixed inset-0 flex items-center justify-center z-50 bg-gradient-to-br from-black via-gray-900 to-black' : ''}`}
      >
        <motion.div 
          className="relative"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className={`absolute w-24 h-24 rounded-full ${currentColors.background}`}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ 
                scale: [0.5, 1.2, 1], 
                opacity: [0, 1, 0] 
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.7,
                ease: "easeInOut"
              }}
            />
          ))}
          <motion.div
            className={`relative w-24 h-24 border-4 ${currentColors.primary} border-opacity-30 rounded-full border-t-4 border-t-current`}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 1, 
              repeat: Infinity, 
              ease: "linear" 
            }}
          />
        </motion.div>
      </motion.div>
    ),

    gradient: (
      <motion.div 
        className={`${fullScreen ? 'fixed inset-0 flex items-center justify-center z-50 bg-background/30 backdrop-blur-sm' : ''}`}
      >
        <motion.div 
          className="relative"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className={`w-24 h-24 rounded-full bg-gradient-to-r from-transparent via-${color}-500 to-transparent`}
            animate={{
              rotate: [0, 360],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          <motion.div
            className={`absolute inset-2 bg-background rounded-full`}
          />
        </motion.div>
      </motion.div>
    ),

    particle: (
      <motion.div 
        className={`${fullScreen ? 'fixed inset-0 flex items-center justify-center z-50 bg-background/30 backdrop-blur-sm' : ''}`}
      >
        <motion.div 
          className="flex space-x-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ staggerChildren: 0.2 }}
        >
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={i}
              className={`w-4 h-4 ${currentColors.primary} rounded-full`}
              variants={{
                initial: { scale: 0 },
                animate: { 
                  scale: [0, 1.2, 1],
                  opacity: [0.5, 1, 0.5]
                }
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: i * 0.2
              }}
            />
          ))}
        </motion.div>
      </motion.div>
    ),

    text: (
      <motion.div 
        className={`${fullScreen ? 'fixed inset-0 flex items-center justify-center z-50 bg-background/30 backdrop-blur-sm' : ''}`}
      >
        <motion.div 
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.h2
            className={`text-4xl font-bold ${currentColors.primary}`}
            animate={{
              scale: [1, 1.1, 1],
              opacity: [0.6, 1, 0.6]
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity
            }}
          >
            Streamify
          </motion.h2>
          <motion.p
            className={`${currentColors.secondary} mt-2`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Loading your entertainment...
          </motion.p>
        </motion.div>
      </motion.div>
    )
  };

  // Return the selected spinner type or default
  return spinnerTypes[type] || spinnerTypes.default;
};

// Preset Loader Components for Easy Use
export const CosmicLoader = () => <LoaderSpinner type="cosmic" />;
export const GradientLoader = () => <LoaderSpinner type="gradient" />;
export const ParticleLoader = () => <LoaderSpinner type="particle" />;
export const TextLoader = () => <LoaderSpinner type="text" />;

export default LoaderSpinner;