import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, X, Command } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({
  onSearch,
  isLoading,
  initialValue = '',
  placeholder = 'Search...',
  variant = 'index',
  searchRef: externalSearchRef,
  onFocus,
  onBlur,
  children
}) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);
  const internalSearchRef = useRef(null);
  const searchRef = externalSearchRef || internalSearchRef;
  const navigate = useNavigate();

  const handleInputChange = (value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
      setSearchQuery('');
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        searchRef.current?.focus();
      }
      if (e.key === 'Escape') {
        searchRef.current?.blur();
        setSearchQuery('');
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const getVariantStyles = () => {
    return variant === 'index' ? {
      wrapper: "relative w-full max-w-3xl mx-auto",
      form: "relative w-full group",
      input: "relative flex items-center bg-background/30 rounded-xl px-5 py-3.5 shadow-[0_0_15px_rgba(0,0,0,0.3)]",
      gradient: "absolute -inset-[2px] bg-gradient-to-r from-gold-600/50 via-gold-400/50 to-gold-600/50 rounded-xl"
    } : {
      wrapper: "relative w-full",
      form: "relative w-full",
      input: "flex items-center bg-background/40 text-black rounded-lg px-2 py-1.5 shadow-sm",
      gradient: "absolute -inset-[1px] bg-gradient-to-r from-gold-600/30 via-gold-400/30 to-gold-600/30 rounded-lg"
    };
  };

  const styles = getVariantStyles();

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {/* Decorative elements */}
        <div className={`${styles.gradient} opacity-40 group-hover:opacity-75 blur-md transition-all duration-500`}></div>
        <div className="absolute inset-0 bg-gradient-to-r from-black via-zinc-900/90 to-black rounded-xl backdrop-blur-xl"></div>

        <motion.div 
          initial={{ scale: 0.98 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 300 }}
          className={`
            ${styles.input}
            group-hover:shadow-[0_0_25px_rgba(184,150,46,0.2)] 
            group-focus-within:shadow-[0_0_30px_rgba(184,150,46,0.3)]
            border border-gold-500/20 group-hover:border-gold-500/30
            backdrop-blur-sm
            transition-all duration-500
          `}
        >
          <div className="p-2 bg-gold-500/10 rounded-lg group-hover:bg-gold-500/20 transition-colors duration-300">
            {isLoading ? (
              <div className="w-5 h-5 border-2 border-gold-500/80 border-t-transparent rounded-full animate-spin" />
            ) : (
              <Search className="w-5 h-5 text-gold-500/80 group-hover:text-gold-500 transition-colors duration-300" />
            )}
          </div>
          
          <input
            ref={searchRef}
            type="text"
            value={searchQuery}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={() => {
              setIsFocused(true);
              onFocus?.();
            }}
            onBlur={() => {
              setIsFocused(false);
              onBlur?.();
            }}
            placeholder={placeholder}
            className="ml-4 bg-transparent text-gold-300 placeholder-gold-500/30 
                     focus:outline-none w-full text-base tracking-wide
                     group-hover:placeholder-gold-500/40 transition-all duration-300"
          />
          
          {searchQuery && (
            <button
              onClick={() => handleInputChange('')}
              className="p-2 hover:bg-gold-500/10 rounded-lg transition-colors"
            >
              <X className="w-4 h-4 text-gold-400 hover:text-gold-300" />
            </button>
          )}
          
          <div className="hidden md:flex items-center gap-1 ml-2 px-2 py-1.5 rounded-lg 
                        bg-gold-500/5 border border-gold-500/10">
            <Command className="w-3.5 h-3.5 text-gold-500/70" />
            <span className="text-xs font-medium text-gold-500/70">K</span>
          </div>
        </motion.div>

        {/* Render suggestions passed as children */}
        {children}
      </form>
    </div>
  );
};

export default SearchBar;
