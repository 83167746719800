import React, { useState } from 'react';

export const Tab = ({ children }) => {
  return <div>{children}</div>;
};

export const Tabs = ({ children, defaultTab = 0 }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTab);

  // Ensure children is always an array
  const childrenArray = React.Children.toArray(children);
  
  return (
    <div>
      <div className="flex overflow-x-auto hide-scrollbar border-b border-white/10 mb-4">
        {childrenArray.map((child, index) => (
          <button
            key={index}
            onClick={() => setActiveTabIndex(index)}
            className={`px-5 py-3 text-sm font-medium transition-colors whitespace-nowrap
              ${index === activeTabIndex 
              ? 'text-[#6366f1] border-b-2 border-[#6366f1]' 
              : 'text-gray-400 hover:text-white border-b-2 border-transparent'
              }`}
          >
            {child.props.title}
          </button>
        ))}
      </div>
      {childrenArray[activeTabIndex]}
    </div>
  );
};
