import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Calendar, Star, Filter, ChevronDown, Film, 
  Tv, Sparkles, Info, Bookmark, Share2
} from 'lucide-react';
import { fetchUpcomingContent } from '../services/api';

const Upcoming = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('release_date');
  const [showFilters, setShowFilters] = useState(false);
  const [error, setError] = useState(null);

  const tabs = [
    { id: 'all', label: 'All', icon: Sparkles },
    { id: 'movies', label: 'Movies', icon: Film },
    { id: 'tv', label: 'TV Shows', icon: Tv },
  ];

  const filters = [
    { id: 'release_date', label: 'Release Date' },
    { id: 'popularity', label: 'Popularity' },
    { id: 'alphabetical', label: 'Alphabetical' },
  ];

  // Simplified CountdownTimer component
  const CountdownTimer = ({ releaseDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(releaseDate) - new Date();
      return {
        days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60))
      };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 60000); // Update every minute instead of every second
      return () => clearInterval(timer);
    }, [releaseDate]);

    return (
      <div className="flex gap-2 text-xs justify-center mt-2">
        <div className="bg-gold-500/20 px-2 py-1 rounded">
          <span className="font-bold text-gold-400">{timeLeft.days}</span>
          <span className="text-gold-300 ml-1">d</span>
        </div>
        <div className="bg-gold-500/20 px-2 py-1 rounded">
          <span className="font-bold text-gold-400">{timeLeft.hours}</span>
          <span className="text-gold-300 ml-1">h</span>
        </div>
        <div className="bg-gold-500/20 px-2 py-1 rounded">
          <span className="font-bold text-gold-400">{timeLeft.minutes}</span>
          <span className="text-gold-300 ml-1">m</span>
        </div>
      </div>
    );
  };

  // Simplified ContentCard component
  const ContentCard = ({ item }) => {
    if (!item.poster_path) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="relative group cursor-pointer overflow-hidden rounded-xl bg-background/30 
                   border border-white/10 hover:border-gold-500/50 transition-all duration-300"
      >
        {/* Main Content */}
        <div className="relative aspect-[2/3]">
          <img
            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
            alt={item.title || item.name}
            className="w-full h-full object-cover"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 
                        group-hover:opacity-100 transition-opacity duration-300">
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h3 className="text-lg font-bold mb-2">{item.title || item.name}</h3>
              <p className="text-sm text-gray-300 line-clamp-2 mb-2">{item.overview}</p>
              <CountdownTimer releaseDate={item.release_date || item.first_air_date} />
            </div>
          </div>
        </div>

        {/* Info Bar */}
        <div className="p-3 flex items-center justify-between border-t border-white/10">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gold-500" />
            <span className="text-sm">
              {new Date(item.release_date || item.first_air_date).toLocaleDateString()}
            </span>
          </div>
          {item.vote_average > 0 && (
            <div className="flex items-center gap-1">
              <Star className="w-4 h-4 text-gold-500" />
              <span className="text-sm">{item.vote_average.toFixed(1)}</span>
            </div>
          )}
        </div>
      </motion.div>
    );
  };

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Pass current page (can add pagination later if needed)
        const data = await fetchUpcomingContent(activeTab === 'all' ? 'all' : activeTab, selectedFilter, 1);
        if (data.length === 0) {
          setError('No upcoming content found for the selected filters.');
        } else {
          setContent(data);
        }
      } catch (error) {
        console.error('Error loading upcoming content:', error);
        setError('Failed to load content. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadContent();
  }, [activeTab, selectedFilter]);

  if (error) {
    return (
      <div className="min-h-screen bg-background text-white pt-20 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-gold-500 text-black rounded hover:bg-gold-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background text-white pt-20 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gold-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background text-white pt-20 pb-32">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mt-6 mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-gold-400 to-gold-600 bg-clip-text text-transparent">
            Coming Soon
          </h1>
          <p className="text-gray-400">Track upcoming releases and never miss your favorite content</p>
        </div>

        {/* Filters */}
        <div className="flex flex-wrap items-center justify-between gap-4 mb-8">
          <div className="flex gap-2 p-1 bg-white/5 rounded-lg">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all
                  ${activeTab === tab.id ? 'bg-gold-500 text-black' : 'text-gold-500 hover:bg-white/5'}`}
              >
                <tab.icon className="w-4 h-4" />
                {tab.label}
              </button>
            ))}
          </div>

          {/* Sort Dropdown */}
          <div className="relative">
            {/* ...existing filter dropdown code... */}
          </div>
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {content.map((item) => (
            <ContentCard key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
