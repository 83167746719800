import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Zap, Crown, Signal, CheckCircle2, Monitor } from 'lucide-react';

const MobileServerSelector = ({ servers, activeServer, onServerChange }) => {
  const serverTiers = [
    { 
      name: 'Premium', 
      servers: servers.slice(0, 3), 
      icon: Crown, 
      color: 'gold',
      quality: '4K Ultra HD'
    },
    { 
      name: 'Fast', 
      servers: servers.slice(3, 6), 
      icon: Zap, 
      color: 'emerald',
      quality: '1080p HD'
    },
    { 
      name: 'Backup', 
      servers: servers.slice(6), 
      icon: Shield, 
      color: 'blue',
      quality: '720p'
    }
  ];

  return (
    <div className="space-y-8">
      {serverTiers.map(({ name, servers, icon: Icon, color, quality }) => (
        <motion.div
          key={name}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-4"
        >
          {/* Enhanced Tier Header */}
          <div className="bg-background/40 rounded-xl p-4 border border-white/5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg bg-${color}-500/10`}>
                  <Icon className={`w-5 h-5 text-${color}-500`} />
                </div>
                <div>
                  <h3 className={`text-sm font-semibold text-${color}-500`}>{name}</h3>
                  <div className="flex items-center gap-2 mt-1">
                    <Monitor className="w-3 h-3 text-white/40" />
                    <span className="text-xs text-white/40">{quality}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Signal className={`w-4 h-4 text-${color}-500`} />
                <span className={`text-xs text-${color}-500`}>
                  {servers.length} Available
                </span>
              </div>
            </div>
          </div>

          {/* Enhanced Server Grid */}
          <div className="grid grid-cols-2 gap-3">
            {servers.map((server, idx) => (
              <motion.button
                key={server.url}
                whileTap={{ scale: 0.98 }}
                onClick={() => onServerChange(server.url)}
                className={`group relative p-4 rounded-xl border backdrop-blur-sm
                  transition-all duration-300 ${
                    activeServer === server.url 
                      ? `bg-${color}-500/20 border-${color}-500/50` 
                      : 'bg-background/40 border-white/10 hover:bg-white/5'
                  }`}
              >
                <div className="flex items-start gap-3">
                  <div className={`w-2 h-2 rounded-full mt-1.5
                    ${activeServer === server.url 
                      ? `bg-${color}-500 animate-pulse` 
                      : 'bg-white/20'}`} 
                  />
                  <div className="flex-1 text-left">
                    <span className={`text-sm font-medium
                      ${activeServer === server.url 
                        ? `text-${color}-500` 
                        : 'text-white/90'}`}>
                      Server {idx + 1}
                    </span>
                    <p className="text-xs text-white/40 mt-1">
                      Premium CDN
                    </p>
                  </div>
                </div>

                {/* Active Indicator */}
                {activeServer === server.url && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    className={`absolute top-2 right-2 text-${color}-500`}
                  >
                    <CheckCircle2 className="w-4 h-4" />
                  </motion.div>
                )}
              </motion.button>
            ))}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default MobileServerSelector;
