import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaPlay, 
  FaStar, 
  FaFilter,
  FaFilm,
  FaTv,
  FaDragon,
  FaTimes,
  FaChevronDown
} from 'react-icons/fa';
import { fetchFilteredContent, fetchGenres } from '../services/api';
import Pagination from '../components/Pagination';

const getCategoryIcon = (mediaType) => {
  switch (mediaType) {
    case 'movie':
      return FaFilm;
    case 'tv':
      return FaTv;
    case 'anime':
      return FaDragon;
    default:
      return FaFilm;
  }
};

const FilterPage = () => {
  // State Management
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    genre: '',
    type: '',
    minRating: '',
    yearFrom: '',
    yearTo: '',
    sortBy: 'popularity'
  });
  const [genres, setGenres] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Fetch Genres and Initial Content
  useEffect(() => {
    const initializeData = async () => {
      try {
        // Fetch genres
        const genreData = await fetchGenres();
        setGenres(genreData);

        // Fetch initial filtered content
        await fetchContent(true);
      } catch (error) {
        console.error('Initialization error:', error);
      }
    };

    initializeData();
  }, []);

  // Fetch Content Based on Filters
  const fetchContent = useCallback(async (isReset = false) => {
    try {
      setLoading(true);
      const currentPage = isReset ? 1 : page;
      
      const result = await fetchFilteredContent({
        ...filters,
        page: currentPage
      });
  
      if (!result || !result.results) {
        setContent([]);
        setHasMore(false);
        setLoading(false);
        return;
      }
  
      setContent(result.results); // Just set the current page results
      setTotalPages(result.total_pages || 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching filtered content:', error);
      setContent([]);
      setHasMore(false);
      setLoading(false);
    }
  }, [filters, page]);

  // Trigger fetch when filters change
  useEffect(() => {
    fetchContent(true);
  }, [filters, fetchContent]);

  // Handle Filter Changes
  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => {
      // Validate and sanitize filters
      const sanitizedFilters = {
        genre: newFilters.genre || '',
        type: newFilters.type || '',
        minRating: newFilters.minRating || '',
        yearFrom: newFilters.yearFrom || '',
        yearTo: newFilters.yearTo || '',
        sortBy: newFilters.sortBy || 'popularity'
      };
  
      // Reset page when filters change
      setPage(1);
      
      return sanitizedFilters;
    });
  };

  // Add page change handler
  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Reset Filters
  const resetFilters = () => {
    setFilters({
      genre: '',
      type: '',
      minRating: '',
      yearFrom: '',
      yearTo: '',
      sortBy: 'popularity'
    });
  };

  // Add scroll listener
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Render Filter Controls
  const renderFilterControls = () => (
    <div className="space-y-6">
      {/* Media Type Filter */}
      <div className="filter-section">
        <h3 className="text-xl font-semibold text-gold-400 mb-4">Media Type</h3>
        <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-wrap sm:space-x-2">
          {[
            { key: '', label: 'All', icon: <FaFilm /> },
            { key: 'movie', label: 'Movies', icon: <FaFilm /> },
            { key: 'tv', label: 'TV Shows', icon: <FaTv /> },
            { key: 'anime', label: 'Anime', icon: <FaDragon /> }
          ].map(({ key, label, icon }) => (
            <motion.button
              key={key}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleFilterChange({ ...filters, type: key })}
              className={`flex items-center justify-center px-4 py-3 rounded-lg transition-all duration-300 
                ${filters.type === key 
                  ? 'bg-gold-500 text-black shadow-lg shadow-gold-500/30' 
                  : 'bg-gray-800/50 text-gold-400 hover:bg-gray-700'}`}
            >
              <span className="mr-2">{icon}</span>
              <span className="text-sm font-medium">{label}</span>
            </motion.button>
          ))}
        </div>
      </div>

      {/* Genre Filter */}
      <div className="filter-section">
        <h3 className="text-xl font-semibold text-gold-400 mb-4">Genre</h3>
        <div className="relative">
          <select
            value={filters.genre}
            onChange={(e) => handleFilterChange({ ...filters, genre: e.target.value })}
            className="w-full bg-gray-800/50 text-gold-400 rounded-lg p-4 appearance-none
                     border border-gray-700 focus:border-gold-500 transition-colors duration-300"
          >
            <option value="">All Genres</option>
            {genres.map((genre) => (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            ))}
          </select>
          <FaChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gold-400" />
        </div>
      </div>

      {/* Rating Slider */}
      <div className="filter-section">
        <h3 className="text-xl font-semibold text-gold-400 mb-4">Minimum Rating</h3>
        <div className="relative pt-2">
          <input
            type="range"
            min="0"
            max="10"
            step="0.5"
            value={filters.minRating}
            onChange={(e) => handleFilterChange({ ...filters, minRating: e.target.value })}
            className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer
                     accent-gold-500"
          />
          <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 bg-gold-500 
                        text-black px-2 py-1 rounded text-sm">
            {filters.minRating || '0'}
          </div>
        </div>
      </div>

      {/* Year Range */}
      <div className="filter-section">
        <h3 className="text-xl font-semibold text-gold-400 mb-4">Year Range</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="relative">
            <input
              type="number"
              value={filters.yearFrom}
              onChange={(e) => handleFilterChange({ ...filters, yearFrom: e.target.value })}
              placeholder="From"
              className="w-full bg-gray-800/50 text-gold-400 rounded-lg p-4
                       border border-gray-700 focus:border-gold-500 transition-colors duration-300"
            />
          </div>
          <div className="relative">
            <input
              type="number"
              value={filters.yearTo}
              onChange={(e) => handleFilterChange({ ...filters, yearTo: e.target.value })}
              placeholder="To"
              className="w-full bg-gray-800/50 text-gold-400 rounded-lg p-4
                       border border-gray-700 focus:border-gold-500 transition-colors duration-300"
            />
          </div>
        </div>
      </div>

      {/* Reset Button */}
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={resetFilters}
        className="w-full bg-red-500/80 hover:bg-red-600 text-white rounded-lg p-4 
                 transition duration-300 shadow-lg shadow-red-500/20"
      >
        Reset All Filters
      </motion.button>
    </div>
  );

  // Render Content Grid
  const renderContentGrid = () => (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
      <AnimatePresence>
        {content.map((item, index) => (
          <motion.div
            key={`${item.id}-${index}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.05 }}
          >
            <Link 
              to={`/details/${item.media_type === 'movie' ? 'movie' : 'tv'}/${item.id}`} 
              key={`${item.id}-${Math.random().toString(36).substr(2, 9)}`} 
              className=" group relative"
            >
              <div className="
                relative overflow-hidden rounded-xl shadow-xl 
                transition-all duration-300 
                border border-transparent
                group-hover:border-gold-500/50 
                group-hover:scale-105 
                group-hover:shadow-2xl 
                bg-background/5">
                {/* Category Icon Overlay */}
                <div className="absolute top-2 left-2 z-10 
                                bg-background/60 rounded-full 
                                p-2 flex items-center justify-center">
                  {React.createElement(getCategoryIcon(item.media_type), {
                    className: "text-gold-400 text-xs"
                  })}
                </div>

                {/* Poster Image */}
                <div className="relative pt-[150%] overflow-hidden"> 
                  <img 
                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
                    alt={item.title || item.name}
                    className="absolute inset-0 w-full h-full object-cover 
                               opacity-90 group-hover:opacity-100 
                               transition-opacity duration-300 
                               transform group-hover:scale-110"
                  />
                  
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 bg-background/0 group-hover:bg-background/30 
                                  transition-all duration-300 flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <FaPlay className="text-gold-500 text-4xl 
                                        transform hover:scale-110 
                                        transition-transform" />
                    </div>
                  </div>
                  
                  {/* Rating Overlay */}
                  <div className="absolute top-2 right-2 
                                  bg-background/60 rounded-full 
                                  px-2 py-1 flex items-center">
                    <FaStar className="text-gold-500 mr-1" />
                    <span className="text-xs text-gold-400 font-bold">
                      {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
                    </span>
                  </div>
                </div>

                {/* Title Overlay */}
                <div className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to-transparent 
                                p-2">
                  <h3 className="text-xs font-semibold text-gold-400 p-2 bg-background bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
                    {item.title || item.name}
                  </h3>
                </div>
              </div>
            </Link>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-zinc-900">
      {/* Mobile Filter Toggle Button */}
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={`fixed bottom-6 right-6 z-50 lg:hidden bg-gold-500 text-black p-4 rounded-full
                   shadow-lg shadow-gold-500/30 ${isScrolled ? 'scale-75' : 'scale-100'}`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FaFilter className="text-xl" />
      </motion.button>

      <div className="max-w-screen-2xl mx-auto px-4 py-8 lg:py-12">
        <div className="flex mt-14 flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <AnimatePresence>
            {(isSidebarOpen || window.innerWidth >= 1024) && (
              <motion.aside
                initial={{ x: -300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className={`
                  fixed inset-0 z-40 lg:relative lg:z-0
                  w-full sm:w-80 lg:w-96
                  bg-zinc-900/95 backdrop-blur-xl lg:bg-zinc-900
                  p-6 rounded-r-2xl lg:rounded-xl
                  shadow-2xl shadow-black/50
                  overflow-y-auto
                  ${isSidebarOpen ? 'block' : 'hidden lg:block'}
                `}
              >
                {/* Close button for mobile */}
                <button
                  className="absolute top-4 right-4 lg:hidden text-gold-400 hover:text-gold-500"
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <FaTimes className="text-2xl" />
                </button>

                {renderFilterControls()}
              </motion.aside>
            )}
          </AnimatePresence>

          {/* Main Content */}
          <main className="flex-1">
            <div className="flex flex-col gap-6">
              {loading ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                  {[...Array(12)].map((_, index) => (
                    <div
                      key={index}
                      className="relative pt-[150%] bg-gray-800/30 rounded-xl overflow-hidden
                               animate-pulse"
                    />
                  ))}
                </div>
              ) : (
                <>
                  {renderContentGrid()}
                  
                  {/* Pagination */}
                  {content.length > 0 && (
                    <motion.div 
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                      className="mt-8 pb-16"
                    >
                      <Pagination 
                        currentPage={page}
                        onPageChange={handlePageChange}
                      />
                    </motion.div>
                  )}

                  {/* No results message */}
                  {content.length === 0 && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="text-center py-8"
                    >
                      <p className="text-gold-400/60">No content found matching your filters</p>
                    </motion.div>
                  )}
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FilterPage;