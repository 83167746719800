import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Play, Trash2, Filter, Calendar, Star, 
   List, Grid, SortAsc, Info, Loader
} from 'lucide-react';
import { getFavorites, removeFromFavorites } from '../services/favorites';
import { useToast } from '../hooks/useToast';
import Toast from '../components/Toast';

const Watchlist = () => {
  const [favorites, setFavorites] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const { toast, showToast, setToast } = useToast();
  const [sortBy, setSortBy] = useState('latest'); // 'latest' or 'oldest'
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [isLoading, setIsLoading] = useState(true);

  // Sort options
  const sortOptions = [
    { id: 'latest', label: 'Date Added (Newest)', icon: <Calendar className="w-4 h-4" /> },
    { id: 'oldest', label: 'Date Added (Oldest)', icon: <Calendar className="w-4 h-4" /> },
    { id: 'rating', label: 'Rating', icon: <Star className="w-4 h-4" /> },
    { id: 'name', label: 'Name', icon: <SortAsc className="w-4 h-4" /> }
  ];

  useEffect(() => {
    loadFavorites();
  }, []);

  const loadFavorites = () => {
    const items = getFavorites();
    setFavorites(items);
    setIsLoading(false);
  };

  const handleRemove = (id) => {
    try {
      removeFromFavorites(id);
      setFavorites(prev => prev.filter(item => item.id !== id));
      showToast('Removed from watchlist', 'success');
    } catch (error) {
      showToast('Failed to remove from watchlist', 'error');
    }
  };

  // Enhanced sorting function
  const getSortedItems = (items) => {
    return items.sort((a, b) => {
      switch (sortBy) {
        case 'rating':
          return b.vote_average - a.vote_average;
        case 'name':
          return (a.title || a.name).localeCompare(b.title || b.name);
        case 'latest':
          return new Date(b.addedAt) - new Date(a.addedAt);
        case 'oldest':
          return new Date(a.addedAt) - new Date(b.addedAt);
        default:
          return 0;
      }
    });
  };

  const filteredAndSortedFavorites = React.useMemo(() => {
    let items = favorites.filter(item => 
      filterType === 'all' ? true : item.media_type === filterType
    );
    return getSortedItems(items);
  }, [favorites, filterType, sortBy]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background/95 flex items-center justify-center">
        <Loader className="w-8 h-8 text-gold-500 animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background/95 pt-20 pb-12">
      <div className="container mx-auto px-4">
        {/* Enhanced Header Section - Updated Styling */}
        <div className="bg-background/40 backdrop-blur-md rounded-xl p-6 border border-gold-500/10 mb-8 
            hover:border-gold-500/20 transition-all duration-300 shadow-lg shadow-black/20 mt-6">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-2">
                Your Watchlist
              </h1>
              <div className="flex items-center gap-2 text-gray-400">
                <Info className="w-4 h-4" />
                <span>{filteredAndSortedFavorites.length} {filterType === 'all' ? 'items' : filterType + 's'}</span>
              </div>
            </div>

            {/* Enhanced Controls - Updated Styling */}
            <div className="flex flex-wrap items-center gap-4">
              {/* View Mode Toggle */}
              <div className="flex items-center gap-1 bg-background/50 rounded-lg p-1 border border-gold-500/10">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-md transition-all ${
                    viewMode === 'grid' 
                      ? 'bg-gold-500 text-black' 
                      : 'text-gold-500/70 hover:text-gold-500 hover:bg-gold-500/10'
                  }`}
                >
                  <Grid className="w-4 h-4" />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-2 rounded-md transition-all ${
                    viewMode === 'list' 
                      ? 'bg-gold-500 text-black' 
                      : 'text-gold-500/70 hover:text-gold-500 hover:bg-gold-500/10'
                  }`}
                >
                  <List className="w-4 h-4" />
                </button>
              </div>

              {/* Filter Dropdown - Enhanced Styling */}
              <div className="relative group">
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="appearance-none relative z-10 w-full
                            bg-background/40 backdrop-blur-sm text-gold-500 
                            border border-gold-500/20 rounded-xl px-4 py-3
                            focus:outline-none focus:border-gold-500/50
                            hover:border-gold-500/40 cursor-pointer
                            transition-all duration-300 pr-10
                            font-medium"
                >
                  <option value="all" className="bg-background/95 text-gold-500 py-2">All Types</option>
                  <option value="movie" className="bg-background/95 text-gold-500 py-2">Movies</option>
                  <option value="tv" className="bg-background/95 text-gold-500 py-2">TV Shows</option>
                </select>
                <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gold-500/50 
                              group-hover:text-gold-500 pointer-events-none transition-colors">
                  <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {/* Hover effect overlay */}
                <div className="absolute inset-0 rounded-xl bg-gold-500/5 opacity-0 
                              group-hover:opacity-100 transition-opacity duration-300" />
              </div>

              {/* Sort Dropdown - Enhanced Styling */}
              <div className="relative group">
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="appearance-none relative z-10 w-full
                            bg-background/40 backdrop-blur-sm text-gold-500 
                            border border-gold-500/20 rounded-xl px-4 py-3
                            focus:outline-none focus:border-gold-500/50
                            hover:border-gold-500/40 cursor-pointer
                            transition-all duration-300 pr-10
                            font-medium"
                >
                  {sortOptions.map(option => (
                    <option 
                      key={option.id} 
                      value={option.id} 
                      className="bg-background/95 text-gold-500 py-2 hover:bg-gold-500/10 hover:text-black"
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gold-500/50 
                              group-hover:text-gold-500 pointer-events-none transition-colors">
                  <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {/* Hover effect overlay */}
                <div className="absolute inset-0 rounded-xl bg-gold-500/5 opacity-0 
                              group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            </div>
          </div>
        </div>

        {/* Content Grid/List View */}
        {filteredAndSortedFavorites.length > 0 ? (
          <AnimatePresence mode="wait">
            <motion.div
              key={viewMode}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={viewMode === 'grid' 
                ? "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4"
                : "flex flex-col gap-4"
              }
            >
              {filteredAndSortedFavorites.map((item) => (
                <WatchlistItem 
                  key={item.id}
                  item={item}
                  viewMode={viewMode}
                  onRemove={handleRemove}
                />
              ))}
            </motion.div>
          </AnimatePresence>
        ) : (
          <div className="flex flex-col items-center justify-center py-20 text-center">
            <div className="w-16 h-16 mb-4 rounded-full bg-zinc-900/50 
                          flex items-center justify-center">
              <Filter className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">
              Your watchlist is empty
            </h3>
            <p className="text-gray-400 max-w-md">
              Start adding movies and TV shows to your watchlist to keep track of what you want to watch
            </p>
          </div>
        )}
      </div>

      {/* Toast Notification */}
      <AnimatePresence>
        {toast.show && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast({ show: false, message: '', type: 'success' })}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

// New WatchlistItem component for better organization
const WatchlistItem = ({ item, viewMode, onRemove }) => {
  if (viewMode === 'grid') {
    return (
      <motion.div
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="group relative aspect-[2/3] bg-background/40 rounded-xl 
                 overflow-hidden border border-gold-500/10 
                 hover:border-gold-500/30 hover:transform hover:scale-[1.02]
                 transition-all duration-300 shadow-lg hover:shadow-2xl 
                 hover:shadow-gold-500/10"
      >
        <Link to={`/details/${item.media_type}/${item.id}`} className="block h-full">
          <img
            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
            alt={item.title}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
          
          {/* Main Overlay with Gradient */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent 
                        opacity-0 group-hover:opacity-100 transition-all duration-300">
            <div className="absolute inset-0 flex flex-col justify-between p-4">
              {/* Top Section */}
              <div className="flex justify-between items-start">
                <span className="px-2 py-1 bg-background/60 backdrop-blur-sm rounded-md text-xs font-medium
                               text-gold-500 capitalize">
                  {item.media_type}
                </span>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={(e) => {
                    e.preventDefault();
                    onRemove(item.id);
                  }}
                  className="p-2 rounded-full bg-background/60 backdrop-blur-sm text-red-500
                           hover:bg-red-500 hover:text-white
                           transition-all duration-300"
                >
                  <Trash2 className="w-4 h-4" />
                </motion.button>
              </div>

              {/* Bottom Section */}
              <div className="space-y-3">
                {/* Rating and Date */}
                <div className="flex items-center gap-3 text-xs">
                  <span className="flex items-center gap-1 bg-background/60 backdrop-blur-sm 
                                 px-2 py-1 rounded-md">
                    <Star className="w-3 h-3 text-gold-500" fill="currentColor" />
                    <span className="text-white">{item.vote_average?.toFixed(1)}</span>
                  </span>
                  <span className="flex items-center gap-1 bg-background/60 backdrop-blur-sm 
                                 px-2 py-1 rounded-md text-gray-300">
                    <Calendar className="w-3 h-3" />
                    {new Date(item.addedAt).toLocaleDateString()}
                  </span>
                </div>

                {/* Title */}
                <h3 className="text-white font-semibold text-sm line-clamp-2 
                             drop-shadow-lg shadow-black">
                  {item.title || item.name}
                </h3>

                {/* Action Buttons */}
                <div className="flex items-center gap-2">
                  <Link
                    to={`/watch/${item.media_type}/${item.id}`}
                    onClick={(e) => e.stopPropagation()}
                    className="flex-1 inline-flex items-center justify-center gap-2 text-xs
                             bg-gold-500 text-black px-3 py-2 rounded-lg font-medium
                             hover:bg-gold-400 transition-all duration-300
                             transform hover:translate-y-[-1px]"
                  >
                    <Play className="w-3 h-3" />
                    Watch Now
                  </Link>
                  <Link
                    to={`/details/${item.media_type}/${item.id}`}
                    onClick={(e) => e.stopPropagation()}
                    className="flex-1 inline-flex items-center justify-center gap-2 text-xs
                             bg-white/10 backdrop-blur-sm text-white px-3 py-2 rounded-lg
                             hover:bg-white/20 transition-all duration-300
                             transform hover:translate-y-[-1px]"
                  >
                    <Info className="w-3 h-3" />
                    Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </motion.div>
    );
  }

  // List view
  return (
    <motion.div
      layout
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      className="group relative bg-background/40 rounded-xl overflow-hidden 
               border border-gold-500/10 hover:border-gold-500/20 hover:bg-background/60 
               transition-all duration-300 shadow-lg shadow-black/20"
    >
      <div className="flex items-center gap-4 p-4">
        <img
          src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
          alt={item.title}
          className="w-16 h-24 object-cover rounded-lg"
        />
        <div className="flex-1">
          <Link 
            to={`/details/${item.media_type}/${item.id}`}
            className="text-white font-semibold hover:text-gold-500 transition-colors"
          >
            {item.title || item.name}
          </Link>
          <div className="flex items-center gap-4 mt-2 text-sm text-gray-400">
            <span className="flex items-center gap-1">
              <Calendar className="w-4 h-4" />
              {new Date(item.addedAt).toLocaleDateString()}
            </span>
            <span className="flex items-center gap-1">
              <Star className="w-4 h-4 text-gold-500" />
              {item.vote_average?.toFixed(1)}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Link
            to={`/watch/${item.media_type}/${item.id}`}
            className="p-2 rounded-full bg-gold-500 text-black
                     hover:bg-gold-400 transition-colors"
          >
            <Play className="w-4 h-4" />
          </Link>
          <button
            onClick={() => onRemove(item.id)}
            className="p-2 rounded-full bg-red-500/20 text-red-500
                     hover:bg-red-500 hover:text-white transition-colors"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default Watchlist;
