import React, { useState, useEffect } from 'react';
import { useAnimeApi } from './useAnimeApi.jsx';
import { useAnimeInfo } from './useAnimeInfo.jsx';
import { PremiumCard } from './card/PremiumCard';
import { SearchBar } from './SearchBar';
import { AnimeModal } from './modal/AnimeModal';
import { ChevronDown, ChevronRight, Play, Loader2, AlertCircle, RefreshCw } from 'lucide-react';
import { JsonView } from './components/JsonView';
import { Tab, Tabs } from './components/Tabs';
import { PremiumPlayer } from './components/PremiumPlayer';

const TestPage = () => {
  // API hooks
  const animeApi = useAnimeApi();
  const animeInfoHook = useAnimeInfo();

  // State for selected anime and UI state
  const [selectedAnimeId, setSelectedAnimeId] = useState('');
  const [selectedEpisodeId, setSelectedEpisodeId] = useState(''); // New state for episodeId
  const [selectedEpisodeNumber, setSelectedEpisodeNumber] = useState('1');
  const [expandedSections, setExpandedSections] = useState({
    home: false,
    search: true,
    animeInfo: false,
    episodes: false,
    streaming: false,
  });
  
  // Search state
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  
  // Data state
  const [homeData, setHomeData] = useState(null);
  const [animeData, setAnimeData] = useState(null);
  const [episodesData, setEpisodesData] = useState(null);
  const [streamingData, setStreamingData] = useState(null);
  
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAnime, setSelectedAnime] = useState(null);

  // Handle section toggle
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Load home data
  const loadHomeData = async () => {
    const data = await animeApi.getHome();
    setHomeData(data);
  };

  // Handle search
  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.trim()) {
      const data = await animeApi.getSuggestions(query);
      setSearchSuggestions(data?.data?.suggestions || []);
    } else {
      setSearchSuggestions([]);
    }
  };

  // Load anime details
  const loadAnimeDetails = async (id) => {
    setSelectedAnimeId(id);
    
    // Reset related data
    setEpisodesData(null);
    setStreamingData(null);
    setSelectedEpisodeId(''); // Reset episodeId
    
    // Fetch anime details
    const data = await animeApi.getAnimeInfo(id);
    setAnimeData(data);
    
    // Load episodes for this anime
    if (id) {
      loadEpisodes(id);
    }
  };

  // Load episodes
  const loadEpisodes = async (id) => {
    const data = await animeApi.getEpisodes(id);
    setEpisodesData(data);
    
    // Select the first episode by default
    if (data?.data?.episodes?.length > 0) {
      const firstEpisode = data.data.episodes[0];
      setSelectedEpisodeNumber(firstEpisode.number.toString());
      setSelectedEpisodeId(firstEpisode.episodeId);
    }
  };

  // Update the loadStreamingLinks function to handle direct streaming URLs better
  const loadStreamingLinks = async () => {
    if (!selectedEpisodeId) {
      console.error("No episode ID selected");
      return;
    }
    
    console.log("Loading streaming links for episodeId:", selectedEpisodeId);
    const data = await animeApi.getStreamingLinks(selectedEpisodeId);
    
    if (data?.success) {
      console.log("Streaming data loaded successfully:", data);
      setStreamingData(data);
    } else {
      console.error("Failed to load streaming data:", data);
    }
  };

  // Handle selecting an episode
  const handleEpisodeSelect = (episode) => {
    setSelectedEpisodeNumber(episode.number.toString());
    setSelectedEpisodeId(episode.episodeId);
    setStreamingData(null); // Clear previous streaming data
  };

  // Handle suggestion click
  const handleSuggestionClick = (id) => {
    loadAnimeDetails(id);
    setExpandedSections(prev => ({
      ...prev,
      animeInfo: true,
      episodes: true
    }));
  };

  // Open anime modal
  const openAnimeModal = (anime) => {
    setSelectedAnime(anime);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Load initial trending anime to display some cards
    loadHomeData();
  }, []);

  // Render cards grid
  const renderCards = (animeList = []) => {
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {animeList.map((anime, index) => (
          <div key={anime.id} onClick={() => loadAnimeDetails(anime.id)}>
            <PremiumCard anime={anime} index={index} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[#06060a] text-white p-4 md:p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="text-center mb-12 mt-14">
          <h1 className="text-3xl md:text-5xl font-night-bold bg-clip-text text-transparent bg-gradient-to-r from-[#6366f1] via-[#8b5cf6] to-[#d946ef]">
            Anime API Testing Page
          </h1>
          <p className="mt-4 text-gray-400">
            Test the functionality and responses from the anime API
          </p>
        </div>

        {/* Search Section */}
        <section className="border border-white/10 rounded-2xl p-5 bg-card-bg/60">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('search')}
          >
            <h2 className="text-xl font-night-bold text-white flex items-center gap-2">
              {expandedSections.search ? <ChevronDown className="w-5 h-5 text-[#6366f1]" /> : <ChevronRight className="w-5 h-5 text-[#6366f1]" />}
              Search Testing
            </h2>
          </div>

          {expandedSections.search && (
            <div className="mt-4 space-y-6">
              <div className="space-y-4">
                <SearchBar
                  onSearch={handleSearch}
                  suggestions={searchSuggestions}
                  isLoading={animeApi.isLoading}
                  error={animeApi.error}
                  initialValue={searchQuery}
                  onSuggestionClick={handleSuggestionClick}
                />

                {searchSuggestions.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-lg font-medium text-white mb-3">Search Results:</h3>
                    <JsonView data={searchSuggestions} />
                  </div>
                )}
              </div>
            </div>
          )}
        </section>

        {/* Home Data Section */}
        <section className="border border-white/10 rounded-2xl p-5 bg-card-bg/60">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('home')}
          >
            <h2 className="text-xl font-night-bold text-white flex items-center gap-2">
              {expandedSections.home ? <ChevronDown className="w-5 h-5 text-[#6366f1]" /> : <ChevronRight className="w-5 h-5 text-[#6366f1]" />}
              Home Data
            </h2>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                loadHomeData();
              }}
              className="p-2 rounded-lg bg-[#6366f1]/10 hover:bg-[#6366f1]/20 transition-colors text-[#6366f1]"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
          </div>

          {expandedSections.home && (
            <div className="mt-4 space-y-6">
              {animeApi.isLoading ? (
                <div className="flex items-center justify-center p-12">
                  <Loader2 className="w-8 h-8 animate-spin text-[#6366f1]" />
                </div>
              ) : homeData ? (
                <>
                  <Tabs>
                    <Tab title="Trending Anime">
                      <div className="mt-4">
                        {renderCards(homeData?.data?.trendingAnimes || [])}
                      </div>
                    </Tab>
                    <Tab title="Most Popular">
                      <div className="mt-4">
                        {renderCards(homeData?.data?.mostPopularAnimes || [])}
                      </div>
                    </Tab>
                    <Tab title="Latest Episodes">
                      <div className="mt-4">
                        {renderCards(homeData?.data?.latestEpisodeAnimes || [])}
                      </div>
                    </Tab>
                    <Tab title="Raw JSON">
                      <JsonView data={homeData} />
                    </Tab>
                  </Tabs>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center p-12 text-gray-400">
                  <AlertCircle className="w-12 h-12 mb-4" />
                  <p>No home data available. Click refresh to load data.</p>
                </div>
              )}
            </div>
          )}
        </section>

        {/* Anime Info Section */}
        <section className="border border-white/10 rounded-2xl p-5 bg-card-bg/60">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('animeInfo')}
          >
            <h2 className="text-xl font-night-bold text-white flex items-center gap-2">
              {expandedSections.animeInfo ? <ChevronDown className="w-5 h-5 text-[#6366f1]" /> : <ChevronRight className="w-5 h-5 text-[#6366f1]" />}
              Anime Details
            </h2>
          </div>

          {expandedSections.animeInfo && (
            <div className="mt-4 space-y-6">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    value={selectedAnimeId}
                    onChange={(e) => setSelectedAnimeId(e.target.value)}
                    placeholder="Enter anime ID"
                    className="w-full p-4 rounded-xl bg-[#0a0a0f]/40 border border-white/10 text-white"
                  />
                </div>
                <button
                  onClick={() => loadAnimeDetails(selectedAnimeId)}
                  disabled={!selectedAnimeId || animeApi.isLoading}
                  className="px-6 py-4 rounded-xl bg-[#6366f1] hover:bg-[#6366f1]/90 
                    disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  {animeApi.isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    'Get Anime Details'
                  )}
                </button>
              </div>

              {animeApi.isLoading ? (
                <div className="flex items-center justify-center p-12">
                  <Loader2 className="w-8 h-8 animate-spin text-[#6366f1]" />
                </div>
              ) : animeData ? (
                <Tabs>
                  <Tab title="Preview Card">
                    <div className="mt-4 flex justify-center">
                      {animeData?.data?.anime?.info && (
                        <div className="max-w-xs">
                          <PremiumCard 
                            anime={{
                              id: animeData.data.anime.info.id,
                              name: animeData.data.anime.info.name,
                              poster: animeData.data.anime.info.poster,
                              rating: animeData.data.anime.info.stats?.rating,
                              episodes: animeData.data.anime.info.stats?.episodes,
                              duration: animeData.data.anime.info.stats?.duration,
                              type: animeData.data.anime.info.stats?.type,
                            }} 
                            index={0} 
                          />
                        </div>
                      )}
                    </div>
                    <div className="mt-6 flex justify-center">
                      <button
                        onClick={() => {
                          if (animeData?.data?.anime?.info) {
                            openAnimeModal({
                              id: animeData.data.anime.info.id,
                              name: animeData.data.anime.info.name,
                              poster: animeData.data.anime.info.poster,
                              rating: animeData.data.anime.info.stats?.rating,
                              episodes: animeData.data.anime.info.stats?.episodes,
                              type: animeData.data.anime.info.stats?.type,
                              duration: animeData.data.anime.info.stats?.duration,
                              synopsis: animeData.data.anime.info?.description,
                              genres: animeData.data.anime.moreInfo?.genres,
                              status: animeData.data.anime.moreInfo?.status,
                              aired: animeData.data.anime.moreInfo?.aired,
                              studio: animeData.data.anime.moreInfo?.studios,
                              quality: 'HD',
                            });
                          }
                        }}
                        className="px-6 py-3 rounded-xl bg-[#6366f1]/20 hover:bg-[#6366f1]/30 
                          text-[#6366f1] transition-colors flex items-center gap-2"
                      >
                        <Play className="w-4 h-4" />
                        Open in Modal
                      </button>
                    </div>
                  </Tab>
                  <Tab title="Info">
                    {animeData?.data?.anime?.info && (
                      <div className="mt-4">
                        <h3 className="text-xl font-medium text-white mb-2">
                          {animeData.data.anime.info.name}
                        </h3>
                        <div className="prose prose-invert max-w-none">
                          <p>{animeData.data.anime.info.description}</p>
                        </div>
                        
                        <div className="mt-4 grid grid-cols-2 gap-4">
                          <div>
                            <h4 className="text-lg text-[#6366f1] mb-2">Stats</h4>
                            <ul className="space-y-2 text-sm">
                              <li><span className="text-gray-400">Rating:</span> {animeData.data.anime.info.stats?.rating}</li>
                              <li><span className="text-gray-400">Type:</span> {animeData.data.anime.info.stats?.type}</li>
                              <li><span className="text-gray-400">Duration:</span> {animeData.data.anime.info.stats?.duration}</li>
                              <li>
                                <span className="text-gray-400">Episodes:</span> 
                                Sub: {animeData.data.anime.info.stats?.episodes?.sub}, 
                                Dub: {animeData.data.anime.info.stats?.episodes?.dub}
                              </li>
                            </ul>
                          </div>
                          <div>
                            <h4 className="text-lg text-[#8b5cf6] mb-2">Details</h4>
                            <ul className="space-y-2 text-sm">
                              <li><span className="text-gray-400">Status:</span> {animeData.data.anime.moreInfo?.status}</li>
                              <li><span className="text-gray-400">Aired:</span> {animeData.data.anime.moreInfo?.aired}</li>
                              <li><span className="text-gray-400">Studios:</span> {animeData.data.anime.moreInfo?.studios}</li>
                              <li>
                                <span className="text-gray-400">Genres:</span> 
                                {animeData.data.anime.moreInfo?.genres.join(', ')}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </Tab>
                  <Tab title="Raw JSON">
                    <JsonView data={animeData} />
                  </Tab>
                </Tabs>
              ) : (
                <div className="flex flex-col items-center justify-center p-12 text-gray-400">
                  <AlertCircle className="w-12 h-12 mb-4" />
                  <p>No anime selected. Enter an anime ID and click "Get Anime Details".</p>
                </div>
              )}
            </div>
          )}
        </section>

        {/* Episodes Section */}
        <section className="border border-white/10 rounded-2xl p-5 bg-card-bg/60">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('episodes')}
          >
            <h2 className="text-xl font-night-bold text-white flex items-center gap-2">
              {expandedSections.episodes ? <ChevronDown className="w-5 h-5 text-[#6366f1]" /> : <ChevronRight className="w-5 h-5 text-[#6366f1]" />}
              Episodes
            </h2>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                if (selectedAnimeId) loadEpisodes(selectedAnimeId);
              }}
              disabled={!selectedAnimeId}
              className="p-2 rounded-lg bg-[#6366f1]/10 hover:bg-[#6366f1]/20 transition-colors text-[#6366f1]
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
          </div>

          {expandedSections.episodes && (
            <div className="mt-4 space-y-6">
              {animeApi.isLoading ? (
                <div className="flex items-center justify-center p-12">
                  <Loader2 className="w-8 h-8 animate-spin text-[#6366f1]" />
                </div>
              ) : episodesData ? (
                <Tabs>
                  <Tab title="Episodes List">
                    <div className="mt-4 space-y-2">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-medium text-white">
                          Total Episodes: {episodesData.data?.totalEpisodes}
                        </h3>
                        {selectedEpisodeId && (
                          <div className="text-sm text-gray-400">
                            Selected Episode ID: <span className="text-[#6366f1]">{selectedEpisodeId}</span>
                          </div>
                        )}
                      </div>
                      
                      <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2">
                        {episodesData.data?.episodes.map((episode) => (
                          <div
                            key={episode.episodeId}
                            onClick={() => handleEpisodeSelect(episode)}
                            className={`p-3 rounded-lg border text-center cursor-pointer transition-all 
                              ${selectedEpisodeId === episode.episodeId 
                                ? 'bg-[#6366f1]/20 border-[#6366f1] text-white' 
                                : 'bg-card-bg/60 border-white/10 text-gray-400 hover:border-white/30'
                              }
                              ${episode.isFiller ? 'border-yellow-500/30' : ''}
                            `}
                          >
                            <div className="text-sm font-medium">EP {episode.number}</div>
                            {episode.title && (
                              <div className="text-xs truncate mt-1" title={episode.title}>
                                {episode.title}
                              </div>
                            )}
                            {episode.isFiller && (
                              <div className="text-xs text-yellow-500">Filler</div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Tab>
                  <Tab title="Raw JSON">
                    <JsonView data={episodesData} />
                  </Tab>
                </Tabs>
              ) : (
                <div className="flex flex-col items-center justify-center p-12 text-gray-400">
                  <AlertCircle className="w-12 h-12 mb-4" />
                  <p>No episodes data available. Select an anime first.</p>
                </div>
              )}
            </div>
          )}
        </section>

        {/* Streaming Section */}
        <section className="border border-white/10 rounded-2xl p-5 bg-card-bg/60">
          <div 
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('streaming')}
          >
            <h2 className="text-xl font-night-bold text-white flex items-center gap-2">
              {expandedSections.streaming ? <ChevronDown className="w-5 h-5 text-[#6366f1]" /> : <ChevronRight className="w-5 h-5 text-[#6366f1]" />}
              Streaming Links
            </h2>
          </div>

          {expandedSections.streaming && (
            <div className="mt-4 space-y-6">
              <div className="bg-background/30 p-4 rounded-xl">
                <div className="text-sm text-gray-400 mb-2">
                  <strong>Selected Episode ID:</strong>
                </div>
                <input
                  type="text"
                  value={selectedEpisodeId}
                  onChange={(e) => setSelectedEpisodeId(e.target.value)}
                  placeholder="Episode ID will be filled automatically when selecting an episode"
                  className="w-full p-4 rounded-xl bg-[#0a0a0f]/40 border border-white/10 text-white mb-4"
                />
                
                <div className="flex justify-end">
                  <button
                    onClick={loadStreamingLinks}
                    disabled={!selectedEpisodeId || animeApi.isLoading}
                    className="px-6 py-3 rounded-xl bg-[#6366f1] hover:bg-[#6366f1]/90 
                      disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    {animeApi.isLoading ? (
                      <Loader2 className="w-5 h-5 animate-spin" />
                    ) : (
                      'Get Streaming Links'
                    )}
                  </button>
                </div>
              </div>

              {animeApi.isLoading ? (
                <div className="flex items-center justify-center p-12">
                  <Loader2 className="w-8 h-8 animate-spin text-[#6366f1]" />
                </div>
              ) : streamingData ? (
                <div className="mt-4 space-y-6">
                  <div className="bg-background/30 p-5 rounded-xl border border-white/10">
                    <h3 className="text-lg font-medium text-white mb-4">Stream Sources</h3>
                    <div className="space-y-4">
                      {streamingData.data?.sources.map((source, index) => (
                        <div key={index} className="bg-background/20 p-4 rounded-lg border border-white/5">
                          <div className="flex flex-col sm:flex-row sm:items-center gap-2 justify-between">
                            <div>
                              <div className="text-sm text-gray-400">Type: <span className="text-white">{source.type || 'Unknown'}</span></div>
                              <div className="text-sm break-all">
                                <span className="text-gray-400">URL:</span> 
                                <a href={source.url} target="_blank" rel="noopener noreferrer" 
                                  className="text-[#6366f1] hover:underline ml-2">
                                  {source.url}
                                </a>
                              </div>
                            </div>
                            <div>
                              <button
                                onClick={() => window.open(source.url, '_blank')}
                                className="px-4 py-2 rounded-lg bg-[#6366f1]/10 hover:bg-[#6366f1]/20
                                  text-[#6366f1] transition-colors text-sm"
                              >
                                Test Source
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* Subtitles */}
                      {streamingData.data?.tracks?.length > 0 && (
                        <div className="mt-4">
                          <h4 className="text-md text-[#8b5cf6] mb-2">Subtitles</h4>
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                            {streamingData.data.tracks.map((track, index) => (
                              <div key={index} className="bg-background/20 p-3 rounded-lg border border-white/5 text-sm">
                                <div className="text-white">
                                  {track.label} {track.default && <span className="text-[#6366f1]">(Default)</span>}
                                </div>
                                <div className="text-gray-400 text-xs truncate">
                                  <a href={track.file} target="_blank" rel="noopener noreferrer" className="hover:text-[#6366f1]">
                                    {track.file}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Video Markers */}
                      {(streamingData.data?.intro || streamingData.data?.outro) && (
                        <div className="mt-4">
                          <h4 className="text-md text-[#8b5cf6] mb-2">Video Markers</h4>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                            {streamingData.data?.intro && (
                              <div className="bg-background/20 p-3 rounded-lg border border-white/5">
                                <div className="text-[#6366f1] text-sm font-medium mb-1">Intro</div>
                                <div className="text-xs text-white">
                                  Start: {streamingData.data.intro.start}s | End: {streamingData.data.intro.end}s
                                </div>
                                <div className="text-xs text-gray-400">
                                  Duration: {Math.floor(streamingData.data.intro.end - streamingData.data.intro.start)}s
                                </div>
                              </div>
                            )}
                            {streamingData.data?.outro && (
                              <div className="bg-background/20 p-3 rounded-lg border border-white/5">
                                <div className="text-[#d946ef] text-sm font-medium mb-1">Outro</div>
                                <div className="text-xs text-white">
                                  Start: {streamingData.data.outro.start}s | End: {streamingData.data.outro.end}s
                                </div>
                                <div className="text-xs text-gray-400">
                                  Duration: {Math.floor(streamingData.data.outro.end - streamingData.data.outro.start)}s
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <Tabs>
                    <Tab title="Player Example">
                      <div className="bg-background/30 p-5 rounded-xl border border-white/10">
                        <h3 className="text-lg font-medium text-white mb-4">Video Player</h3>
                        {streamingData?.data?.sources && streamingData.data.sources.length > 0 ? (
                          <>
                            <div className="aspect-video w-full overflow-hidden relative rounded-lg">
                              <PremiumPlayer
                                url={streamingData.data.sources.find(source => 
                                  source.url.includes('.m3u8') || 
                                  source.type === 'hls' || 
                                  source.quality === 'auto'
                                )?.url || streamingData.data.sources[0].url}
                                poster={animeData?.data?.anime?.info?.poster}
                                title={`${animeData?.data?.anime?.info?.name || 'Anime'} - Episode ${selectedEpisodeNumber}`}
                                quality={streamingData.data.sources.find(source => source.quality)?.quality || 'HD'}
                                subtitles={streamingData.data.tracks || []}
                                introMarker={streamingData.data.intro}
                                outroMarker={streamingData.data.outro}
                              />
                            </div>
                            <div className="mt-4 grid grid-cols-1">
                              <p className="text-sm text-gray-400">Player loaded successfully.</p>
                            </div>
                          </>
                        ) : (
                          <div className="aspect-video bg-background/50 rounded-lg flex items-center justify-center">
                            <div className="text-center">
                              <Play className="w-12 h-12 text-[#6366f1] mx-auto mb-2" />
                              <p className="text-gray-300">No streaming sources available</p>
                              <p className="text-xs text-gray-500 mt-1">Select an anime and episode first</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab>
                    <Tab title="Raw JSON">
                      <JsonView data={streamingData} />
                    </Tab>
                  </Tabs>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center p-12 text-gray-400">
                  <AlertCircle className="w-12 h-12 mb-4" />
                  <p>No streaming data available. Enter an anime ID and episode number, then click "Get Streaming Links".</p>
                </div>
              )}
            </div>
          )}
        </section>

        {/* Modal */}
        {selectedAnime && (
          <AnimeModal
            anime={selectedAnime}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TestPage;
