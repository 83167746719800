// src/services/search.js
import axios from 'axios';
import { searchAnime } from './Consumet';
import { fetchFromAPI } from './api';

// TMDB API base URL and key
const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_BASE_URL = 'https://api.themoviedb.org/3/';
const CONSUMET_BASE_URL = 'https://consumet-api-omega-one.vercel.app';

/**
 * Gets search suggestions based on a query
 * @param {string} query - The search query
 * @returns {Promise<Array>} - An array of search suggestions
 */
export const getSearchSuggestions = async (query) => {
  try {
    const response = await fetchFromAPI(`/search/multi?query=${encodeURIComponent(query)}&include_adult=false`);
    
    // Enhanced processing to include additional details
    return response.results.map(item => {
      // Process dates and handle missing data
      if (item.release_date) {
        item.year = item.release_date.split('-')[0];
      } else if (item.first_air_date) {
        item.year = item.first_air_date.split('-')[0];
      }
      
      // Truncate overview text
      if (item.overview && item.overview.length > 100) {
        item.short_overview = `${item.overview.substring(0, 100)}...`;
      } else {
        item.short_overview = item.overview;
      }
      
      return item;
    });
  } catch (error) {
    console.error('Error fetching search suggestions:', error);
    return [];
  }
};

/**
 * Fetches complete search results for a query
 * @param {string} query - The search query
 * @param {number} page - The page number (default: 1)
 * @returns {Promise<Object>} - Search results with pagination
 */
export const getSearchResults = async (query, page = 1) => {
  try {
    return await fetchFromAPI(`/search/multi`, {
      query: encodeURIComponent(query),
      page,
      include_adult: false
    });
  } catch (error) {
    console.error('Error fetching search results:', error);
    return { results: [], total_pages: 0, total_results: 0, page: 1 };
  }
};

// Enhanced search content function to include anime and actors
export const searchContent = async (query) => {
  if (!query) return { movies: [], tvShows: [], anime: [], actors: [] };
  try {
    // Fetch results from TMDB
    const tmdbResponse = await axios.get(`${TMDB_BASE_URL}search/multi`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'en-US',
        include_adult: true,
      },
    });

    // Fetch anime results from Consumet
    const animeResults = await searchAnime(query);

    // Process TMDB results with proper media type normalization
    const movies = tmdbResponse.data.results
      .filter(item => item.media_type === 'movie')
      .map(movie => ({
        ...movie,
        media_type: 'movie',
        type: 'movie'  // Add normalized type
      }));

    const tvShows = tmdbResponse.data.results
      .filter(item => item.media_type === 'tv')
      .map(show => ({
        ...show,
        media_type: 'tv',
        type: 'tv'  // Add normalized type
      }));

    const actors = tmdbResponse.data.results
      .filter(item => item.media_type === 'person')
      .map(actor => ({
        ...actor,
        media_type: 'person',
        type: 'person'  // Add normalized type
      }));
    
    // Process anime results with proper type
    const processedAnimeResults = animeResults.map(anime => ({
      id: anime.id,
      title: anime.title.english || anime.title.romaji,
      description: anime.description,
      image: anime.image,
      media_type: 'anime',
      type: 'anime',  // Add normalized type
      rating: anime.rating,
      genres: anime.genres,
      status: anime.status,
      releaseDate: anime.releaseDate,
    }));

    return { 
      movies, 
      tvShows, 
      anime: processedAnimeResults,
      actors 
    };
  } catch (error) {
    console.error('Error fetching search results', error);
    return { movies: [], tvShows: [], anime: [], actors: [] };
  }
};

// New comprehensive search function that combines all sources
export const comprehensiveSearch = async (query) => {
  if (!query) return { tmdb: [], anime: [] };
  try {
    // Parallel searches
    const [tmdbResponse, animeResults] = await Promise.all([
      axios.get(`${TMDB_BASE_URL}search/multi`, {
        params: {
          api_key: TMDB_API_KEY,
          query: query,
          language: 'en-US',
          include_adult: true,
        },
      }),
      searchAnime(query)
    ]);

    // Process TMDB results
    const tmdbResults = tmdbResponse.data.results.map(item => ({
      ...item,
      source: 'tmdb'
    }));

    // Process Anime Results
    const processedAnimeResults = animeResults.map(anime => ({
      id: anime.id,
      title: anime.title.english || anime.title.romaji,
      image: anime.image,
      media_type: 'anime',
      rating: anime.rating,
      source: 'consumet',
      description: anime.description,
      genres: anime.genres
    }));

    return {
      tmdb: tmdbResults,
      anime: processedAnimeResults
    };
  } catch (error) {
    console.error('Comprehensive search error:', error);
    return { tmdb: [], anime: [] };
  }
};

// Advanced Search Function with Multiple Sources
export const advancedMultiSearch = async (params) => {
  const { 
    query, 
    type = 'all', 
    genres = [], 
    minRating = 0, 
    yearFrom, 
    yearTo 
  } = params;

  try {
    const results = {
      movies: [],
      tvShows: [],
      anime: []
    };

    // TMDB Search
    if (type === 'all' || type === 'movies' || type === 'tv') {
      const tmdbResponse = await axios.get(`${TMDB_BASE_URL}search/multi`, {
        params: {
          api_key: TMDB_API_KEY,
          query: query,
          language: 'en-US',
          include_adult: false,
        },
      });

      // Filter TMDB results based on additional parameters
      results.movies = tmdbResponse.data.results
        .filter(item => 
          (type === 'all' || item.media_type === type) &&
          (minRating ? item.vote_average >= minRating : true) &&
          (yearFrom ? parseInt(item.release_date?.split('-')[0] || 0) >= yearFrom : true) &&
          (yearTo ? parseInt(item.release_date?.split('-')[0] || 9999) <= yearTo : true)
        );
    }

    // Anime Search
    if (type === 'all' || type === 'anime') {
      const animeResults = await searchAnime(query);
      
      results.anime = animeResults.filter(anime => 
        (genres.length === 0 || genres.some(genre => anime.genres.includes(genre))) &&
        (minRating ? anime.rating >= minRating : true) &&
        (yearFrom ? parseInt(anime.releaseDate?.split('-')[0] || 0) >= yearFrom : true) &&
        (yearTo ? parseInt(anime.releaseDate?.split('-')[0] || 9999) <= yearTo : true)
      );
    }

    return results;
  } catch (error) {
    console.error('Advanced multi-source search error:', error);
    return { movies: [], tvShows: [], anime: [] };
  }
};