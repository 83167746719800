const FAVORITES_KEY = 'streamify_favorites';

/**
 * Favorites service for managing user favorite movies and shows
 */

// Get all favorites from localStorage
export const getFavorites = () => {
  try {
    const favorites = localStorage.getItem(FAVORITES_KEY);
    return favorites ? JSON.parse(favorites) : [];
  } catch (error) {
    console.error('Error retrieving favorites:', error);
    return [];
  }
};

// Add a movie or show to favorites
export const addToFavorites = (item) => {
  try {
    const favorites = getFavorites();
    
    // Check if item already exists in favorites
    if (!favorites.some(favItem => favItem.id === item.id)) {
      const updatedFavorites = [...favorites, item];
      localStorage.setItem(FAVORITES_KEY, JSON.stringify(updatedFavorites));
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error adding to favorites:', error);
    return false;
  }
};

// Remove a movie or show from favorites
export const removeFromFavorites = (id) => {
  try {
    const favorites = getFavorites();
    const updatedFavorites = favorites.filter(item => item.id !== id);
    localStorage.setItem(FAVORITES_KEY, JSON.stringify(updatedFavorites));
    return true;
  } catch (error) {
    console.error('Error removing from favorites:', error);
    return false;
  }
};

// Check if an item is in favorites
export const isInFavorites = (id) => {
  try {
    const favorites = getFavorites();
    return favorites.some(item => item.id === id);
  } catch (error) {
    console.error('Error checking favorites:', error);
    return false;
  }
};

// Clear all favorites
export const clearFavorites = () => {
  try {
    localStorage.removeItem(FAVORITES_KEY);
    return true;
  } catch (error) {
    console.error('Error clearing favorites:', error);
    return false;
  }
};