import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { toast, Toaster } from 'react-hot-toast';
import { 
  FaUser, 
  FaEnvelope, 
  FaComment, 
  FaPaperPlane,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
  FaInstagram,
  FaLinkedin
} from 'react-icons/fa';

const ContactUs = () => {
  // Form State
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Validation State
  const [errors, setErrors] = useState({});

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Validate Form
  const validateForm = () => {
    const newErrors = {};

    // Name Validation
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (formData.name.trim().length < 2) {
      newErrors.name = 'Name must be at least 2 characters';
    }

    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    // Message Validation
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.trim().length < 10) {
      newErrors.message = 'Message must be at least 10 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate Form
    if (validateForm()) {
      // Simulate form submission
      toast.success('Message sent successfully!', {
        icon: '✉️',
        style: {
          background: '#2C3E50',
          color: '#ECF0F1',
          border: '2px solid #b8962e',
          borderRadius: '12px',
        }
      });

      // Reset form after submission
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } else {
      toast.error('Please correct the errors', {
        style: {
          background: '#2C3E50',
          color: '#ECF0F1',
          border: '2px solid #ff4444',
          borderRadius: '12px',
        }
      });
    }
  };

  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 50 
    },
    visible: {
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white p-6 overflow-hidden"
    >
      {/* Toast Notifications */}
      <Toaster 
        position="top-right"
        reverseOrder={false}
      />

      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>

      <div className="max-w-6xl mx-auto relative z-10">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          className="text-center mb-12 mt-14"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-gold-500 mb-4">
            Contact Streamify
          </h1>
          <p className="text-gold-300 max-w-xl mx-auto">
            We're here to help! Reach out to us with any questions, feedback, or support inquiries.
          </p>
        </motion.div>

        {/* Contact Container */}
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid md:grid-cols-2 gap-12"
        >
          {/* Contact Form */}
          <motion.div 
            variants={itemVariants}
            className="bg-zinc-800/50 p-8 rounded-xl border border-gold-500/20 shadow-lg"
          >
            <h2 className="text-2xl font-semibold text-gold-500 mb-6">
              Send Us a Message
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Input */}
              <div>
                <div className="flex items-center mb-2">
                  <FaUser className="mr-2 text-gold-500" />
                  <label className="text-gold-400">Name</label>
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`
                    w-full p-3 rounded-lg 
                    bg-background/40 border 
                    ${errors.name 
                      ? 'border-red-500' 
                      : 'border-gold-500/30'}
                    focus:outline-none focus:ring-2 focus:ring-gold-500
                  `}
                  placeholder="Your Name"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                )}
              </div>

              {/* Email Input */}
              <div>
                <div className="flex items-center mb-2">
                  <FaEnvelope className="mr-2 text-gold-500" />
                  <label className="text-gold-400">Email</label>
                </div>
                <input type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`
                    w-full p-3 rounded-lg 
                    bg-background/40 border 
                    ${errors.email 
                      ? 'border-red-500' 
                      : 'border-gold-500/30'}
                    focus:outline-none focus:ring-2 focus:ring-gold-500
                  `}
                  placeholder="Your Email"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>

              {/* Message Input */}
              <div>
                <div className="flex items-center mb-2">
                  <FaComment className="mr-2 text-gold-500" />
                  <label className="text-gold-400">Message</label>
                </div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className={`
                    w-full p-3 rounded-lg 
                    bg-background/40 border 
                    ${errors.message 
                      ? 'border-red-500' 
                      : 'border-gold-500/30'}
                    focus:outline-none focus:ring-2 focus:ring-gold-500
                  `}
                  placeholder="Your Message"
                  rows="4"
                />
                {errors.message && (
                  <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                )}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="
                  bg-gold-500 text-black 
                  px-6 py-3 rounded-full 
                  font-bold text-lg 
                  hover:bg-gold-600 
                  transition-all duration-300
                "
              >
                <FaPaperPlane className="inline mr-2" />
                Send Message
              </button>
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div 
            variants={itemVariants}
            className="bg-zinc-800/50 p-8 rounded-xl border border-gold-500/20 shadow-lg"
          >
            <h2 className="text-2xl font-semibold text-gold-500 mb-6">
              Get in Touch
            </h2>
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="mr-2 text-gold-500" />
              <p className="text-gold-300">N/A</p>
            </div>
            <div className="flex items-center mb-4">
              <FaPhoneAlt className="mr-2 text-gold-500" />
              <p className="text-gold-300">N/A</p>
            </div>
            <div className="flex items-center mb-4">
              <FaEnvelope className="mr-2 text-gold-500" />
              <p className="text-gold-300">support@streamify.com</p>
            </div>
            <div className="flex space-x-4 mt-6">
              <a href="https://twitter.com/streamify" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="text-gold-500 text-2xl hover:text-gold-600" />
              </a>
              <a href="https://instagram.com/streamify" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-gold-500 text-2xl hover:text-gold-600" />
              </a>
              <a href="https://linkedin.com/company/streamify" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="text-gold-500 text-2xl hover:text-gold-600" />
              </a>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ContactUs;