import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, useScroll, useTransform, useSpring } from 'framer-motion';
import toast, { Toaster } from 'react-hot-toast';
import { 
  FaUser, FaSignOutAlt, FaClock, FaBell, FaEdit,
  FaShieldAlt, FaKey, FaImage, FaTrash, FaHeart,
  FaChevronRight, FaMoon, FaSun, FaCrown, FaCalendar,
  FaEye, FaThumbsUp, FaDownload, FaUserCircle, FaCamera
} from 'react-icons/fa';
import { MdFavorite, MdPlayCircleFilled, MdSettings, MdDashboard } from 'react-icons/md';
import { getFavorites, removeFromFavorites } from '../services/favorites';
import { FaStar } from 'react-icons/fa';

// Custom Toast Styles
const toastOptions = {
  style: {
    background: 'rgba(20, 20, 30, 0.9)',
    color: '#ECF0F1',
    border: '1px solid #b8962e',
    borderRadius: '12px',
    padding: '16px',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)'
  },
  success: {
    style: {
      background: 'rgba(20, 20, 30, 0.9)',
      color: '#2ECC71',
      border: '1px solid #2ECC71',
    },
    icon: '✅'
  },
  error: {
    style: {
      background: 'rgba(20, 20, 30, 0.9)',
      color: '#E74C3C',
      border: '1px solid #E74C3C',
    },
    icon: '❌'
  }
};

// Animation Variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 100 }
  }
};

// Parallax Background Effect
const ParallaxBackground = () => {
  const { scrollYProgress } = useScroll();
  const y1 = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, -200]);
  const y3 = useTransform(scrollYProgress, [0, 1], [0, -150]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0.5]);

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden z-0">
      <motion.div 
        className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-indigo-900/30 via-background to-background"
        style={{ opacity }}
      />
      <motion.div 
        style={{ y: y1 }}
        className="absolute top-0 left-0 w-full h-[120%] opacity-20"
      >
        {Array.from({ length: 20 }).map((_, i) => (
          <motion.div
            key={`star-${i}`}
            className="absolute rounded-full bg-gold-300"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 3}px`,
              height: `${Math.random() * 3}px`,
              opacity: Math.random() * 0.8 + 0.2,
            }}
            animate={{
              opacity: [0.2, 0.8, 0.2],
            }}
            transition={{
              duration: Math.random() * 5 + 3,
              repeat: Infinity,
              delay: Math.random() * 5,
            }}
          />
        ))}
      </motion.div>
      
      <motion.div 
        className="absolute top-0 left-0 w-full h-[120%]"
        style={{ y: y2 }}
      >
        {Array.from({ length: 10 }).map((_, i) => (
          <motion.div
            key={`orb-${i}`}
            className="absolute rounded-full"
            style={{
              background: `radial-gradient(circle, rgba(184,150,46,0.3) 0%, rgba(184,150,46,0) 70%)`,
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 300 + 50}px`,
              height: `${Math.random() * 300 + 50}px`,
              opacity: 0.2,
              filter: 'blur(40px)'
            }}
          />
        ))}
      </motion.div>
      
      <motion.div 
        className="absolute bottom-0 left-0 w-full h-[40%]"
        style={{ y: y3 }}
      >
        <div 
          className="absolute bottom-0 w-full h-full bg-gradient-to-t from-background via-background/80 to-transparent"
        />
      </motion.div>
    </div>
  );
};

// Menu Item Component
const MenuItem = ({ active, icon, label, onClick }) => (
  <motion.li 
    onClick={onClick}
    className={`flex items-center space-x-4 p-3 rounded-xl cursor-pointer transition-all duration-300 ${
      active ? 'bg-gold-500/20 text-gold-400' : 'hover:bg-zinc-800/50'
    }`}
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className={`p-2 rounded-lg ${active ? 'bg-gold-500 text-black' : 'bg-zinc-800 text-gray-400'}`}>
      {icon}
    </div>
    <span className={active ? 'font-medium' : 'text-gray-400'}>{label}</span>
    {active && (
      <motion.div 
        className="ml-auto"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <FaChevronRight className="text-gold-400" />
      </motion.div>
    )}
  </motion.li>
);

// Stat Card Component
const StatCard = ({ icon, title, value, subtitle, color }) => (
  <motion.div 
    variants={itemVariants}
    className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-6 shadow-xl border border-zinc-700/50 hover:border-gold-500/30 transition-all duration-300"
    whileHover={{ y: -5, boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)" }}
  >
    <div className="flex items-center mb-4 space-x-3">
      <div className={`p-3 rounded-xl ${color}`}>
        {icon}
      </div>
      <h2 className="text-xl font-bold text-white">{title}</h2>
    </div>
    <div className="text-3xl font-bold text-gold-400 mb-1">
      {value}
    </div>
    <p className="text-gray-400 text-sm">{subtitle}</p>
  </motion.div>
);

// Media Card Component
const MediaCard = ({ item, onRemove }) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  
  const handleClick = () => {
    const mediaType = item.media_type || (item.title ? 'movie' : 'tv');
    navigate(`/watch/${mediaType}/${item.id}`);
  };

  return (
    <motion.div 
      variants={itemVariants}
      className="relative group"
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
    >
      <motion.div 
        className="relative rounded-xl overflow-hidden shadow-2xl aspect-[2/3]"
        whileHover={{ 
          scale: 1.05, 
          boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.7)"
        }}
        whileTap={{ scale: 0.98 }}
      >
        <img 
          src={item.poster} 
          alt={item.title} 
          className="w-full h-full object-cover"
          loading="lazy"
        />
        
        <AnimatePresence>
          {isHovering && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent flex flex-col justify-end p-4"
            >
              <motion.h3 
                initial={{ y: 20 }}
                animate={{ y: 0 }}
                className="text-white font-bold line-clamp-1"
              >
                {item.title}
              </motion.h3>
              
              <motion.div 
                initial={{ y: 20 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.1 }}
                className="flex space-x-2 mt-2"
              >
                <button 
                  onClick={handleClick}
                  className="flex-1 bg-gold-500 hover:bg-gold-400 text-black p-2 rounded-lg flex justify-center items-center"
                >
                  <MdPlayCircleFilled className="mr-1" /> 
                  Play
                </button>
                
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(item.id);
                  }}
                  className="p-2 bg-red-500/80 hover:bg-red-600 text-white rounded-lg"
                >
                  <FaTrash />
                </button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

// Activity Timeline Item
const ActivityItem = ({ date, icon, title, subtitle, time }) => (
  <motion.div 
    variants={itemVariants}
    className="flex"
  >
    <div className="relative flex items-center justify-center">
      <div className="h-full w-0.5 bg-zinc-700 absolute"></div>
      <div className="w-10 h-10 rounded-full flex items-center justify-center bg-zinc-800 border border-zinc-700 z-10">
        {icon}
      </div>
    </div>
    <div className="flex-grow ml-4 pb-8">
      <div className="flex items-center justify-between">
        <h3 className="text-white font-medium">{title}</h3>
        <span className="text-gray-400 text-sm">{time}</span>
      </div>
      <p className="text-gray-400 mt-1">{subtitle}</p>
      <div className="text-xs text-gray-500 mt-1">{date}</div>
    </div>
  </motion.div>
);

// Subscription Badge
const SubscriptionBadge = ({ tier }) => {
  const tiers = {
    premium: {
      color: "bg-gradient-to-r from-gold-500 to-yellow-500",
      icon: <FaCrown />,
      label: "Premium"
    },
    standard: {
      color: "bg-gradient-to-r from-gray-400 to-gray-500",
      icon: <FaStar />,
      label: "Standard"
    },
    basic: {
      color: "bg-gradient-to-r from-zinc-600 to-zinc-700",
      icon: <FaUser />,
      label: "Basic"
    }
  };
  
  const { color, icon, label } = tiers[tier] || tiers.basic;
  
  return (
    <div className={`px-3 py-1.5 rounded-full flex items-center space-x-1 text-sm ${color}`}>
      {icon}
      <span className="font-semibold">{label}</span>
    </div>
  );
};

// Import FaStar if not already imported

const FavoritesSection = ({ favorites, onRemoveFavorite }) => {
  return (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6"
    >
      {favorites.length > 0 ? (
        favorites.map((item) => (
          <MediaCard 
            key={item.id} 
            item={item} 
            onRemove={onRemoveFavorite} 
          />
        ))
      ) : (
        <motion.div 
          variants={itemVariants}
          className="col-span-full text-center py-12"
        >
          <FaHeart className="mx-auto text-5xl text-gray-600 mb-3" />
          <h3 className="text-xl font-medium text-gray-400">No favorites yet</h3>
          <p className="text-gray-500 mt-2">
            Add movies and shows to your favorites to see them here
          </p>
        </motion.div>
      )}
    </motion.div>
  );
};

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [activeSection, setActiveSection] = useState('overview');
  const [favorites, setFavorites] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [darkMode, setDarkMode] = useState(true);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  
  // Scrolling progress animation
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, { stiffness: 100, damping: 30 });

  // Fetch and Load User Data
  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        setUserInfo({
          ...parsedUserInfo,
          subscription: parsedUserInfo.subscription || 'premium',
          joinDate: parsedUserInfo.joinDate || '2023-05-15',
          watchTimeMinutes: parsedUserInfo.watchTimeMinutes || 7845,
          recentActivity: parsedUserInfo.recentActivity || []
        });
        setEditedProfile({
          ...parsedUserInfo,
          subscription: parsedUserInfo.subscription || 'premium',
        });
      } catch (error) {
        console.error('Error parsing user info:', error);
        toast.error("Error loading profile", toastOptions.error);
        navigate('/login');
      }
    } else {
      toast.error("Please login to view your profile", toastOptions.error);
      navigate('/login');
    }
  }, [navigate]);

  // Fetch Favorites
  useEffect(() => {
    const favs = getFavorites();
    setFavorites(favs);
  }, []);

  // Remove Favorite
  const handleRemoveFavorite = (id) => {
    removeFromFavorites(id);
    setFavorites(prev => prev.filter(item => item.id !== id));
    toast.success("Removed from favorites", toastOptions.success);
  };

  // Profile Edit Handlers
  const handleEditToggle = () => {
    if (editMode) {
      // Save changes logic
      localStorage.setItem('userInfo', JSON.stringify({
        ...userInfo,
        ...editedProfile
      }));
      setUserInfo(prev => ({...prev, ...editedProfile}));
      toast.success("Profile updated successfully", toastOptions.success);
    }
    setEditMode(!editMode);
  };

  const handleProfileImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedProfile(prev => ({
          ...prev,
          profilePicture: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Logout Handler
  const handleLogout = () => {
    toast.success("Logged out successfully", toastOptions.success);
    localStorage.removeItem('userInfo');
    sessionStorage.removeItem('userInfo');
    navigate('/login');
  };

  // Format minutes to hours and minutes
  const formatWatchTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  // Mock activity data - in a real app, this would come from the backend
  const activityData = [
    {
      id: 1,
      icon: <FaEye className="text-blue-400" />,
      title: "Watched Inception",
      subtitle: "Completed watching",
      time: "2h 28m",
      date: "Today"
    },
    {
      id: 2,
      icon: <MdFavorite className="text-red-400" />,
      title: "Added to Favorites",
      subtitle: "The Matrix",
      time: "12:30 PM",
      date: "Yesterday"
    },
    {
      id: 3,
      icon: <FaUserCircle className="text-green-400" />,
      title: "Updated Profile",
      subtitle: "Changed profile picture",
      time: "3:45 PM",
      date: "3 days ago"
    },
    {
      id: 4,
      icon: <FaThumbsUp className="text-purple-400" />,
      title: "Liked a Review",
      subtitle: "Breaking Bad Season 5 Review",
      time: "7:20 PM",
      date: "1 week ago"
    },
    {
      id: 5,
      icon: <FaDownload className="text-teal-400" />,
      title: "Downloaded for Offline",
      subtitle: "Stranger Things S04E01",
      time: "9:15 AM",
      date: "2 weeks ago"
    }
  ];

  // Render Sections
  const renderSection = useCallback(() => {
    switch (activeSection) {
      case 'overview':
        return (
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-8"
          >
            {/* Welcome Section */}
            <motion.div 
              variants={itemVariants}
              className="bg-gradient-to-r from-zinc-900/80 to-zinc-800/50 backdrop-blur-md rounded-2xl p-6 border border-zinc-700/50 shadow-xl"
            >
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
                <div>
                  <h2 className="text-2xl font-bold text-white">Welcome back, <span className="text-gold-400">{userInfo?.username}</span></h2>
                  <p className="text-gray-400 mt-1">
                    Member since {new Date(userInfo?.joinDate).toLocaleDateString()} · <SubscriptionBadge tier={userInfo?.subscription} />
                  </p>
                </div>
                <div>
                  <button 
                    onClick={handleLogout} 
                    className="flex items-center px-4 py-2 rounded-lg bg-red-500/10 hover:bg-red-500/20 text-red-500 transition-all"
                  >
                    <FaSignOutAlt className="mr-2" /> Sign Out
                  </button>
                </div>
              </div>
            </motion.div>
          
            {/* Stats Summary */}
            <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <StatCard 
                icon={<MdFavorite className="text-xl" />}
                title="Favorites"
                value={favorites.length}
                subtitle="Saved Movies & Shows"
                color="bg-red-500/20 text-red-400"
              />
              
              <StatCard 
                icon={<FaClock className="text-xl" />}
                title="Watch Time"
                value={formatWatchTime(userInfo?.watchTimeMinutes || 0)}
                subtitle="This Month"
                color="bg-blue-500/20 text-blue-400"
              />
              
              <StatCard 
                icon={<FaCalendar className="text-xl" />}
                title="Upcoming"
                value="2 Releases"
                subtitle="On Your Watchlist"
                color="bg-purple-500/20 text-purple-400"
              />
            </motion.div>
            
            {/* Favorites Preview */}
            <motion.div variants={itemVariants} className="space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-white">Recently Favorited</h2>
                <button 
                  onClick={() => setActiveSection('favorites')}
                  className="text-gold-400 hover:text-gold-300 text-sm flex items-center"
                >
                  See All <FaChevronRight className="ml-1 text-xs" />
                </button>
              </div>
              
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4">
                {favorites.slice(0, 5).map((item) => (
                  <MediaCard 
                    key={item.id} 
                    item={item} 
                    onRemove={handleRemoveFavorite} 
                  />
                ))}
                
                {favorites.length === 0 && (
                  <div className="col-span-full text-center py-8">
                    <FaHeart className="mx-auto text-4xl text-gray-600 mb-3" />
                    <p className="text-gray-400">Add something to your favorites!</p>
                  </div>
                )}
              </div>
            </motion.div>
            
            {/* Recent Activity */}
            <motion.div variants={itemVariants} className="space-y-4">
              <h2 className="text-xl font-bold text-white">Recent Activity</h2>
              <div className="bg-zinc-800/50 backdrop-blur-md rounded-2xl p-6 border border-zinc-700/50">
                <div className="space-y-3">
                  {activityData.slice(0, 3).map((activity) => (
                    <ActivityItem 
                      key={activity.id}
                      icon={activity.icon}
                      title={activity.title}
                      subtitle={activity.subtitle}
                      time={activity.time}
                      date={activity.date}
                    />
                  ))}
                </div>
                
                <button 
                  onClick={() => setActiveSection('activity')}
                  className="mt-4 text-gold-400 hover:text-gold-300 flex items-center"
                >
                  View Full Activity <FaChevronRight className="ml-1 text-xs" />
                </button>
              </div>
            </motion.div>
          </motion.div>
        );

      case 'favorites':
        return (
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6"
          >
            <motion.div variants={itemVariants} className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-white">Your Favorites</h2>
              <p className="text-gray-400">{favorites.length} items</p>
            </motion.div>
            
            <FavoritesSection 
              favorites={favorites} 
              onRemoveFavorite={handleRemoveFavorite} 
            />
          </motion.div>
        );

      case 'settings':
        return (
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="visible" 
            className="space-y-6"
          >
            <motion.h2 variants={itemVariants} className="text-2xl font-bold text-white">Account Settings</motion.h2>
            
            <motion.div 
              variants={itemVariants}
              className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-6 border border-zinc-700/50 shadow-xl"
            >
              <div className="flex flex-col md:flex-row gap-8">
                {/* Profile Image Section */}
                <div className="flex flex-col items-center space-y-4">
                  <div className="relative group">
                    <div className={`w-40 h-40 rounded-full overflow-hidden ring-4 ${editMode ? 'ring-gold-500' : 'ring-zinc-700'} shadow-lg`}>
                      <img 
                        src={editedProfile.profilePicture || 'https://via.placeholder.com/150?text=User'}
                        alt="Profile" 
                        className="w-full h-full object-cover"
                      />
                    </div>
                    
                    {editMode && (
                      <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full cursor-pointer"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <FaCamera className="text-white text-2xl" />
                        <input 
                          ref={fileInputRef}
                          type="file" 
                          className="hidden" 
                          accept="image/*"
                          onChange={handleProfileImageUpload}
                        />
                      </motion.div>
                    )}
                  </div>
                  
                  <div className="text-center">
                    <h3 className="text-xl font-semibold text-white">{userInfo?.username}</h3>
                    <p className="text-gray-400">{userInfo?.email}</p>
                    <div className="mt-2">
                      <SubscriptionBadge tier={userInfo?.subscription} />
                    </div>
                  </div>
                </div>

                {/* Form Fields */}
                <div className="flex-1 space-y-5">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div>
                      <label className="block text-gold-400 mb-2 text-sm">Username</label>
                      {editMode ? (
                        <input 
                          type="text"
                          value={editedProfile.username || ''}
                          onChange={(e) => setEditedProfile(prev => ({
                            ...prev, 
                            username: e.target.value
                          }))}
                          className="w-full p-3 bg-zinc-900/80 text-white rounded-lg border border-zinc-700 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/20 focus:outline-none transition-all"
                        />
                      ) : (
                        <p className="text-white p-3 bg-zinc-900/40 rounded-lg border border-zinc-800">{userInfo?.username}</p>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-gold-400 mb-2 text-sm">Email</label>
                      {editMode ? (
                        <input 
                          type="email"
                          value={editedProfile.email || ''}
                          onChange={(e) => setEditedProfile(prev => ({
                            ...prev, 
                            email: e.target.value
                          }))}
                          className="w-full p-3 bg-zinc-900/80 text-white rounded-lg border border-zinc-700 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/20 focus:outline-none transition-all"
                        />
                      ) : (
                        <p className="text-white p-3 bg-zinc-900/40 rounded-lg border border-zinc-800">{userInfo?.email}</p>
                      )}
                    </div>
                  </div>
                  
                  {editMode && (
                    <>
                      <div>
                        <label className="block text-gold-400 mb-2 text-sm">Change Password</label>
                        <input 
                          type="password"
                          placeholder="New password"
                          className="w-full p-3 bg-zinc-900/80 text-white rounded-lg border border-zinc-700 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/20 focus:outline-none transition-all"
                        />
                      </div>
                      
                      <div>
                        <label className="block text-gold-400 mb-2 text-sm">Display Name (shown publicly)</label>
                        <input 
                          type="text"
                          value={editedProfile.displayName || editedProfile.username || ''}
                          onChange={(e) => setEditedProfile(prev => ({
                            ...prev, 
                            displayName: e.target.value
                          }))}
                          className="w-full p-3 bg-zinc-900/80 text-white rounded-lg border border-zinc-700 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/20 focus:outline-none transition-all"
                        />
                      </div>
                    </>
                  )}
                  
                  <div className="flex items-center justify-between mt-6">
                    <div className="flex space-x-4">
                      <button 
                        onClick={handleEditToggle}
                        className={`px-6 py-2.5 rounded-lg flex items-center ${
                          editMode 
                            ? 'bg-gold-500 hover:bg-gold-400 text-black' 
                            : 'bg-zinc-700 hover:bg-zinc-600 text-white'
                        } transition-all duration-200`}
                      >
                        {editMode ? (
                          <>
                            <FaKey className="mr-2" /> Save Changes
                          </>
                        ) : (
                          <>
                            <FaEdit className="mr-2" /> Edit Profile
                          </>
                        )}
                      </button>
                      
                      {editMode && (
                        <button 
                          onClick={() => setEditMode(false)}
                          className="px-6 py-2.5 rounded-lg bg-transparent hover:bg-zinc-700 text-gray-400 hover:text-white border border-zinc-700 transition-all duration-200"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                    
                    <button 
                      onClick={() => setDarkMode(!darkMode)}
                      className="p-3 rounded-lg bg-zinc-700 text-gray-300 hover:text-white transition-colors"
                    >
                      {darkMode ? <FaSun /> : <FaMoon />}
                    </button>
                  </div>
                  
                  {!editMode && userInfo?.recoveryCode && (
                    <div className="mt-8 p-4 border border-zinc-700 rounded-lg bg-zinc-900/40">
                      <div className="flex items-center mb-2">
                        <FaShieldAlt className="text-gold-500 mr-2" />
                        <h3 className="text-white font-medium">Account Recovery</h3>
                      </div>
                      <p className="text-gray-400 text-sm mb-3">Keep this code safe. You'll need it if you forget your password.</p>
                      <div className="bg-zinc-800 p-3 rounded font-mono text-gold-400 text-center tracking-widest">
                        {userInfo.recoveryCode}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
            
            {/* Privacy Section */}
            <motion.div 
              variants={itemVariants}
              className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-6 border border-zinc-700/50 shadow-xl"
            >
              <h3 className="text-white text-xl font-semibold mb-4">Privacy & Notifications</h3>
              
              <div className="space-y-4">
                <div className="flex items-center justify-between border-b border-zinc-700 pb-4">
                  <div>
                    <h4 className="text-white">Email Notifications</h4>
                    <p className="text-gray-400 text-sm">Get updates about new releases</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked={true} />
                    <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gold-500"></div>
                  </label>
                </div>
                
                <div className="flex items-center justify-between border-b border-zinc-700 pb-4">
                  <div>
                    <h4 className="text-white">Watch History</h4>
                    <p className="text-gray-400 text-sm">Remember what you've watched</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked={true} />
                    <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gold-500"></div>
                  </label>
                </div>
                
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-white">Autoplay Next Episode</h4>
                    <p className="text-gray-400 text-sm">Automatically play the next episode</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked={true} />
                    <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gold-500"></div>
                  </label>
                </div>
              </div>
            </motion.div>
          </motion.div>
        );

      case 'activity':
        return (
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6"
          >
            <motion.h2 variants={itemVariants} className="text-2xl font-bold text-white">Activity Timeline</motion.h2>
            
            <motion.div 
              variants={itemVariants}
              className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-6 border border-zinc-700/50 shadow-xl"
            >
              {activityData.map((activity) => (
                <ActivityItem 
                  key={activity.id}
                  icon={activity.icon}
                  title={activity.title}
                  subtitle={activity.subtitle}
                  time={activity.time}
                  date={activity.date}
                />
              ))}
            </motion.div>
          </motion.div>
        );

      default:
        return null;
    }
  }, [activeSection, userInfo, favorites, editMode, editedProfile, darkMode, activityData]);

  if (!userInfo) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="p-8 text-center">
          <div className="inline-block animate-spin mb-4">
            <div className="w-16 h-16 border-4 border-gold-500 border-t-transparent rounded-full"></div>
          </div>
          <p className="text-gray-400">Loading profile...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background relative overflow-x-hidden">
      <ParallaxBackground />
      <Toaster position="top-right" toastOptions={toastOptions} />
      
      {/* Scroll Progress */}
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gold-500 origin-left z-50"
        style={{ scaleX }}
      />

      <div className="max-w-7xl mt-24 mx-auto pb-20 relative z-10">
        <div className="flex flex-col md:flex-row gap-6 p-6">
          {/* Sidebar */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full md:w-64 md:shrink-0"
          >
            <div className="md:sticky md:top-24">
              <div className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-4 border border-zinc-700/50 shadow-xl mb-6">
                <div className="flex items-center space-x-4 p-2">
                  <div className="w-14 h-14 rounded-full overflow-hidden border-2 border-gold-500">
                    <img 
                      src={userInfo.profilePicture || 'https://via.placeholder.com/150?text=User'} 
                      alt="Profile" 
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>
                    <h3 className="text-white font-medium">{userInfo.username}</h3>
                    <SubscriptionBadge tier={userInfo.subscription} />
                  </div>
                </div>
              </div>
              
              <nav className="bg-zinc-800/70 backdrop-blur-md rounded-2xl p-4 border border-zinc-700/50 shadow-xl">
                <ul className="space-y-2">
                  <MenuItem 
                    active={activeSection === 'overview'} 
                    icon={<MdDashboard />} 
                    label="Overview" 
                    onClick={() => setActiveSection('overview')} 
                  />
                  <MenuItem 
                    active={activeSection === 'favorites'} 
                    icon={<FaHeart />} 
                    label="Favorites" 
                    onClick={() => setActiveSection('favorites')} 
                  />
                  <MenuItem 
                    active={activeSection === 'activity'} 
                    icon={<FaClock />} 
                    label="Activity" 
                    onClick={() => setActiveSection('activity')} 
                  />
                  <MenuItem 
                    active={activeSection === 'settings'} 
                    icon={<MdSettings />} 
                    label="Settings" 
                    onClick={() => setActiveSection('settings')} 
                  />
                </ul>
              </nav>
            </div>
          </motion.div>
          
          {/* Main Content */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="flex-1"
          >
            <div className="bg-zinc-800/30 backdrop-blur-md rounded-2xl p-6 md:p-8 border border-zinc-700/50 shadow-xl">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeSection}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderSection()}
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Profile;