import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronUp, ArrowLeft, Settings, Monitor, Globe, Tv, Play, VolumeX, ChevronLeft, Share2 } from 'lucide-react';
import MobileServerSelector from '../components/mobile/MobileServerSelector';
import MobileEpisodeSelector from '../components/mobile/MobileEpisodeSelector';

const MobileWatchPage = ({ 
  mediaDetails, 
  currentUrl, 
  videoServers, 
  mediaType,
  selectedSeason,
  selectedEpisode,
  onServerChange,
  onEpisodeSelect,
  episodes,
  seasons,
  watchedEpisodes,
  playerRef,
  onClose
}) => {
  const [activeTab, setActiveTab] = useState(mediaType === 'tv' ? 'episodes' : 'servers');
  const [showVideoControls, setShowVideoControls] = useState(false);

  // Add touch handler with timeout
  const handleVideoTouch = () => {
    setShowVideoControls(true);
    // Auto-hide controls after 3 seconds
    setTimeout(() => setShowVideoControls(false), 3000);
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-background">
      {/* Navigation Bar - Fixed */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-b from-black via-black/80 to-transparent">
        <div className="px-4 py-3 flex items-center justify-between">
          <button onClick={onClose} className="p-2 rounded-full hover:bg-white/10">
            <ChevronLeft className="w-5 h-5 text-white" />
          </button>
          <h1 className="text-white font-medium text-sm truncate max-w-[60%]">
            {mediaDetails?.title || mediaDetails?.name}
          </h1>
          <div className="w-10" /> {/* Spacer */}
        </div>

        {/* Episode Info Bar */}
        {mediaType === 'tv' && (
          <div className="px-4 py-2 flex items-center justify-center">
            <span className="text-gold-500 text-sm font-medium">
              S{selectedSeason} E{selectedEpisode}
            </span>
          </div>
        )}
      </div>

      {/* Video Section - Fixed height */}
      <div className="mt-[72px] w-full aspect-video bg-background relative">
        {/* Video Player */}
        <iframe
          ref={playerRef}
          src={currentUrl}
          title={`${mediaDetails?.title || mediaDetails?.name} video player`}
          className="w-full h-full absolute inset-0 z-10"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />

        {/* Touch Overlay - Only show when video controls are visible */}
        {showVideoControls && (
          <div 
            className="absolute inset-0 z-20"
            onClick={handleVideoTouch}
          />
        )}

        {/* Video Controls Overlay */}
        <AnimatePresence>
          {showVideoControls && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 z-30 flex flex-col"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Top Bar with opacity transition */}
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-gradient-to-b from-black/90 to-transparent p-4"
              >
                <div className="flex items-center gap-3">
                  <button 
                    onClick={onClose}
                    className="p-2 -m-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <ArrowLeft className="w-5 h-5 text-white" />
                  </button>
                  <div className="flex-1 min-w-0">
                    <h2 className="text-white font-medium text-sm truncate">
                      {mediaDetails?.title || mediaDetails?.name}
                      {mediaType === 'tv' && (
                        <span className="ml-2 text-gold-500 font-semibold">
                          S{selectedSeason} E{selectedEpisode}
                        </span>
                      )}
                    </h2>
                  </div>
                </div>
              </motion.div>

              {/* Bottom Controls with opacity transition */}
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="mt-auto bg-gradient-to-t from-black/90 to-transparent p-4"
              >
                {/* Progress Bar */}
                <div className="mb-4">
                  <div className="h-1 bg-white/20 rounded-full overflow-hidden">
                    <div className="h-full w-[45%] bg-gold-500 rounded-full" />
                  </div>
                </div>

                {/* Control Buttons */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <button>
                      <VolumeX className="w-5 h-5 text-white" />
                    </button>
                    <span className="text-xs text-white/70">45:30</span>
                  </div>
                  <button>
                    <Monitor className="w-5 h-5 text-white" />
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Double tap area for showing controls */}
      <div 
        className="absolute inset-0 z-5"
        onDoubleClick={handleVideoTouch}
      />

      {/* Controls Section - Scrollable */}
      <div className="flex-1 flex flex-col min-h-0 bg-background/95">
        {/* Fixed Tabs */}
        <div className="sticky top-0 z-10 bg-background p-3 border-b border-white/10">
          <div className="flex gap-2 p-1 bg-white/5 rounded-xl">
            {mediaType === 'tv' && (
              <TabButton
                active={activeTab === 'episodes'}
                onClick={() => setActiveTab('episodes')}
                icon={<Tv className="w-4 h-4" />}
                label="Episodes"
              />
            )}
            <TabButton
              active={activeTab === 'servers'}
              onClick={() => setActiveTab('servers')}
              icon={<Globe className="w-4 h-4" />}
              label="Servers"
            />
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto overscroll-contain">
          <div className="p-4 pb-safe">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'episodes' && mediaType === 'tv' ? (
                  <MobileEpisodeSelector
                    seasons={seasons}
                    episodes={episodes}
                    currentSeason={selectedSeason}
                    currentEpisode={selectedEpisode}
                    onSelect={onEpisodeSelect}
                    watchedEpisodes={watchedEpisodes}
                  />
                ) : (
                  <MobileServerSelector
                    servers={videoServers}
                    activeServer={currentUrl}
                    onServerChange={onServerChange}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

// New Premium Tab Button Component
const TabButton = ({ active, onClick, icon, label }) => (
  <motion.button
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={`flex-1 flex items-center justify-center gap-2 py-3 rounded-lg
               transition-all duration-300 ${
                 active
                   ? 'bg-gold-500 text-black shadow-lg'
                   : 'text-white/70 hover:bg-white/5'
               }`}
  >
    {icon}
    <span className="text-sm font-medium">{label}</span>
  </motion.button>
);

export default MobileWatchPage;
