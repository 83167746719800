import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
   FaStar, FaCalendarAlt, FaClock,
   FaSpinner, FaListUl,
    FaChevronRight
} from 'react-icons/fa';
import TrailerModal from '../components/TrailerModal';
import { getMediaDetails, getCastDetails, getRecommendedContent } from '../services/api';
import { addToFavorites, removeFromFavorites, getFavorites } from '../services/favorites';
import RecommendedContent from '../components/RecommendedContent';
import { 
  Play, Film, Share2, 
  Plus, Check, 
} from 'lucide-react';
import Toast from '../components/Toast';
import { useToast } from '../hooks/useToast';

const Details = () => {
  const { id, mediaType } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [cast, setCast] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [trailerKey, setTrailerKey] = useState(null);
  const [isTrailerOpen, setIsTrailerOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [showFullCast, setShowFullCast] = useState(false);
  const { toast, showToast, setToast } = useToast();

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: details?.title || details?.name,
          text: details?.overview,
          url: window.location.href
        });
        showToast('Content shared successfully!', 'success');
      } else {
        await navigator.clipboard.writeText(window.location.href);
        showToast('Link copied to clipboard!', 'success');
      }
    } catch (error) {
      showToast('Failed to share content', 'error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [mediaDetails, castData, recommendedData] = await Promise.all([
          getMediaDetails(id, mediaType),
          getCastDetails(id, mediaType),
          getRecommendedContent(id, mediaType)
        ]);

        if (!mediaDetails) throw new Error('Content not found');
        
        setDetails(mediaDetails);
        setCast(castData?.slice(0, 6) || []);
        setRecommendations(recommendedData || []);

        const videos = mediaDetails.videos?.results || [];
        const trailer = videos.find(video => 
          video.type === "Trailer" && video.site === "YouTube"
        ) || videos[0];
        setTrailerKey(trailer?.key);

        const favorites = getFavorites();
        setIsFavorite(favorites.some(item => item.id === Number(id)));
      } catch (err) {
        setError(err.message || 'Failed to load content');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, mediaType]);

  const handleFavoriteToggle = () => {
    if (!details) return;
    
    try {
      if (isFavorite) {
        removeFromFavorites(details.id);
        showToast('Removed from watchlist', 'info');
      } else {
        addToFavorites({
          id: details.id,
          title: details.title || details.name,
          poster_path: details.poster_path,
          media_type: mediaType
        });
        showToast('Added to watchlist', 'success');
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      showToast('Failed to update watchlist', 'error');
    }
  };

  const tabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'cast', label: 'Cast & Crew' },
    { id: 'more', label: 'Recommended' }
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-background/95 flex items-center justify-center">
        <motion.div
          animate={{ 
            rotate: 360,
            scale: [1, 1.2, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="text-gold-500"
        >
          <FaSpinner className="w-12 h-12" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-zinc-900 flex items-center justify-center">
        <div className="text-center space-y-4">
          <p className="text-gold-500 text-xl">{error}</p>
          <button 
            onClick={() => navigate(-1)}
            className="bg-gold-500 text-black px-6 py-2 rounded-full hover:bg-gold-400"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  if (!details) return null;

  return (
    <div className="min-h-screen bg-background">
      <div className="relative min-h-[80vh]">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0"
        >
          <img
            src={`https://image.tmdb.org/t/p/original${details.backdrop_path}`}
            alt=""
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/60 to-transparent" />
        </motion.div>

        <div className="relative container mx-auto px-4 pt-32 pb-16">
          <div className="grid md:grid-cols-[320px_1fr] gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="hidden md:block"
            >
              <div className="sticky top-32 rounded-xl overflow-hidden shadow-2xl">
                <img
                  src={`https://image.tmdb.org/t/p/w500${details.poster_path}`}
                  alt={details.title || details.name}
                  className="w-full h-auto"
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="space-y-6"
            >
              <div className="space-y-4">
                <h1 className="text-4xl md:text-6xl font-bold text-white">
                  {details.title || details.name}
                </h1>
                
                <div className="flex flex-wrap items-center gap-4 text-sm text-gray-300">
                  <div className="flex items-center text-gold-500">
                    <FaStar className="mr-1" />
                    <span className="font-semibold">{details.vote_average?.toFixed(1)}</span>
                  </div>

                  {details.release_date && (
                    <div className="flex items-center">
                      <FaCalendarAlt className="mr-1 text-gold-500/70" />
                      <span>{new Date(details.release_date).getFullYear()}</span>
                    </div>
                  )}

                  {details.runtime && (
                    <div className="flex items-center">
                      <FaClock className="mr-1 text-gold-500/70" />
                      <span>{`${details.runtime} min`}</span>
                    </div>
                  )}

                  {details.number_of_seasons && (
                    <div className="flex items-center">
                      <FaListUl className="mr-1 text-gold-500/70" />
                      <span>{`${details.number_of_seasons} Season${details.number_of_seasons > 1 ? 's' : ''}`}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {details.genres?.map(genre => (
                  <span
                    key={genre.id}
                    className="px-3 py-1 rounded-full text-sm font-medium
                             bg-gold-500/10 text-gold-500 border border-gold-500/20"
                  >
                    {genre.name}
                  </span>
                ))}
              </div>

              <p className="text-gray-300 text-lg leading-relaxed max-w-3xl">
                {details.overview}
              </p>

              <div className="flex flex-wrap items-center gap-2 md:gap-4">
                <div className="flex items-center gap-2 md:gap-3">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => navigate(`/watch/${mediaType}/${id}`)}
                    className="group relative inline-flex items-center gap-2 md:gap-3 
                              px-4 md:px-8 py-2.5 md:py-4
                              bg-gradient-to-br from-gold-400 via-gold-500 to-gold-600
                              hover:from-gold-500 hover:via-gold-600 hover:to-gold-700
                              rounded-lg md:rounded-xl font-semibold text-base md:text-lg
                              shadow-[0_8px_25px_-10px_rgba(234,179,8,0.7)]
                              hover:shadow-[0_8px_30px_-8px_rgba(234,179,8,0.8)]
                              transition-all duration-300"
                  >
                    <div className="absolute -inset-1 bg-gradient-to-br from-gold-300 to-gold-600 
                                  rounded-lg md:rounded-xl blur-lg opacity-40 group-hover:opacity-60 
                                  transition-opacity duration-300" />
                    <Play className="w-4 h-4 md:w-5 md:h-5 relative z-10 transition-transform duration-300
                                   group-hover:transform group-hover:translate-x-0.5" />
                    <span className="relative z-10 bg-gradient-to-b from-black/80 to-black 
                                   bg-clip-text text-transparent font-bold tracking-wide
                                   text-sm md:text-base">
                      Watch Now
                    </span>
                  </motion.button>

                  {trailerKey && (
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => setIsTrailerOpen(true)}
                      className="group relative inline-flex items-center gap-2 md:gap-3 
                                px-3 md:px-6 py-2.5 md:py-4
                                bg-white/5 backdrop-blur-md
                                rounded-lg md:rounded-xl font-medium border border-white/10
                                hover:bg-white/10 hover:border-white/20
                                shadow-[0_0_20px_rgba(255,255,255,0.05)]
                                transition-all duration-300"
                    >
                      <Film className="w-4 h-4 md:w-5 md:h-5 text-gold-500 transition-transform duration-300
                                    group-hover:scale-110" />
                      <span className="text-white group-hover:text-gold-500 transition-colors
                                     text-sm md:text-base">
                        Trailer
                      </span>
                    </motion.button>
                  )}
                </div>

                <div className="flex items-center gap-2 md:gap-3">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleFavoriteToggle}
                    className={`group relative inline-flex items-center gap-2 md:gap-3 
                              px-3 md:px-6 py-2.5 md:py-4
                              rounded-lg md:rounded-xl font-medium transition-all duration-300
                              border backdrop-blur-md text-sm md:text-base
                              ${isFavorite 
                                ? 'bg-gold-500/20 border-gold-500/50 text-gold-500' 
                                : 'bg-white/5 border-white/10 text-white hover:bg-white/10'
                              }`}
                  >
                    <motion.div
                      initial={false}
                      animate={isFavorite ? { scale: [1.5, 1] } : { scale: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      {isFavorite ? (
                        <Check className="w-4 h-4 md:w-5 md:h-5 text-gold-500" />
                      ) : (
                        <Plus className="w-4 h-4 md:w-5 md:h-5 text-gold-500" />
                      )}
                    </motion.div>
                    <span className="relative z-10 hidden sm:inline">
                      {isFavorite ? 'Saved' : 'Save'}
                    </span>
                  </motion.button>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleShare}
                    className="group relative inline-flex items-center justify-center
                              p-2.5 md:p-4 bg-white/5 backdrop-blur-md 
                              rounded-lg md:rounded-xl
                              border border-white/10 hover:border-gold-500/30
                              shadow-[0_0_20px_rgba(255,255,255,0.05)]
                              transition-all duration-300"
                  >
                    <Share2 className="w-4 h-4 md:w-5 md:h-5 text-white/70 group-hover:text-gold-500 
                                     transition-transform duration-300 group-hover:scale-110" />
                    <span className="sr-only">Share</span>
                  </motion.button>
                </div>
              </div>

              <div className="pt-8 border-t border-white/10">
                <div className="flex space-x-6">
                  {tabs.map(tab => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`py-2 relative ${
                        activeTab === tab.id 
                          ? 'text-white font-semibold' 
                          : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      {tab.label}
                      {activeTab === tab.id && (
                        <motion.div
                          layoutId="activeTab"
                          className="absolute inset-x-0 -bottom-[1px] h-0.5 bg-gold-500"
                        />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <AnimatePresence mode="wait">
          {activeTab === 'overview' && (
            <motion.div
              key="overview"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-8"
            >
              <section className="grid md:grid-cols-2 gap-8">
                <div className="space-y-6 bg-background/30 rounded-2xl p-6 border border-gray-700/60 shadow-xl shadow-stone-950">
                  <h3 className="text-xl font-semibold text-white">Production Details</h3>
                  <div className="space-y-4 text-gray-300">
                    {details.production_companies?.length > 0 && (
                      <div>
                        <span className="text-gold-400">Production:</span>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {details.production_companies.map(company => (
                            <span key={company.id} className="text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                              {company.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    
                    {details.production_countries?.length > 0 && (
                      <div>
                        <span className="text-gold-400">Country:</span>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {details.production_countries.map(country => (
                            <span key={country.iso_3166_1} className="text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                              {country.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {details.spoken_languages?.length > 0 && (
                      <div>
                        <span className="text-gold-400">Languages:</span>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {details.spoken_languages.map(lang => (
                            <span key={lang.iso_639_1} className="text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                              {lang.english_name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="space-y-6 bg-background/30 rounded-2xl p-6 border border-gray-700/60 shadow-xl shadow-stone-950">
                  <h3 className="text-xl font-semibold text-white">Additional Info</h3>
                  <div className="space-y-4 text-gray-300">
                    <div>
                      <span className="text-gold-400">Status:</span>
                      <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">{details.status}</span>
                    </div>
                    
                    {mediaType === 'movie' ? (
                      <>
                        <div>
                          <span className="text-gold-400">Release Date:</span>
                          <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                            {new Date(details.release_date).toLocaleDateString()}
                          </span>
                        </div>
                        <div>
                          <span className="text-gold-400">Budget:</span>
                          <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                            ${details.budget?.toLocaleString() || 'N/A'}
                          </span>
                        </div>
                        <div >
                        <span className="text-gold-400 ">Revenue:</span>
                          <span className="ml-2  text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                            ${details.revenue?.toLocaleString() || 'N/A'}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className="text-gold-400">First Air Date:</span>
                          <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                            {new Date(details.first_air_date).toLocaleDateString()}
                          </span>
                        </div>
                        <div>
                          <span className="text-gold-400">Last Air Date:</span>
                          <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                            {new Date(details.last_air_date).toLocaleDateString()}
                          </span>
                        </div>
                        <div>
                          <span className="text-gold-400">Episodes:</span>
                          <span className="ml-2 text-sm bg-gold-400/10 text-gold-500 border border-gold-500/40 px-2 py-1 rounded-md shadow-sm shadow-stone-950">
                          {details.number_of_episodes}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>

              {details.keywords?.keywords?.length > 0 && (
                <section className="bg-background/30 rounded-xl p-6 border border-gray-700/60 shadow-xl shadow-stone-950">
                  <h3 className="text-xl font-semibold text-white mb-4">Keywords</h3>
                  <div className="flex flex-wrap gap-2">
                    {details.keywords.keywords.map(keyword => (
                      <span 
                        key={keyword.id}
                        className="text-sm bg-zinc-800 px-3 py-1 rounded-full text-gray-300"
                      >
                        {keyword.name}
                      </span>
                    ))}
                  </div>
                </section>
              )}
            </motion.div>
          )}

          {activeTab === 'cast' && (
            <motion.div
              key="cast"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-6"
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {(showFullCast ? cast : cast.slice(0, 12)).map(person => (
                  <motion.div
                    key={person.id}
                    whileHover={{ y: -5 }}
                    onClick={() => navigate(`/actor/${person.id}`)} // Add this onClick handler
                    className="bg-background/30 rounded-xl overflow-hidden border border-gray-700/60 
                              shadow-xl shadow-stone-950 cursor-pointer 
                              hover:border-gold-500/50 transition-all duration-300"
                  >
                    <div className="aspect-[2/3] relative">
                      <img
                        src={person.profile_path 
                          ? `https://image.tmdb.org/t/p/w300${person.profile_path}`
                          : '/placeholder-avatar.png'
                        }
                        alt={person.name}
                        className="w-full h-full object-cover"
                      />
                      {/* Add hover overlay */}
                      <div className="absolute inset-0 bg-background/0 hover:bg-background/50 
                                    transition-all duration-300 flex items-center 
                                    justify-center opacity-0 hover:opacity-100">
                        <span className="text-gold-500 text-sm font-medium">View Profile</span>
                      </div>
                    </div>
                    <div className="p-4">
                      <h4 className="font-medium text-white text-sm transition-colors group-hover:text-gold-500">
                        {person.name}
                      </h4>
                      <p className="text-xs text-gray-400 mt-1">{person.character}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
              
              {cast.length > 12 && (
                <div className="text-center">
                  <button
                    onClick={() => setShowFullCast(!showFullCast)}
                    className="inline-flex items-center gap-2 px-6 py-3 rounded-full
                             bg-zinc-800 text-white hover:bg-zinc-700 transition-colors"
                  >
                    {showFullCast ? 'Show Less' : 'View Full Cast'}
                    <FaChevronRight className={`transform transition-transform
                      ${showFullCast ? 'rotate-180' : ''}`} />
                  </button>
                </div>
              )}
            </motion.div>
          )}

          {activeTab === 'more' && (
            <motion.div
              key="more"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <RecommendedContent 
                items={recommendations} 
                title="More Like This" 
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <TrailerModal
        isOpen={isTrailerOpen}
        onClose={() => setIsTrailerOpen(false)}
        videoKey={trailerKey}
      />

      <AnimatePresence>
        {toast.show && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast({ show: false, message: '', type: 'success' })}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Details;
