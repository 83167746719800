import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Star, Clock, Calendar, X, Bookmark, Users, Tv, Subtitles, Volume2, Menu, Info } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const AnimeModal = ({ anime, isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Enhanced Backdrop with Blur */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-background/90 backdrop-blur-md z-50"
          />

          {/* Enhanced Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50
              w-[95%] max-w-5xl bg-card-bg/95 backdrop-blur-xl rounded-2xl overflow-hidden 
              border border-white/10 shadow-2xl shadow-[#6366f1]/20"
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-50 p-2 rounded-full bg-background/50 
                hover:bg-white/10 transition-colors duration-300"
            >
              <X className="w-5 h-5 text-white" />
            </button>

            <div className="flex flex-col lg:flex-row">
              {/* Left Side - Enhanced Image Section */}
              <div className="w-full lg:w-[350px] relative">
                <div className="relative aspect-[3/4] lg:aspect-auto lg:h-full">
                  {/* Image with Gradient Overlay */}
                  <div className="absolute inset-0">
                    <img
                      src={anime.poster}
                      alt={anime.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-card-bg via-transparent to-transparent lg:bg-gradient-to-r" />
                  </div>

                  {/* Premium Badge */}
                  {anime.quality && (
                    <div className="absolute top-4 left-4 px-3 py-1.5 rounded-lg 
                      bg-[#6366f1]/20 backdrop-blur-md border border-[#6366f1]/20">
                      <span className="text-sm font-medium text-[#6366f1]">{anime.quality}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Right Side - Enhanced Content Section */}
              <div className="flex-1 p-6 lg:p-8 space-y-6 max-h-[80vh] overflow-y-auto scrollbar-thin">
                {/* Title Section */}
                <div className="space-y-2">
                  <h2 className="text-2xl lg:text-3xl font-night-bold text-white">{anime.name}</h2>
                  {anime.jname && (
                    <p className="text-lg text-[#8b5cf6]/90 font-night">{anime.jname}</p>
                  )}
                </div>

                {/* Statistics Grid */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {[
                    { icon: Star, label: "Rating", value: anime.rating || "N/A", color: "text-[#6366f1]" },
                    { icon: Subtitles, label: "Sub", value: anime.episodes?.sub || "N/A", color: "text-[#8b5cf6]" },
                    { icon: Volume2, label: "Dub", value: anime.episodes?.dub || "N/A", color: "text-[#d946ef]" },
                    { icon: Calendar, label: "Aired", value: anime.aired || "N/A", color: "text-[#6366f1]" },
                  ].map((stat, idx) => (
                    <div key={idx} className="p-4 rounded-xl bg-white/5 backdrop-blur-sm border border-white/5">
                      <stat.icon className={`w-5 h-5 mb-2 ${stat.color}`} />
                      <p className="text-sm text-gray-400">{stat.label}</p>
                      <p className="text-lg font-night-bold text-white">{stat.value}</p>
                    </div>
                  ))}
                </div>

                {/* Info Grid */}
                <div className="space-y-6">
                  {/* Overview Section */}
                  <div className="space-y-2">
                    <h3 className="text-lg font-night-bold text-white flex items-center gap-2">
                      <Menu className="w-5 h-5 text-[#6366f1]" />
                      Overview
                    </h3>
                    <p className="text-gray-300 leading-relaxed">
                      {anime.overview || anime.synopsis || "No overview available."}
                    </p>
                  </div>

                  {/* Additional Info */}
                  <div className="grid grid-cols-2 gap-4">
                    {[
                      { label: "Type", value: anime.type, icon: Tv },
                      { label: "Status", value: anime.status, icon: Info },
                      { label: "Duration", value: anime.duration, icon: Clock },
                      { label: "Studio", value: anime.studio, icon: Users },
                    ].map((info, idx) => (
                      info.value && (
                        <div key={idx} className="flex items-center gap-3">
                          <div className="p-2 rounded-lg bg-white/5">
                            <info.icon className="w-4 h-4 text-[#6366f1]" />
                          </div>
                          <div>
                            <p className="text-sm text-gray-400">{info.label}</p>
                            <p className="text-white">{info.value}</p>
                          </div>
                        </div>
                      )
                    ))}
                  </div>

                  {/* Genres */}
                  {anime.genres && (
                    <div className="space-y-3">
                      <h3 className="text-lg font-night-bold text-white flex items-center gap-2">
                        <Menu className="w-5 h-5 text-[#6366f1]" />
                        Genres
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {anime.genres.map((genre, idx) => (
                          <span
                            key={idx}
                            className="px-4 py-2 rounded-lg bg-[#6366f1]/10 text-sm text-[#6366f1]
                              hover:bg-[#6366f1]/20 transition-colors duration-300 cursor-pointer
                              border border-[#6366f1]/10"
                          >
                            {genre}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="flex gap-4 pt-4">
                  <button
                    onClick={() => navigate(`/anime/${anime.id}`)}
                    className="flex-1 flex items-center justify-center gap-2 px-6 py-4 
                      bg-gradient-to-r from-[#6366f1] to-[#8b5cf6] rounded-xl 
                      hover:shadow-lg hover:shadow-[#6366f1]/20 transition-all duration-300
                      group relative overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-white/20 translate-y-full 
                      group-hover:translate-y-0 transition-transform duration-300" />
                    <Play className="w-5 h-5 relative z-10" fill="currentColor" />
                    <span className="relative z-10 font-medium">Watch Now</span>
                  </button>
                  <button
                    className="px-6 py-4 rounded-xl bg-white/5 hover:bg-white/10 
                      border border-white/10 transition-all duration-300
                      hover:border-[#6366f1]/20"
                  >
                    <Bookmark className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
