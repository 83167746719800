import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, useViewportScroll, useTransform } from 'framer-motion';
import { FaSearch, FaStar, FaTv, FaFilm, FaShieldAlt, FaCode } from 'react-icons/fa';
import { getSearchSuggestions, searchContent } from '../services/search';
import { getFeaturedMovies, getFeaturedTVShows } from '../services/api';
import { TypeAnimation } from 'react-type-animation';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import SearchBar from '../components/SearchBar.jsx';

const BackgroundParticles = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        background: {
          color: {
            value: "transparent",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#b8962e",
          },
          links: {
            color: "#b8962e",
            distance: 150,
            enable: true,
            opacity: 0.3,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.2,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [featuredContent, setFeaturedContent] = useState([]);
  const navigate = useNavigate();
  const { scrollYProgress } = useViewportScroll();
  const searchRef = useRef(null);

  // Parallax effect for background
  const backgroundY = useTransform(scrollYProgress, [0, 1], [0, -300]);

  // Fetch featured content
  useEffect(() => {
    const fetchFeaturedContent = async () => {
      try {
        const movies = await getFeaturedMovies();
        const tvShows = await getFeaturedTVShows();
        const combinedContent = [...movies, ...tvShows]
          .sort(() => 0.5 - Math.random())
          .slice(0, 21)
          .map(item => ({
            ...item,
            media_type: item.title ? 'movie' : 'tv'
          }));
        setFeaturedContent(combinedContent);
      } catch (error) {
        console.error('Error fetching featured content', error);
      }
    };
    fetchFeaturedContent();
  }, []);

  // Search suggestions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchSuggestions = async () => {
        if (searchQuery.length > 2) {
          try {
            const results = await getSearchSuggestions(searchQuery);
            setSuggestions(results.slice(0, 4));
          } catch (error) {
            console.error('Search suggestions error:', error);
          }
        } else {
          setSuggestions([]);
        }
      };
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  // Search handling
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      try {
        const results = await searchContent(searchQuery);
        navigate(`/search?query=${searchQuery}`, { 
          state: { 
            query: searchQuery, 
            results 
          } 
        });
        setSuggestions([]);
      } catch (error) {
        console.error('Search error', error);
      }
    }
  };

  // Suggestion click handler
  const handleSuggestionClick = (id, mediaType) => {
    navigate(`/details/${mediaType}/${id}`);
    setSearchQuery('');
    setSuggestions([]);
  };

   // View More handler
   const handleViewMore = () => {
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-background text-white">
      {/* Background Particles remain the same */}
      <BackgroundParticles />

      {/* Updated Navigation Bar */}
      <nav className="fixed top-0 left-0 w-full z-50">
        <div className="w-full flex justify-center px-2 sm:px-4 lg:px-6">
          <div className={`
            mt-2 sm:mt-3 md:mt-4 w-full 
            max-w-[95%] sm:max-w-[90%] md:max-w-7xl
            bg-background/40 backdrop-blur-md 
            text-gold-300
            p-2 sm:p-3 md:p-4
            flex flex-wrap justify-between 
            items-center
            rounded-xl sm:rounded-2xl
            border border-gold-500/10
            shadow-lg shadow-black/20
            transition-all duration-300
            hover:border-gold-500/20 hover:bg-background/50
          `}>
            {/* Logo Section */}
            <Link 
              to="/" 
              className="
                text-gold-500 font-bold 
                text-xl sm:text-2xl md:text-2xl
                hover:text-gold-400 
                transition-colors duration-300
              "
            >
              Streamify
            </Link>

            {/* Desktop Navigation Links */}
            <div className="hidden md:flex items-center space-x-6">
              {[
                { path: '/home', label: 'Home' },
                { path: '/movies', label: 'Movies' },
                { path: '/tv', label: 'TV Shows' },
                { path: '/anime', label: 'Anime' },
                { path: '/filter', label: 'Filter' },
                {path: '/watchlist', label: 'Watchlist'},
              ].map((item) => (
                <Link 
                  key={item.path}
                  to={item.path} 
                  className="
                    text-gold-400 hover:text-gold-300
                    transition-colors duration-300
                    text-sm lg:text-base
                  "
                >
                  {item.label}
                </Link>
              ))}
            </div>

            {/* Login Button */}
            <Link 
              to="/login" 
              className="
                hidden md:flex items-center
                bg-background/40 border border-gold-500/20
                text-gold-500 rounded-lg sm:rounded-xl
                px-4 sm:px-6 py-1.5 sm:py-2
                text-sm sm:text-base
                hover:bg-gold-500 hover:text-black 
                transition-all duration-300
                shadow-sm
                whitespace-nowrap
              "
            >
              Login →
            </Link>

            {/* Mobile Login Button */}
            <Link 
              to="/login" 
              className="
                md:hidden
                bg-background/40 border border-gold-500/20
                text-gold-500 rounded-lg
                px-3 py-1.5
                text-sm
                hover:bg-gold-500 hover:text-black 
                transition-all duration-300
                shadow-sm
                whitespace-nowrap
              "
            >
              Login
            </Link>
          </div>
        </div>
      </nav>

      {/* Rest of the IndexPage content remains the same */}
      <div className="relative z-10 px-6 py-16 text-center">
        <motion.h1 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-5xl mt-8 md:text-6xl font-bold mb-10 text-gold-500"
        >
          <TypeAnimation
            sequence={[
              'Discover Your Next Favorite Show',
              2000,
              'Stream Unlimited Entertainment',
              2000,
              'Movies, TV Shows, Anime',
              2000
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
          />
        </motion.h1>

        {/* Enhanced Premium Search Form */}
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="relative w-full max-w-3xl mx-auto mt-12"
        >
          <SearchBar
            variant="index"
            onSearch={setSearchQuery}
            searchRef={searchRef}
            placeholder="Search for movies, TV shows, anime..."
            isLoading={false}
            initialValue={searchQuery}
          >
            {/* Keep existing suggestions dropdown */}
            <AnimatePresence>
              {suggestions.length > 0 && (
                <motion.div 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.2 }}
                  className="absolute w-full mt-4 bg-background/95 backdrop-blur-2xl 
                           rounded-2xl shadow-[0_0_30px_rgba(0,0,0,0.5)] 
                           border border-gold-500/30 overflow-hidden z-50
                           divide-y divide-gold-500/10"
                >
                  {/* Existing suggestions mapping remains the same */}
                  {suggestions.map((suggestion, index) => (
                    <motion.div
                      key={suggestion.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ 
                        opacity: 1, 
                        x: 0,
                        transition: { delay: index * 0.1 }
                      }}
                      className="group/item relative"
                      onClick={() => handleSuggestionClick(suggestion.id, suggestion.media_type)}
                    >
                      {/* Hover Background Effect */}
                      <div className="absolute inset-0 bg-gradient-to-r from-gold-500/0 to-gold-500/0 group-hover/item:from-gold-500/5 group-hover/item:to-transparent transition-all duration-300" />
                      
                      <div className="relative flex items-center p-4 transition-all duration-300">
                        {/* Poster with Enhanced Loading State */}
                        <div className="relative w-12 h-16 flex-shrink-0">
                          {suggestion.poster_path ? (
                            <motion.img
                              initial={{ scale: 0.8, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              src={`https://image.tmdb.org/t/p/w92${suggestion.poster_path}`}
                              alt={suggestion.title || suggestion.name}
                              className="w-full h-full rounded-lg object-cover 
                                       border border-gold-500/20 group-hover/item:border-gold-500/40
                                       shadow-[0_0_15px_rgba(0,0,0,0.3)]"
                            />
                          ) : (
                            <div className="w-full h-full rounded-lg bg-zinc-800/50 flex items-center justify-center">
                              <FaFilm className="text-gold-500/50 animate-pulse" />
                            </div>
                          )}
                        </div>
                        
                        {/* Content Details with Better Typography */}
                        <div className="ml-4 flex-1 min-w-0">
                          {/* Title and Type Badge Row */}
                          <div className="flex items-center gap-2 mb-1">
                            <div className="bg-gold-500/90 text-black text-[10px] 
                                          px-1.5 py-0.5 rounded-full font-medium 
                                          group-hover/item:bg-gold-400 transition-colors duration-300">
                              {suggestion.media_type === 'movie' ? 'Movie' : 'TV'}
                            </div>
                            <h3 className="text-gold-400 font-semibold truncate
                                         group-hover/item:text-gold-300 transition-colors 
                                         duration-300 text-base leading-tight">
                              {suggestion.title || suggestion.name}
                            </h3>
                          </div>
                          
                          {/* Metadata Row */}
                          <div className="flex items-center mt-1.5 space-x-3">
                            {/* Year */}
                            <span className="text-xs text-gold-500/70 font-medium">
                              {(suggestion.release_date || suggestion.first_air_date)?.split('-')[0] || 'N/A'}
                            </span>
                            
                            {/* Rating */}
                            <div className="flex items-center bg-gold-500/10 rounded-full px-2 py-0.5">
                              <FaStar className="w-3 h-3 text-gold-500" />
                              <span className="ml-1 text-xs font-medium text-gold-400">
                                {suggestion.vote_average 
                                  ? parseFloat(suggestion.vote_average).toFixed(1) 
                                  : 'N/A'}
                              </span>
                            </div>

                            {/* Additional Info */}
                            {suggestion.original_language && (
                              <span className="text-xs text-gold-500/50 uppercase">
                                {suggestion.original_language}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Arrow Indicator */}
                        <div className="ml-4 text-gold-500/30 group-hover/item:text-gold-500 
                                      transition-colors duration-300">
                          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                  
                  {/* Enhanced "View All Results" Button */}
                  <motion.div className="p-3 bg-gradient-to-b from-black to-zinc-900/50">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleViewMore}
                      className="w-full py-2.5 bg-gold-500 text-black rounded-lg font-medium
                               hover:bg-gold-400 transition-all duration-300
                               shadow-[0_0_15px_rgba(184,150,46,0.3)]
                               flex items-center justify-center space-x-2"
                    >
                      <span>View All Results</span>
                      <FaSearch className="w-4 h-4" />
                    </motion.button>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </SearchBar>
        </motion.div>
      </div>

      {/* Website Description Section */}
<motion.div 
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.6 }}
  className="text-center px-4 md:px-8 lg:px-16 my-10 max-w-5xl mx-auto"
>
  <motion.h2 
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className="text-4xl font-bold text-gold-500 mb-8 tracking-wide"
  >
    Your Ultimate Streaming Companion
  </motion.h2>
  
  <div className="bg-gradient-to-br from-black/60 to-black/80 rounded-2xl p-8 shadow-2xl border border-gold-500/20 overflow-hidden relative">
    {/* Subtle Glow Effect */}
    <div className="absolute inset-0 bg-gradient-to-r from-gold-900/10 to-transparent opacity-30 pointer-events-none"></div>

    <motion.p 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      className="text-gold-300 text-lg mb-8 leading-relaxed text-center max-w-3xl mx-auto"
    >
      Streamify is not just another streaming platform – it's your personalized entertainment hub designed to revolutionize how you discover and enjoy content.
    </motion.p>

    <div className="grid md:grid-cols-3 gap-6">
      {[
        {
          icon: FaSearch,
          title: "Comprehensive Search",
          description: "Advanced search across movies, TV shows, and anime with intelligent suggestions and filters.",
          color: "text-gold-400"
        },
        {
          icon: FaStar,
          title: "Curated Recommendations",
          description: "Personalized content suggestions based on your viewing history and preferences.",
          color: "text-gold-400"
        },
        {
          icon: FaShieldAlt,
          title: "Safe & Ad-Free",
          description: "Enjoy uninterrupted streaming with multiple server options and content filtering.",
          color: "text-gold-400"
        }
      ].map((feature, index) => (
        <motion.div 
          key={feature.title}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ 
            duration: 0.5, 
            delay: 0.4 + index * 0.2 
          }}
          whileHover={{ 
            scale: 1.05,
            rotate: 2,
            transition: { duration: 0.3 }
          }}
          className="bg-zinc-800/50 p-6 rounded-xl 
          border border-gold-500/10 
          hover:border-gold-500/30 
          transition-all duration-300 
          transform hover:shadow-2xl 
          group"
        >
          <feature.icon 
            className={`${feature.color} text-5xl mb-4 mx-auto 
            group-hover:scale-110 
            transition-transform duration-300`} 
          />
          <h3 className="text-xl font-semibold text-gold-400 mb-3 text-center">
            {feature.title}
          </h3>
          <p className="text-gold-200 text-sm text-center">
            {feature.description}
          </p>
        </motion.div>
      ))}
    </div>

    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.8 }}
      className="mt-8 border-t border-gold-500/20 pt-6"
    >
      <p className="text-gold-300 italic text-center text-lg">
        "Discover, Stream, Enjoy – Streamify brings the world of entertainment to your fingertips."
      </p>
    </motion.div>
  </div>
</motion.div>

{/* Start Watching Button */}
<motion.div 
  initial={{ opacity: 0, scale: 0.9 }}
  animate={{ opacity: 1, scale: 1 }}
  transition={{ duration: 0.5, delay: 0.9 }}
  className="mt-6 flex justify-center"
>
  <Link 
    to="/home" 
    className="
      bg-gold-500 
      text-black 
      py-3 px-6 
      rounded-full 
      text-lg 
      font-semibold 
      hover:bg-gold-600 
      transition-colors 
      duration-300 
      shadow-lg 
      hover:shadow-xl 
      transform 
      hover:-translate-y-1
    "
  >
    Start Watching
  </Link>
</motion.div>

      {/* Featured Content Section */}
<div className="mt-10 px-4 md:px-8 lg:px-16">
  <motion.h2 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="text-3xl font-bold text-gold-500 mb-8 text-center"
  >
    Featured
  </motion.h2>
  <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-4">
    {featuredContent.map((item, index) => {
      // Determine media type based on the item's properties
      const mediaType = item.title ? 'movie' : 'tv';

      return (
        <motion.div 
          key={item.id} 
          initial={{ 
            opacity: 0, 
            scale: 0.9,
            y: 20 
          }}
          animate={{ 
            opacity: 1, 
            scale: 1,
            y: 0 
          }}
          transition={{ 
            duration: 0.3, 
            delay: index * 0.05 // Staggered animation
          }}
          className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
          onClick={() => navigate(`/details/${mediaType}/${item.id}`)}
          whileHover={{ 
            scale: 1.05,
            transition: { duration: 0.2 }
          }}
          whileTap={{ scale: 0.95 }}
        >
          <div className="relative pt-[140%] w-full">
            <img 
              src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
              alt={item.title || item.name} 
              className="absolute inset-0 w-full h-full object-cover rounded-lg"
            />
            {/* Media type icon */}
            <div className="absolute top-2 left-2 bg-background/60 rounded-full px-2 py-2 items-center">
              {mediaType === 'movie' ? (
                <FaFilm className="text-gold-400 text-sm" />
              ) : (
                <FaTv className="text-gold-400 text-sm" />
              )}
            </div>
            
            {/* Rating */}
            <div className="absolute top-2 right-2 bg-background/60 rounded-full px-2 py-1 flex items-center">
              <FaStar className="text-gold-500 mr-1 text-xs" />
              <span className="text-xs text-gold-400 font-bold">
                {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
              </span>
            </div>
            
            {/* Title */}
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-2">
              <h3 className="text-xs font-semibold text-gold-400 p-2 bg-background bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
                {item.title || item.name}
              </h3>
            </div>
          </div>
        </motion.div>
      );
    })}
  </div>
</div>
    </div>
  );
};

export default IndexPage;