import React from 'react';
import { motion } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Pagination = ({ currentPage, onPageChange }) => {
  const pageNumbers = Array.from({ length: 5 }, (_, i) => {
    if (currentPage <= 3) {
      return i + 1;
    } else {
      return currentPage + i - 2;
    }
  });

  const renderPageButton = (number) => (
    <motion.button
      key={number}
      onClick={() => onPageChange(number)}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      className={`w-10 h-10 rounded-full flex items-center justify-center
                 transition-all duration-300 font-semibold text-sm
                 ${currentPage === number 
                   ? 'bg-gold-500 text-black shadow-[0_0_15px_rgba(216,180,54,0.5)]' 
                   : 'bg-background/40 text-gold-400 hover:bg-gold-500/20'}`}
    >
      {number}
    </motion.button>
  );

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex items-center gap-2">
        {/* Previous Page Button */}
        <motion.button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className={`w-10 h-10 rounded-full flex items-center justify-center
                     transition-all duration-300
                     ${currentPage === 1 
                       ? 'bg-background/20 text-gray-500 cursor-not-allowed' 
                       : 'bg-background/40 text-gold-400 hover:bg-gold-500/20'}`}
        >
          <FaChevronLeft className="w-4 h-4" />
        </motion.button>

        {/* Page Numbers */}
        <div className="flex gap-2">
          {pageNumbers.map(renderPageButton)}
        </div>

        {/* Next Page Button */}
        <motion.button
          onClick={() => onPageChange(currentPage + 1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="w-10 h-10 rounded-full flex items-center justify-center
                     bg-background/40 text-gold-400 hover:bg-gold-500/20
                     transition-all duration-300"
        >
          <FaChevronRight className="w-4 h-4" />
        </motion.button>
      </div>

      {/* Page Info */}
      <div className="text-gold-400/60 text-sm">
        Page {currentPage}
      </div>
    </div>
  );
};

export default Pagination;