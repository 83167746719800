import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  fetchPopularAnime, 
  advancedAnimeSearch 
} from '../services/Consumet';
import Pagination from '../components/Pagination';
import { Link } from 'react-router-dom';
import { 
  FaPlay, 
  
} from 'react-icons/fa';
import LoaderSpinner from '../components/LoaderSpinner';

const Anime = () => {
  const [anime, setAnime] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [advancedFilters, setAdvancedFilters] = useState({
    genres: [],
    status: '',
    type: '',
    yearFrom: '',
    yearTo: '',
    sortBy: 'popularity',
    rating: ''
  });

  // Memoized fetch function to prevent unnecessary re-renders
  const fetchAnimeData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Fetching Anime with Filters:', {
        page: currentPage,
        ...advancedFilters
      });
      
      // Prepare search parameters
      const searchParams = {
        page: currentPage,
        perPage: 20,
        genres: advancedFilters.genres,
        status: advancedFilters.status,
        type: advancedFilters.type,
        yearFrom: advancedFilters.yearFrom,
        yearTo: advancedFilters.yearTo,
        rating: advancedFilters.rating,
        sortBy: advancedFilters.sortBy
      };

      // Try advanced search first
      let data = await advancedAnimeSearch(searchParams);

      // Fallback to popular anime if advanced search fails
      if (data.length === 0) {
        console.warn('Advanced search returned no results, falling back to popular anime');
        data = await fetchPopularAnime(currentPage);
      }

      console.log('Fetched Anime Results:', data);
      setAnime(data);
    } catch (error) {
      console.error('Comprehensive Anime Fetch Error:', error);
      setError('Failed to fetch anime. Please try again.');
      
      // Fallback mechanism
      try {
        const fallbackData = await fetchPopularAnime(currentPage);
        setAnime(fallbackData);
      } catch (fallbackError) {
        console.error('Fallback fetch failed:', fallbackError);
      }
    } finally {
      setLoading(false);
    }
  }, [currentPage, advancedFilters]);

  // Effect to trigger data fetch
  useEffect(() => {
    fetchAnimeData();
  }, [fetchAnimeData]);

  // Handler for filter changes
  const handleFilterChange = (newFilters) => {
    console.log('Filter Change Detected:', newFilters);
    
    // Reset to first page when filters change
    setCurrentPage(1);
    
    // Update advanced filters
    setAdvancedFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };


  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-background text-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-3xl text-red-500 mb-4">Oops! Something went wrong</h2>
          <p className="text-gold-400">{error}</p>
          <button 
            onClick={() => {
              setError(null);
              setAdvancedFilters({
                genres: [],
                status: '',
                type: '',
                yearFrom: '',
                yearTo: '',
                sortBy: 'popularity',
                rating: ''
              });
            }}
            className="mt-4 bg-gold-500 text-black px-4 py-2 rounded"
          >
            Reset Filters
          </button>
        </div>
      </div>
    );
  }

  // Page Change Handler
  const handlePageChange = (page) => {
    if (page > 0) {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  


  // Animation Variants (remain the same as in previous version)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.9,
      y: 50 
    },
    visible: {
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 15
      }
    }
  };

  // Render Content (remains mostly the same)
  const renderContent = () => {
    if (loading) {
      return <LoaderSpinner type="cosmic" />;
    }

    if (anime.length === 0) {
      return (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="col-span-full text-center text-gold-400"
        >
          No anime found. Try adjusting your filters.
        </motion.div>
      );
    }

    return (
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-2 mt-12 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6"
      >
        <AnimatePresence>
          {anime.map((show) => (
            <motion.div
              key={show.id}
              variants={itemVariants}
              whileHover={{ 
                scale: 1.05,
                transition: { duration: 0.2 }
              }}
              className="group relative"
            >
              <Link to={`/watch/anime/${show.id}`} className="group">
                <motion.div 
                  className="relative overflow-hidden rounded-2xl shadow-lg 
                             transition-all duration-400 
                             border border-transparent 
                             bg-gradient-to-b from-zinc-900 to-black 
                             hover:border-gold-500/30 
                             hover:shadow-2xl"
                  whileHover={{
                    scale: 1.05,
                    transition: { 
                      duration: 0.3,
                      type: "spring",
                      stiffness: 300
                    }
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  {/* Poster Image Container */}
                  <div className="relative pt-[150%] overflow-hidden rounded-2xl">
                    {/* Background Blur Effect */}
                    <div 
                      className="absolute inset-0 bg-cover bg-center blur-sm opacity-30"
                      style={{ 
                        backgroundImage: `url(${show.image})`,
                        transform: 'scale(1.1)'
                      }}
                    />

                    {/* Main Poster Image */}
                    <motion.img 
                      src={show.image || 'https://via.placeholder.com/300x450'}
                      alt={show.title?.english || show.title?.romaji || 'Anime Title'}
                      className="absolute inset-0 w-full h-full object-cover rounded-2xl"
                      initial={{ opacity: 0.8, scale: 1 }}
                      whileHover={{ 
                        opacity: 1, 
                        scale: 1.1,
                        transition: { 
                          duration: 0.3,
                          type: "spring",
                          stiffness: 200
                        }
                      }}
                    />

                    {/* Hover Overlay */}
                    <motion.div 
                      className="absolute inset-0 bg-background/0 group-hover:bg-background/40 
                                  transition-all duration-300 flex items-center justify-center"
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                    >
                      <motion.div 
                        className="opacity-0 group-hover:opacity-100 
                                    transition-opacity duration-300"
                        whileHover={{ 
                          scale: 1.2,
                          rotate: 360,
                          transition: { duration: 0.5 }
                        }}
                      >
                        <FaPlay className="text-gold-500 text-5xl 
                                            drop-shadow-[0_0_10px_rgba(216,180,54,0.7)]
                                            transform hover:scale-110 
                                            transition-transform" />
                      </motion.div>
                    </motion.div>

                    {/* Status Overlay */}
                    <motion.div 
                      className="absolute top-3 right-3 
                                  bg-background/70 rounded-full 
                                  px-3 py-1 flex items-center"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <span className="text-sm text-gold-400 font-bold">
                        {show.status || 'Unknown'}
                      </span>
                    </motion.div>
                  </div>

                  {/* Title Overlay */}
                  <motion.div 
                    className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to-transparent 
                                p-3 rounded-b-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <h3 className="text-sm font-semibold text-gold-400 
                                   truncate group-hover:text-gold-300 
                                   transition-colors duration-300 
                                   px-2 py-1 bg-background/50 rounded-full 
                                   group-hover:bg-gold-500/20">
                      {show.title?.english || show.title?.romaji || 'Anime Title'}
                    </h3>
                  </motion.div>
                </motion.div>
              </Link>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    );
  };

  

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-b from-gray-900 to-black min-h-screen pt-24 px-4 sm:px-6 pb-6"
    >
      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="fixed inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>
      {/* Header Section with Filters */}
      <motion.div 
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-50 mb-6 flex flex-col md:flex-row justify-between items-center gap-4"
      >
        <h1 className="text-2xl sm:text-3xl font-bold text-gold-500">
          Anime Collection
        </h1>
        
        <div className="w-full md:w-auto">
          {/* Add your AnimeFilter component here */}
        </div>
      </motion.div>

      {/* Content Area */}
      <div className="relative z-40">
        {renderContent()}
      </div>

      {/* Pagination */}
      <motion.div 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="mt-8 relative z-40"
      >
        <Pagination 
          currentPage={currentPage} 
          onPageChange={handlePageChange} 
        />
      </motion.div>
    </motion.div>
  );
};

export default Anime;