import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';
import { 
  Play, Pause, Volume2, VolumeX, Maximize, Minimize, 
  Settings, Subtitles, RotateCcw, FastForward, 
  Loader2, ChevronRight, Forward, Rewind, AlertTriangle
} from 'lucide-react';

// Format time (seconds) to display format
const formatTime = (seconds) => {
  if (isNaN(seconds) || seconds === Infinity) return '00:00';
  
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  
  return `${mm}:${ss}`;
};

// Custom HLS player component with improved error handling
const HLSPlayer = React.forwardRef(({ url, playing, volume, muted, playbackRate, onProgress, onDuration, onBuffer, onBufferEnd, onReady, tracks, selectedTrack, onError }, ref) => {
  const videoRef = useRef(null);
  const hls = useRef(null);
  const [retries, setRetries] = useState(0);
  const MAX_RETRIES = 3;
  
  // Initialize HLS
  useEffect(() => {
    let _hls;
    
    function initHls() {
      if (hls.current) {
        hls.current.destroy();
      }
      
      if (!url) return;

      if (Hls.isSupported() && videoRef.current) {
        _hls = new Hls({ 
          enableWorker: true,
          fragLoadingTimeOut: 30000, // Increase timeout to 30 seconds
          manifestLoadingTimeOut: 30000,
          levelLoadingTimeOut: 30000,
          fragLoadingMaxRetry: 5, // Increase retry attempts
          manifestLoadingMaxRetry: 5,
          levelLoadingMaxRetry: 5,
          fragLoadingRetryDelay: 1000, // Start retry after 1 second
          manifestLoadingRetryDelay: 1000,
          levelLoadingRetryDelay: 1000
        });
        
        _hls.loadSource(url);
        _hls.attachMedia(videoRef.current);
        
        // Event listeners
        _hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (onReady) onReady();
        });
        
        _hls.on(Hls.Events.BUFFER_CREATED, () => {
          if (onBuffer) onBuffer();
        });
        
        _hls.on(Hls.Events.BUFFER_APPENDED, () => {
          if (onBufferEnd) onBufferEnd();
        });
        
        // Enhanced error handling
        _hls.on(Hls.Events.ERROR, (event, data) => {
          console.error('HLS error:', data);
          
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                if (retries < MAX_RETRIES) {
                  console.log(`Network error, retrying (${retries + 1}/${MAX_RETRIES})...`);
                  setRetries(prev => prev + 1);
                  _hls.startLoad();
                } else {
                  console.error('Max retries reached, reporting error');
                  if (onError) onError({ message: 'Network error: Unable to load the video. Please check your connection.' });
                  _hls.destroy();
                }
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log('Media error, trying to recover...');
                _hls.recoverMediaError();
                break;
              default:
                if (onError) onError({ message: 'Playback error: The video cannot be played.' });
                _hls.destroy();
                break;
            }
          } else if (data.details === 'fragLoadTimeOut' || data.details === 'manifestLoadTimeOut') {
            // Non-fatal timeout errors - just report
            console.warn(`HLS timeout error: ${data.details}`);
          }
        });
        
        hls.current = _hls;
      } else if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        // For Safari, which has native HLS support
        videoRef.current.src = url;
      } else {
        // No HLS support at all
        if (onError) onError({ message: 'Your browser does not support HLS playback.' });
      }
    }
    
    if (url) {
      setRetries(0); // Reset retries on new URL
      initHls();
    }
    
    return () => {
      if (hls.current) {
        hls.current.destroy();
      }
    };
  }, [url, onReady, onBuffer, onBufferEnd, onError, retries]);
  
  // Handle playback state changes
  useEffect(() => {
    if (videoRef.current) {
      if (playing) {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error("Play error:", error);
            // Don't report "interrupted" errors as they're usually just new load requests
            if (error.name !== 'AbortError') {
              if (onError) onError({ message: 'Playback error: Unable to start playback.' });
            }
          });
        }
      } else {
        videoRef.current.pause();
      }
    }
  }, [playing, onError]);
  
  // Handle volume & muted changes
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
      videoRef.current.muted = muted;
    }
  }, [volume, muted]);
  
  // Handle playback rate
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);
  
  // Handle progress tracking
  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current && onProgress) {
        const duration = videoRef.current.duration || 0;
        const currentTime = videoRef.current.currentTime || 0;
        
        if (duration > 0) {
          onProgress({
            played: currentTime / duration,
            playedSeconds: currentTime,
            loaded: videoRef.current.buffered.length > 0 ? 
              videoRef.current.buffered.end(videoRef.current.buffered.length - 1) / duration : 0,
            loadedSeconds: videoRef.current.buffered.length > 0 ? 
              videoRef.current.buffered.end(videoRef.current.buffered.length - 1) : 0
          });
        }
      }
    }, 1000);
    
    return () => clearInterval(interval);
  }, [onProgress]);
  
  // Handle duration
  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current && onDuration) {
        onDuration(videoRef.current.duration);
      }
    };
    
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [onDuration]);
  
  // Expose player API via ref
  useEffect(() => {
    if (ref) {
      ref.current = {
        play: () => {
          if (videoRef.current) videoRef.current.play();
        },
        pause: () => {
          if (videoRef.current) videoRef.current.pause();
        },
        seekTo: (amount, type) => {
          if (videoRef.current) {
            if (type === 'fraction') {
              videoRef.current.currentTime = amount * videoRef.current.duration;
            } else {
              videoRef.current.currentTime = amount;
            }
          }
        },
        getCurrentTime: () => {
          return videoRef.current ? videoRef.current.currentTime : 0;
        },
        getDuration: () => {
          return videoRef.current ? videoRef.current.duration : 0;
        }
      };
    }
  }, [ref]);
  
  // Add subtitles
  useEffect(() => {
    if (videoRef.current && tracks && tracks.length > 0) {
      // Remove any existing tracks
      while (videoRef.current.firstChild) {
        videoRef.current.removeChild(videoRef.current.firstChild);
      }
      
      // Add new tracks
      tracks.forEach((track, index) => {
        const trackElement = document.createElement('track');
        trackElement.kind = 'subtitles';
        trackElement.label = track.label;
        trackElement.src = track.file;
        trackElement.srclang = track.srclang || 'en';
        trackElement.default = index === selectedTrack;
        videoRef.current.appendChild(trackElement);
      });
    }
  }, [tracks, selectedTrack]);
  
  return (
    <video
      ref={videoRef}
      className="w-full h-full"
      crossOrigin="anonymous"
      playsInline
      preload="auto"
      poster="" // Don't set poster here to avoid flashing
    />
  );
});

// Main Premium Player component
export const PremiumPlayer = ({
  url,
  poster = '',
  title = '',
  autoplay = false,
  quality = '1080p',
  subtitles = [],
  introMarker = null,
  outroMarker = null
}) => {
  // Main player state
  const [playing, setPlaying] = useState(autoplay);
  const [volume, setVolume] = useState(0.7);
  const [muted, setMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [selectedSubtitle, setSelectedSubtitle] = useState(-1);
  const [showSettings, setShowSettings] = useState(false);
  const [error, setError] = useState(null);
  const [currentSource, setCurrentSource] = useState(url);
  const [fallbackIndex, setFallbackIndex] = useState(0);
  
  // Refs
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsTimeout = useRef(null);
  
  // Check if this is an HLS stream
  const isHLS = currentSource && (currentSource.includes('.m3u8') || currentSource.endsWith('.m3u8'));
  
  // Hide controls after inactivity
  useEffect(() => {
    if (!playing) return;
    
    const handleMouseMove = () => {
      setShowControls(true);
      clearTimeout(controlsTimeout.current);
      
      controlsTimeout.current = setTimeout(() => {
        if (playing) setShowControls(false);
      }, 3000);
    };
    
    const container = playerContainerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('click', () => setShowControls(true));
    }
    
    return () => {
      clearTimeout(controlsTimeout.current);
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('click', () => setShowControls(true));
      }
    };
  }, [playing]);
  
  // Handle fullscreen changes from browser
  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(
        document.fullscreenElement || 
        document.webkitFullscreenElement || 
        document.mozFullScreenElement || 
        document.msFullscreenElement
      );
    };
    
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);
    
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);
  
  // Handle fullscreen
  const handleFullscreen = () => {
    if (!fullscreen) {
      const container = playerContainerRef.current;
      if (!container) return;
      
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  
  // Handle seeking
  const handleSeekMouseDown = () => {
    setSeeking(true);
  };
  
  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value));
  };
  
  const handleSeekMouseUp = e => {
    setSeeking(false);
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value), 'fraction');
    }
  };
  
  // Handle playback rate
  const handlePlaybackRate = (rate) => {
    setPlaybackRate(rate);
    setShowSettings(false);
  };
  
  // Toggle play/pause
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
  
  // Skip forward/backward
  const handleSkip = (seconds) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      const newTime = currentTime + seconds;
      playerRef.current.seekTo(newTime, 'seconds');
    }
  };
  
  // Handle progress
  const handleProgress = state => {
    if (!seeking) {
      setPlayed(state.played);
      setPlayedSeconds(state.playedSeconds);
    }
  };
  
  // Skip intro function
  const skipIntro = () => {
    if (introMarker && playerRef.current) {
      playerRef.current.seekTo(introMarker.end, 'seconds');
    }
  };
  
  // Detect if intro or outro is currently playing
  const isIntroPlaying = introMarker && 
    playedSeconds >= introMarker.start && 
    playedSeconds <= introMarker.end;
    
  const isOutroPlaying = outroMarker && 
    playedSeconds >= outroMarker.start;
  
  // Handle subtitles
  const handleSubtitleToggle = () => {
    if (subtitles.length === 0) return;
    
    setSelectedSubtitle(prev => {
      const next = (prev + 1) % (subtitles.length + 1);
      return next === subtitles.length ? -1 : next; // -1 means off
    });
  };
  
  // Function to try fallback URL
  const tryFallbackUrl = () => {
    if (typeof url === 'string') {
      // Single URL, no fallbacks
      return false;
    }
    
    if (Array.isArray(url) && fallbackIndex < url.length - 1) {
      const nextIndex = fallbackIndex + 1;
      console.log(`Trying fallback URL ${nextIndex}/${url.length - 1}`);
      setFallbackIndex(nextIndex);
      setCurrentSource(url[nextIndex]);
      setError(null);
      setLoading(true);
      return true;
    }
    
    return false;
  };
  
  // Handle error with fallback logic
  const handlePlayerError = (err) => {
    console.error("Player error:", err);
    
    if (!tryFallbackUrl()) {
      setError("Error playing video. Please try a different source.");
      setLoading(false);
    }
  };
  
  // Update current source when URL prop changes
  useEffect(() => {
    if (url !== currentSource) {
      setCurrentSource(Array.isArray(url) ? url[0] : url);
      setFallbackIndex(0);
      setError(null);
    }
  }, [url]);

  // Progress tracking
  useEffect(() => {
    // Set up progress tracking interval to handle both ReactPlayer and HLSPlayer
    const interval = setInterval(() => {
      if (playerRef.current && !seeking) {
        const currentTime = playerRef.current.getCurrentTime ? playerRef.current.getCurrentTime() : 0;
        const totalDuration = playerRef.current.getDuration ? playerRef.current.getDuration() : 0;
        
        if (totalDuration > 0) {
          setPlayed(currentTime / totalDuration);
          setPlayedSeconds(currentTime);
        }
      }
    }, 500);
    
    return () => clearInterval(interval);
  }, [seeking]);
  
  // Create the final player element
  const renderPlayer = () => {
    if (!currentSource) {
      return (
        <div className="w-full h-full bg-background flex items-center justify-center">
          <div className="text-center text-white">
            <AlertTriangle className="w-12 h-12 mx-auto mb-3 text-yellow-500" />
            <p>No video source available</p>
          </div>
        </div>
      );
    }
    
    if (isHLS) {
      return (
        <HLSPlayer
          ref={playerRef}
          url={currentSource}
          playing={playing}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          onDuration={setDuration}
          onBuffer={() => setLoading(true)}
          onBufferEnd={() => setLoading(false)}
          onReady={() => setLoading(false)}
          onError={handlePlayerError}
          tracks={subtitles}
          selectedTrack={selectedSubtitle}
        />
      );
    }
    
    return (
      <ReactPlayer
        ref={playerRef}
        url={currentSource}
        width="100%"
        height="100%"
        playing={playing}
        volume={volume}
        muted={muted}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        onDuration={setDuration}
        onBuffer={() => setLoading(true)}
        onBufferEnd={() => setLoading(false)}
        onReady={() => setLoading(false)}
        onError={handlePlayerError}
        config={{
          file: {
            forceVideo: true,
            attributes: {
              crossOrigin: "anonymous",
              controlsList: "nodownload",
              poster: poster || undefined
            },
            tracks: subtitles.map((subtitle, index) => ({
              kind: 'subtitles',
              src: subtitle.file,
              srcLang: subtitle.srclang || 'en',
              label: subtitle.label,
              default: index === selectedSubtitle
            }))
          }
        }}
      />
    );
  };

  return (
    <div 
      className="relative bg-background rounded-xl overflow-hidden group aspect-video"
      ref={playerContainerRef}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => playing && setShowControls(false)}
    >
      {/* Video Player */}
      <div className="w-full h-full">
        {renderPlayer()}
      </div>
      
      {/* Loading Overlay */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-background/50 z-10">
          <div className="flex flex-col items-center">
            <Loader2 className="w-10 h-10 text-[#6366f1] animate-spin" />
            <p className="mt-2 text-white/80 text-sm">Loading video...</p>
            {fallbackIndex > 0 && (
              <p className="mt-1 text-white/50 text-xs">Using alternative source ({fallbackIndex + 1})</p>
            )}
          </div>
        </div>
      )}
      
      {/* Error Overlay with Retry Button */}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-background/80 z-20">
          <div className="flex flex-col items-center p-6 bg-card-bg/90 rounded-lg max-w-md text-center">
            <AlertTriangle className="w-10 h-10 text-yellow-500 mb-3" />
            <p className="text-red-400 mb-4">{error}</p>
            <div className="flex gap-3">
              <button 
                onClick={() => {
                  setCurrentSource(Array.isArray(url) ? url[0] : url);
                  setFallbackIndex(0);
                  setError(null);
                  setLoading(true);
                }}
                className="px-4 py-2 bg-[#6366f1] hover:bg-[#5355cc] rounded-lg text-white"
              >
                Retry Playback
              </button>
              {Array.isArray(url) && url.length > 1 && (
                <button 
                  onClick={() => tryFallbackUrl()}
                  className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg text-white"
                >
                  Try Alternative Source
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Custom Controls */}
      <div 
        className={`absolute inset-0 flex flex-col justify-between transition-opacity duration-300 
          bg-gradient-to-t from-black/80 via-transparent to-black/30
          ${showControls ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        {/* Top Bar */}
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center">
            {title && (
              <h3 className="text-white font-medium text-sm md:text-base">{title}</h3>
            )}
            <div className="ml-2 bg-[#6366f1]/80 px-2 py-1 rounded text-xs font-medium text-white">
              {quality}
            </div>
            {fallbackIndex > 0 && (
              <div className="ml-2 bg-yellow-500/50 px-2 py-1 rounded text-xs font-medium text-white">
                Source {fallbackIndex + 1}
              </div>
            )}
          </div>
          
          {/* Skip intro button */}
          {introMarker && playedSeconds >= introMarker.start && playedSeconds <= introMarker.end && (
            <button 
              onClick={() => playerRef.current?.seekTo(introMarker.end, 'seconds')}
              className="px-3 py-1.5 bg-[#6366f1]/80 rounded-lg flex items-center gap-1 hover:bg-[#6366f1] transition-colors"
            >
              <span className="text-sm font-medium">Skip Intro</span>
              <ChevronRight className="w-4 h-4" />
            </button>
          )}
          
          {/* Outro indicator */}
          {outroMarker && playedSeconds >= outroMarker.start && (
            <div className="px-3 py-1.5 bg-[#d946ef]/30 border border-[#d946ef]/20 rounded-lg">
              <span className="text-sm font-medium text-white/90">Outro</span>
            </div>
          )}
        </div>
        
        {/* Center Play/Pause Button */}
        <div className="absolute inset-0 flex items-center justify-center">
          {!playing && !loading && (
            <button 
              onClick={() => setPlaying(true)}
              className="w-16 h-16 md:w-20 md:h-20 flex items-center justify-center rounded-full bg-[#6366f1]/30 border border-[#6366f1]/50
                hover:bg-[#6366f1]/50 transition-all duration-300"
            >
              <Play className="w-8 h-8 md:w-10 md:h-10 text-white" fill="currentColor" />
            </button>
          )}
        </div>
        
        {/* Bottom Controls */}
        <div className="p-4 space-y-2">
          {/* Progress Bar */}
          <div className="flex items-center gap-2">
            <span className="text-white text-xs md:text-sm">{formatTime(playedSeconds)}</span>
            <div className="flex-1 relative group">
              <input
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
                className="absolute z-10 inset-0 w-full h-full opacity-0 cursor-pointer"
              />
              <div className="h-1.5 bg-white/30 rounded-full overflow-hidden relative">
                {/* Played Progress */}
                <div 
                  className="absolute left-0 top-0 bottom-0 bg-[#6366f1] rounded-full"
                  style={{ width: `${played * 100}%` }}
                />
                
                {/* Hover Effect */}
                <div className="absolute right-0 top-0 bottom-0 left-0 scale-x-0 group-hover:scale-x-100 bg-white/10 transition-transform duration-300 origin-left" />
                
                {/* Intro Marker */}
                {introMarker && duration > 0 && (
                  <div className="absolute top-0 bottom-0 bg-yellow-500/70" 
                    style={{ 
                      left: `${(introMarker.start / duration) * 100}%`, 
                      width: `${((introMarker.end - introMarker.start) / duration) * 100}%`
                    }}
                  />
                )}
                
                {/* Outro Marker */}
                {outroMarker && duration > 0 && (
                  <div className="absolute top-0 bottom-0 bg-[#d946ef]/70" 
                    style={{ 
                      left: `${(outroMarker.start / duration) * 100}%`, 
                      width: `${((duration - outroMarker.start) / duration) * 100}%`
                    }}
                  />
                )}
              </div>
            </div>
            <span className="text-white text-xs md:text-sm">{formatTime(duration)}</span>
          </div>
          
          {/* Controls Row */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 md:gap-3">
              {/* Play/Pause */}
              <button 
                onClick={() => setPlaying(!playing)}
                className="p-2 hover:bg-white/10 rounded-full transition-colors"
                aria-label={playing ? "Pause" : "Play"}
              >
                {playing ? (
                  <Pause className="w-4 h-4 md:w-5 md:h-5 text-white" />
                ) : (
                  <Play className="w-4 h-4 md:w-5 md:h-5 text-white" fill="white" />
                )}
              </button>
              
              {/* Volume Control */}
              <div className="group relative flex items-center">
                <button 
                  onClick={() => setMuted(!muted)}
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  aria-label={muted ? "Unmute" : "Mute"}
                >
                  {muted || volume === 0 ? (
                    <VolumeX className="w-4 h-4 md:w-5 md:h-5 text-white" />
                  ) : (
                    <Volume2 className="w-4 h-4 md:w-5 md:h-5 text-white" />
                  )}
                </button>
                
                <div className="hidden sm:block opacity-0 w-0 overflow-hidden group-hover:w-20 group-hover:opacity-100 transition-all duration-300">
                  <input
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={muted ? 0 : volume}
                    onChange={e => {
                      setVolume(parseFloat(e.target.value));
                      setMuted(false);
                    }}
                    className="w-20 h-1.5 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            
            {/* Right Controls */}
            <div className="flex items-center gap-1 md:gap-3">
              {/* Settings */}
              <div className="relative">
                <button
                  onClick={() => setShowSettings(!showSettings)}
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  aria-label="Settings"
                >
                  <Settings className="w-4 h-4 md:w-5 md:h-5 text-white" />
                </button>
                
                {showSettings && (
                  <div className="absolute bottom-full right-0 mb-2 py-2 w-36 md:w-48 
                    bg-background/90 border border-white/10 rounded-lg shadow-xl z-20">
                    {/* Playback Rate settings */}
                    <p className="px-3 py-1 text-xs text-gray-400">Playback Speed</p>
                    {[0.5, 0.75, 1, 1.25, 1.5, 2].map(rate => (
                      <button
                        key={rate}
                        onClick={() => {
                          setPlaybackRate(rate);
                          setShowSettings(false);
                        }}
                        className={`w-full px-3 py-2 text-left text-xs md:text-sm hover:bg-[#6366f1]/20 transition-colors
                          ${playbackRate === rate ? 'text-[#6366f1] bg-[#6366f1]/10' : 'text-white'}`}
                      >
                        {rate === 1 ? 'Normal' : `${rate}x`}
                      </button>
                    ))}
                    
                    {/* Source selection if multiple sources */}
                    {Array.isArray(url) && url.length > 1 && (
                      <>
                        <div className="border-t border-white/10 my-1"></div>
                        <p className="px-3 py-1 text-xs text-gray-400">Source</p>
                        {url.map((_, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setFallbackIndex(index);
                              setCurrentSource(url[index]);
                              setShowSettings(false);
                              setError(null);
                              setLoading(true);
                            }}
                            className={`w-full px-3 py-2 text-left text-xs md:text-sm hover:bg-[#6366f1]/20 transition-colors
                              ${fallbackIndex === index ? 'text-[#6366f1] bg-[#6366f1]/10' : 'text-white'}`}
                          >
                            Source {index + 1}
                          </button>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
              
              {/* Fullscreen */}
              <button
                onClick={handleFullscreen}
                className="p-2 hover:bg-white/10 rounded-full transition-colors"
                aria-label={fullscreen ? "Exit fullscreen" : "Fullscreen"}
              >
                {fullscreen ? (
                  <Minimize className="w-4 h-4 md:w-5 md:h-5 text-white" />
                ) : (
                  <Maximize className="w-4 h-4 md:w-5 md:h-5 text-white" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
