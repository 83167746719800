import React, { useState } from 'react';
import { ChevronDown, ChevronRight, Copy, Check } from 'lucide-react';

export const JsonView = ({ data }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="bg-[#0a0a1f]/50 rounded-lg border border-white/10 overflow-hidden">
      <div className="flex items-center justify-between p-3 bg-[#0a0a1f]/80 border-b border-white/5">
        <h4 className="text-sm font-medium text-white">Response Data</h4>
        <button
          onClick={copyToClipboard}
          className="p-1.5 rounded-md hover:bg-white/10 text-gray-400 hover:text-[#6366f1] transition-colors"
        >
          {copied ? (
            <Check className="w-4 h-4 text-green-500" />
          ) : (
            <Copy className="w-4 h-4" />
          )}
        </button>
      </div>
      <div className="p-3 overflow-x-auto">
        <pre className="text-sm text-gray-300">
          <JsonTree data={data} />
        </pre>
      </div>
    </div>
  );
};

const JsonTree = ({ data, level = 0 }) => {
  const [collapsed, setCollapsed] = useState(level > 1);

  if (data === null) return <span className="text-red-400">null</span>;
  if (data === undefined) return <span className="text-red-400">undefined</span>;

  if (typeof data === 'boolean')
    return <span className="text-yellow-400">{data.toString()}</span>;
  if (typeof data === 'number')
    return <span className="text-blue-400">{data}</span>;
  if (typeof data === 'string')
    return <span className="text-green-400">"{data}"</span>;

  if (Array.isArray(data)) {
    if (data.length === 0) return <span className="text-gray-500">[]</span>;

    return (
      <div>
        <span 
          className="cursor-pointer flex items-center" 
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <ChevronRight className="w-3 h-3 inline mr-1 text-[#6366f1]" />
          ) : (
            <ChevronDown className="w-3 h-3 inline mr-1 text-[#6366f1]" />
          )}
          <span className="text-gray-500">
            Array({data.length})
          </span>
          {collapsed && <span className="text-gray-500 ml-1">[...]</span>}
        </span>

        {!collapsed && (
          <div style={{ marginLeft: '1rem' }}>
            [
            {data.map((item, idx) => (
              <div key={idx}>
                <JsonTree data={item} level={level + 1} />{idx < data.length - 1 ? ',' : ''}
              </div>
            ))}
            ]
          </div>
        )}
      </div>
    );
  }

  if (typeof data === 'object') {
    const keys = Object.keys(data);
    if (keys.length === 0) return <span className="text-gray-500">{'{}'}</span>;

    return (
      <div>
        <span 
          className="cursor-pointer flex items-center" 
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <ChevronRight className="w-3 h-3 inline mr-1 text-[#6366f1]" />
          ) : (
            <ChevronDown className="w-3 h-3 inline mr-1 text-[#6366f1]" />
          )}
          <span className="text-gray-500">
            Object({keys.length})
          </span>
          {collapsed && <span className="text-gray-500 ml-1">{'{ ... }'}</span>}
        </span>

        {!collapsed && (
          <div style={{ marginLeft: '1rem' }}>
            {'{'}
            {keys.map((key, idx) => (
              <div key={key}>
                <span className="text-[#d946ef]">"{key}"</span>: <JsonTree data={data[key]} level={level + 1} />{idx < keys.length - 1 ? ',' : ''}
              </div>
            ))}
            {'}'}
          </div>
        )}
      </div>
    );
  }

  return <span>{String(data)}</span>;
};