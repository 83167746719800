import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getMediaDetails, getMovieServers, getTVShowServers, fetchTVShowSeasons, fetchEpisodesBySeason } from '../services/api';
import ServerSelector from '../components/ServerSelector';
import EpisodeSelector from '../components/EpisodeSelector';
import { Circle, Maximize, Minimize } from 'lucide-react';
import { useToast } from '../contexts/ToastContext';
import MobileWatchPage from './MobileWatchPage';

const WatchPage = () => {
  // Keep essential state
  const { id, mediaType } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mediaDetails, setMediaDetails] = useState(null);
  const [videoServers, setVideoServers] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(1);
  const [selectedEpisode, setSelectedEpisode] = useState(1);
  const [error, setError] = useState('');
  const [watchProgress, setWatchProgress] = useState({});
  const [currentUrl, setCurrentUrl] = useState('');
  const playerRef = useRef(null);
  const [isTheaterMode, setIsTheaterMode] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const lastPlayedRef = useRef(null);
  const { showToast } = useToast();
  const [serverErrorCount, setServerErrorCount] = useState(0);
  const currentServerIndex = useRef(0);
  const [isMobile, setIsMobile] = useState(false);

  // Keep watched episodes tracking
  const [watchedEpisodes, setWatchedEpisodes] = useState(() => {
    const stored = localStorage.getItem(`watchedEpisodes_${id}`);
    return stored ? JSON.parse(stored) : {};
  });

  // Load essential data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const details = await getMediaDetails(id, mediaType);
        setMediaDetails(details);

        if (mediaType === 'movie') {
          const servers = await getMovieServers(id);
          setVideoServers(servers);
          setCurrentUrl(servers[0]?.url || '');
        } else if (mediaType === 'tv') {
          const seasonsData = await fetchTVShowSeasons(id);
          setSeasons(seasonsData);
          const servers = await getTVShowServers(id, selectedSeason, selectedEpisode);
          setVideoServers(servers);
          setCurrentUrl(servers[0]?.url || '');
        }
        setLoading(false);
      } catch (err) {
        setError('Error loading content');
        setLoading(false);
      }
    };

    fetchData();
  }, [id, mediaType, selectedSeason, selectedEpisode]);

  // Fetch episodes for selected season
  useEffect(() => {
    if (mediaType === 'tv') {
      fetchEpisodesBySeason(id, selectedSeason).then(setEpisodes);
    }
  }, [id, selectedSeason, mediaType]);

  // Handle episode selection
  const handleEpisodeSelect = useCallback((episode) => {
    const seasonKey = `season${episode.season_number || selectedSeason}`;
    const updatedWatched = { ...watchedEpisodes };
    
    if (!updatedWatched[seasonKey]) {
      updatedWatched[seasonKey] = [];
    }

    // Always mark the episode as watched when selected
    if (!updatedWatched[seasonKey].includes(episode.episode_number)) {
      updatedWatched[seasonKey].push(episode.episode_number);
      // Store with the specific media ID
      localStorage.setItem(`watchedEpisodes_${id}`, JSON.stringify(updatedWatched));
      setWatchedEpisodes(updatedWatched);
    }

    setSelectedSeason(episode.season_number || selectedSeason);
    setSelectedEpisode(episode.episode_number);
    
    showToast(`Playing Episode ${episode.episode_number}`, 'info');
    
    const newServer = videoServers[currentServerIndex.current]?.url.replace(
      /\/\d+\/\d+$/,
      `/${episode.season_number || selectedSeason}/${episode.episode_number}`
    );
    setCurrentUrl(newServer);
    
    if (playerRef.current) {
      playerRef.current.src = newServer;
    }
  }, [id, selectedSeason, watchedEpisodes, videoServers]);

  const handleServerChange = useCallback((url) => {
    setCurrentUrl(url);
    if (playerRef.current) {
      playerRef.current.src = url;
      showToast('Server changed successfully', 'success');
    }
  }, []);

  const handleIframeError = useCallback(() => {
    showToast('Server error, trying next server...', 'error');
    setServerErrorCount(prev => prev + 1);
    
    // Try next server automatically
    const nextIndex = (currentServerIndex.current + 1) % videoServers.length;
    currentServerIndex.current = nextIndex;
    const nextServer = videoServers[nextIndex]?.url;
    
    if (nextServer) {
      handleServerChange(nextServer);
    } else {
      showToast('All servers failed. Please try again later.', 'error');
    }
  }, [videoServers]);

  const handleIframeLoad = useCallback(() => {
    showToast('Content loaded successfully', 'success');
    setServerErrorCount(0);
  }, []);

  // Add keyboard shortcut for theater mode
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key.toLowerCase() === 'f') {
        setIsTheaterMode(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Enhanced mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024); // Changed to 1024px breakpoint
      document.body.style.overflow = window.innerWidth < 1024 ? 'hidden' : 'auto';
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Add floating theater mode button component
  const TheaterModeButton = () => (
    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.1 }}
      onClick={() => setIsTheaterMode(!isTheaterMode)}
      className={`
        fixed z-50 bottom-6 right-6 p-3 rounded-full
        bg-background/80 backdrop-blur-sm border border-white/10
        text-white/70 hover:text-gold-500 transition-colors
        shadow-lg hover:shadow-gold-500/20
      `}
    >
      {isTheaterMode ? (
        <Minimize className="w-5 h-5" />
      ) : (
        <Maximize className="w-5 h-5" />
      )}
    </motion.button>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-black via-black/95 to-black">
        <motion.div
          animate={{ 
            rotate: 360,
            scale: [1, 1.2, 1]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <Circle className="w-12 h-12 text-gold-500" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-black via-black/95 to-black">
        <div className="text-red-500 bg-red-500/10 px-6 py-4 rounded-lg border border-red-500/20">
          {error}
        </div>
      </div>
    );
  }

  // Conditional render based on screen size
  if (isMobile) {
    return (
      <div className="fixed inset-0 z-50 bg-background">
        <MobileWatchPage
          mediaDetails={mediaDetails}
          currentUrl={currentUrl}
          videoServers={videoServers}
          mediaType={mediaType}
          selectedSeason={selectedSeason}
          selectedEpisode={selectedEpisode}
          onServerChange={handleServerChange}
          onEpisodeSelect={handleEpisodeSelect}
          episodes={episodes}
          seasons={seasons}
          watchedEpisodes={watchedEpisodes}
          onClose={() => navigate(-1)}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background overflow-hidden">
      {/* Backdrop with animated gradient */}
      <div className="fixed inset-0 z-0">
        <div 
          className="absolute inset-0  opacity-20"
          style={{
            backgroundImage: `url(https://image.tmdb.org/t/p/original${mediaDetails?.backdrop_path})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(80px)',
          }}
        />
        <div className="absolute  inset-0 bg-gradient-to-b from-black via-transparent to-black" />
      </div>

      <div className={`relative z-10 transition-all duration-500 ease-in-out
                      ${isTheaterMode ? 'p-0' : 'p-4 pt-20'}`}>
        {/* Modified Header */}
        <AnimatePresence>
          {!isTheaterMode && (
            <motion.header
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className=" top-0 left-0 right-0 z-20 mt-6"
            >
              <div className="bg-background/80 backdrop-blur-md border-b border-white/5">
                <div className="container mx-auto px-4 py-4">
                  <div className="flex items-center justify-between">
                    <h1 className="text-white font-medium truncate">
                      {mediaDetails?.title || mediaDetails?.name}
                      {mediaType === 'tv' && (
                        <span className="ml-2 text-sm text-gold-500">
                          S{selectedSeason} E{selectedEpisode}
                        </span>
                      )}
                    </h1>
                    <button
                      onClick={() => setIsTheaterMode(!isTheaterMode)}
                      className="text-white/70 hover:text-gold-500 transition-colors"
                    >
                      {isTheaterMode ? 'Exit Theater Mode' : 'Theater Mode'}
                    </button>
                  </div>
                </div>
              </div>
            </motion.header>
          )}
        </AnimatePresence>

        {/* Main Content - Updated Layout */}
        <div className={`flex ${isTheaterMode ? 'flex-col' : 'flex-col lg:flex-row'} gap-4`}>
          {/* Player Section with improved transitions */}
          <motion.div
            layout
            className={`${isTheaterMode ? 'h-screen' : 'lg:flex-1'}`}
          >
            <div className="relative aspect-video rounded-lg overflow-hidden bg-background/50 
                          shadow-[0_0_50px_rgba(0,0,0,0.3)] border border-white/10">
              <iframe
                ref={playerRef}
                src={currentUrl}
                title={`${mediaDetails?.title || mediaDetails?.name} ${mediaType === 'tv' ? `S${selectedSeason} E${selectedEpisode}` : ''}`}
                className="absolute inset-0 w-full h-full"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                onError={handleIframeError}
                onLoad={handleIframeLoad}
              />
              
              {/* Player Overlay */}
              <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-black/20 to-transparent" />
              
              {/* Loading Indicator */}
              {loading && (
                <div className="absolute inset-0 bg-background/80 flex items-center justify-center">
                  <div className="relative">
                    <div className="w-16 h-16 border-4 border-gold-500/20 border-t-gold-500 rounded-full animate-spin" />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-12 h-12 border-4 border-gold-500/40 rounded-full" />
                    </div>
                  </div>
                </div>
              )}

              {/* Error Message Overlay */}
              {serverErrorCount > 0 && (
                <div className="absolute inset-0 bg-background/80 flex items-center justify-center">
                  <div className="text-center space-y-4">
                    <p className="text-white">Content unavailable on this server</p>
                    <p className="text-gold-500">Trying alternative server...</p>
                  </div>
                </div>
              )}
            </div>
          </motion.div>

          {/* Controls Panel - Improved slide animation */}
          <motion.div 
            layout
            initial={false}
            animate={{ 
              x: isTheaterMode ? '100%' : 0,
              opacity: isTheaterMode ? 0 : 1,
              transition: {
                type: "spring",
                stiffness: 100,
                damping: 20
              }
            }}
            className={`
              lg:w-[400px] flex flex-col gap-4
              fixed lg:relative
              ${isTheaterMode ? 'right-0 top-0 h-full' : ''}
            `}
          >
            <div className="lg:sticky lg:top-4 lg:max-h-[calc(100vh-2rem)] flex flex-col gap-4
                          overflow-y-auto rounded-xl bg-background/20 backdrop-blur-sm p-4
                          scrollbar-thin scrollbar-track-white/5 scrollbar-thumb-gold-500/20
                          hover:scrollbar-thumb-gold-500/50 scroll-smooth">
              {/* Scroll Indicator */}
              <div className="absolute right-2 top-1/2 -translate-y-1/2 hidden lg:flex flex-col items-center gap-1">
                <div className="w-1 h-16 rounded-full bg-white/5 relative overflow-hidden">
                  <div className="absolute top-0 left-0 right-0 h-1/3 bg-gold-500/50 
                                rounded-full animate-scroll" />
                </div>
              </div>

              {mediaType === 'tv' && (
                <div className="flex-1">
                  <EpisodeSelector
                    seasons={seasons}
                    episodes={episodes}
                    currentSeason={selectedSeason}
                    currentEpisode={selectedEpisode}
                    onSelect={handleEpisodeSelect}
                    watchProgress={watchedEpisodes}
                  />
                </div>
              )}
              
              <div>
                <ServerSelector
                  servers={videoServers}
                  activeServer={currentUrl}
                  onServerChange={handleServerChange}
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Add floating theater mode button */}
      <TheaterModeButton />

      {/* Optional: Add keyboard shortcut hint */}
      <div className="fixed bottom-6 left-6 text-white/50 text-sm">
        Press F for theater mode
      </div>
    </div>
  );
};

export default WatchPage;