import { useState } from 'react';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_ANI_API;

export const useAnimeApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHome = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE}/home`);
      const data = await response.json();
      return data;
    } catch {
      setError('Failed to fetch home data');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getSuggestions = async (query) => {
    if (!query.trim()) return null;
    try {
      const response = await fetch(`${API_BASE}/search/suggestion?q=${encodeURIComponent(query)}`);
      const data = await response.json();
      return data;
    } catch (err) {
      console.error('Failed to fetch suggestions:', err);
      return null;
    }
  };

  const getAnimeInfo = async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE}/anime/${id}`);
      const data = await response.json();
      return data;
    } catch  {
      setError('Failed to fetch anime details');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getAnimeQtip = async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE}/qtip/${id}`);
      const data = await response.json();
      return data;
    } catch {
      setError('Failed to fetch anime qtip info');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Updated streaming links function with better error handling
  const getStreamingLinks = async (animeId) => {
    setIsLoading(true);
    setError(null);
    
    // Handle different parameter formats
    // If it's already a full episodeId, use it directly
    // Otherwise, try to construct it from animeId + episode number
    let episodeId;
    let encodedEpisodeId;
    
    if (animeId.includes("?ep=")) {
      // It's already a complete episodeId
      episodeId = animeId;
    } else {
      // Construct episodeId from animeId and episode number
      const episodeNumber = 1; // Default to episode 1 if not provided
      episodeId = `${animeId}?ep=${episodeNumber}`;
    }
    
    encodedEpisodeId = encodeURIComponent(episodeId);
    const url = `${API_BASE}/episode/sources?animeEpisodeId=${encodedEpisodeId}`;
    
    console.log('Fetching stream with params:', {
      animeId,
      episodeId,
      fullUrl: url
    });

    try {
      const response = await axios.get(url);
      
      // Validate and normalize the intro/outro data
      const streamData = response.data?.data;
      if (streamData?.intro || streamData?.outro) {
        console.log('Found markers:', {
          intro: streamData.intro,
          outro: streamData.outro
        });
      }

      return {
        success: true,
        data: {
          ...streamData,
          intro: streamData.intro ? {
            start: Math.floor(Number(streamData.intro.start)),
            end: Math.floor(Number(streamData.intro.end))
          } : undefined,
          outro: streamData.outro ? {
            start: Math.floor(Number(streamData.outro.start)),
            end: Math.floor(Number(streamData.outro.end))
          } : undefined
        }
      };
    } catch (err) {
      console.error('Streaming error:', err);
      setError('Failed to fetch streaming links');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getEpisodes = async (animeId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE}/anime/${animeId}/episodes`);
      const data = await response.json();
      return data;
    } catch {
      setError('Failed to fetch episodes');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getEpisodeServers = async (episodeId) => {
    try {
      const response = await fetch(`${API_BASE}/episode/servers?animeEpisodeId=${episodeId}`);
      const data = await response.json();
      return data;
    } catch (err) {
      console.error('Failed to fetch episode servers:', err);
      return null;
    }
  };

  const getSchedule = async (date) => {
    try {
      const response = await fetch(`${API_BASE}/schedule?date=${date}`);
      const data = await response.json();
      return data;
    } catch (err) {
      console.error('Failed to fetch schedule:', err);
      return null;
    }
  };

  return {
    getHome,
    getSuggestions,
    getAnimeInfo,
    isLoading,
    error,
    getAnimeQtip,
    getStreamingLinks,
    getEpisodes,
    getEpisodeServers,
    getSchedule
  };
};
