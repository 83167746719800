import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { searchContent } from '../services/search';
import { FaPlay, FaStar, FaSearch, FaTimes } from 'react-icons/fa';
import { Filter, Activity, Film, Tv, User2, Wand2, Search, SliderIcon, SlidersIcon, Gamepad2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// Add genres constant
const genres = [
  { id: 28, name: "Action" },
  { id: 12, name: "Adventure" },
  { id: 16, name: "Animation" },
  { id: 35, name: "Comedy" },
  { id: 80, name: "Crime" },
  { id: 99, name: "Documentary" },
  { id: 18, name: "Drama" },
  { id: 10751, name: "Family" },
  { id: 14, name: "Fantasy" },
  { id: 36, name: "History" },
  { id: 27, name: "Horror" },
  { id: 10402, name: "Music" },
  { id: 9648, name: "Mystery" },
  { id: 10749, name: "Romance" },
  { id: 878, name: "Science Fiction" },
  { id: 10770, name: "TV Movie" },
  { id: 53, name: "Thriller" },
  { id: 10752, name: "War" },
  { id: 37, name: "Western" },
  // Anime specific genres
  { id: 'anime_action', name: "Anime Action" },
  { id: 'anime_adventure', name: "Anime Adventure" },
  { id: 'anime_comedy', name: "Anime Comedy" },
  { id: 'anime_drama', name: "Anime Drama" },
  { id: 'anime_fantasy', name: "Anime Fantasy" },
  { id: 'anime_horror', name: "Anime Horror" },
  { id: 'anime_mecha', name: "Mecha" },
  { id: 'anime_psychological', name: "Psychological" },
  { id: 'anime_romance', name: "Anime Romance" },
  { id: 'anime_scifi', name: "Anime Sci-Fi" },
  { id: 'anime_slice_of_life', name: "Slice of Life" },
  { id: 'anime_sports', name: "Sports" },
  { id: 'anime_supernatural', name: "Supernatural" },
];

const SearchPage = () => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filters, setFilters] = useState({ 
    genre: '', 
    year: '', 
    type: '',
    rating: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [advancedFilters, setAdvancedFilters] = useState({
    year: '',
    rating: '',
    genre: '',
    sortBy: 'popularity'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [displayResults, setDisplayResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('query');

  // Utility function to safely format rating
  const formatRating = (rating) => {
    if (typeof rating === 'number') {
      return rating.toFixed(1);
    }
    const numRating = parseFloat(rating);
    if (!isNaN(numRating)) {
      return numRating.toFixed(1);
    }
    return 'N/A';
  };

  // Utility function to get poster image
  const getPosterImage = (item) => {
    if (item.media_type === 'person') {
      return item.profile_path 
        ? `https://image.tmdb.org/t/p/w500${item.profile_path}`
        : '/images/default-avatar.png'; // Add a default avatar image
    }
    // Prioritize different poster sources
    if (item.source === 'tmdb') {
      // For TMDB, construct full poster path
      return item.poster_path 
        ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
        : 'https://via.placeholder.com/300x450';
    } else if (item.source === 'consumet') {
      // For Consumet, use image directly
      return item.image || 'https://via.placeholder.com/300x450';
    }
    
    // Fallback placeholder
    return 'https://via.placeholder.com/300x450';
  };

  // Add a new function to render the card content based on item type
  const renderCardContent = (item) => {
    const isActor = item.media_type === 'person';

    return (
      <div className="relative overflow-hidden rounded-xl shadow-xl 
                    transition-all duration-300 border border-transparent
                    group-hover:border-gold-500/50 group-hover:scale-105 
                    group-hover:shadow-2xl bg-background/5">
        {/* Type Badge */}
        {isActor ? (
          <div className="absolute top-2 left-2 z-10 
                       bg-blue-600 text-white px-2 py-1 
                       rounded-full text-xs font-bold">
            Actor
          </div>
        ) : item.source === 'consumet' ? (
          <div className="absolute top-2 left-2 z-10 
                       bg-purple-600 text-white px-2 py-1 
                       rounded-full text-xs font-bold">
            Anime
          </div>
        ) : null}

        {/* Image Container */}
        <div className="relative pt-[150%] overflow-hidden"> 
          <img 
            src={getPosterImage(item)}
            alt={item.title || item.name}
            className={`absolute inset-0 w-full h-full
                     transition-opacity duration-300 
                     transform group-hover:scale-110
                     ${isActor ? 'object-top' : 'object-cover'}
                     opacity-90 group-hover:opacity-100`}
          />
          
          {/* Hover Overlay - Only for non-actor content */}
          {!isActor && (
            <div className="absolute inset-0 bg-background/0 
                         group-hover:bg-background/30 transition-all 
                         duration-300 flex items-center justify-center">
              <div className="opacity-0 group-hover:opacity-100 
                          transition-opacity duration-300">
                <FaPlay className="text-gold-500 text-4xl 
                              transform hover:scale-110 
                              transition-transform" />
              </div>
            </div>
          )}
          
          {/* Rating Overlay - Only for non-actor content */}
          {!isActor && (
            <div className="absolute top-2 right-2 
                         bg-background/60 rounded-full 
                         px-2 py-1 flex items-center">
              <FaStar className="text-gold-500 mr-1" />
              <span className="text-xs text-gold-400 font-bold">
                {formatRating(item.vote_average || item.rating)}
              </span>
            </div>
          )}
        </div>

        {/* Info Overlay */}
        <div className="absolute bottom-0 left-0 right-0 
                     bg-gradient-to-t from-black/80 to-transparent 
                     p-2">
          <h3 className="text-xs font-semibold text-gold-400 
                      truncate">
            {item.title || item.name}
          </h3>
          {isActor && item.known_for_department && (
            <p className="text-xs text-gray-400 truncate">
              {item.known_for_department}
            </p>
          )}
        </div>
      </div>
    );
  };

  // Fetch search results
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await searchContent(searchQuery);
        
        // Combine and normalize results with proper media types
        const combinedResults = [
          ...data.movies.map(item => ({
            ...item,
            source: 'tmdb',
            media_type: 'movie',
            type: 'movie'
          })),
          ...data.tvShows.map(item => ({
            ...item,
            source: 'tmdb',
            media_type: 'tv',
            type: 'tv'
          })),
          ...data.anime.map(item => ({
            ...item,
            source: 'consumet',
            media_type: 'anime',
            type: 'anime',
            poster_path: item.image,
            vote_average: item.rating/10,
            title: item.title
          })),
          ...data.actors.map(item => ({
            ...item,
            source: 'tmdb',
            media_type: 'person',
            type: 'person',
            poster_path: item.profile_path,
            title: item.name
          }))
        ];

        setResults(combinedResults);
        setFilteredResults(combinedResults);
      } catch (error) {
        console.error('Search error:', error);
      }
    };

    if (searchQuery) {
      fetchResults();
    }
  }, [searchQuery]);

  // Filter change handler (previous implementation remains the same)
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    let filtered = results;

    // Genre filter
    if (newFilters.genre) {
      filtered = filtered.filter(item => {
        // Handle different sources' genre formats
        if (item.source === 'tmdb') {
          return item.genre_ids?.includes(Number(newFilters.genre));
        }
        if (item.source === 'consumet') {
          const animeGenre = genres.find(g => g.id === newFilters.genre)?.name;
          return item.genres?.some(genre => 
            genre.toLowerCase() === animeGenre?.toLowerCase().replace('anime ', '')
          );
        }
        return true;
      });
    }

    // Year filter
    if (newFilters.year) {
      filtered = filtered.filter(item => {
        const year = item.release_date 
          || item.first_air_date 
          || item.releaseDate;
        return year?.startsWith(newFilters.year);
      });
    }

    // Type filter
    if (newFilters.type) {
      filtered = filtered.filter(item => 
        item.media_type === newFilters.type
      );
    }

    // Rating filter
    if (newFilters.rating) {
      filtered = filtered.filter(item => {
        const rating = parseFloat(item.vote_average || item.rating || 0);
        return rating >= Number(newFilters.rating);
      });
    }

    setFilteredResults(filtered);
  };

  // Fetch and filter results based on activeTab
  useEffect(() => {
    const filterResults = () => {
      if (!filteredResults) return [];
      
      switch (activeTab) {
        case 'movies':
          return filteredResults.filter(item => item.media_type === 'movie');
        case 'tv':
          return filteredResults.filter(item => item.media_type === 'tv');
        case 'person':
          return filteredResults.filter(item => item.media_type === 'person');
        case 'anime':
          return filteredResults.filter(item => item.media_type === 'anime');
        default:
          return filteredResults;
      }
    };

    setDisplayResults(filterResults());
  }, [activeTab, filteredResults]);

  // Apply filters
  useEffect(() => {
    let filtered = results;

    if (advancedFilters.year) {
      filtered = filtered.filter(item => {
        const year = new Date(item.release_date || item.first_air_date).getFullYear();
        return year === parseInt(advancedFilters.year);
      });
    }

    if (advancedFilters.rating) {
      filtered = filtered.filter(item => {
        const rating = parseFloat(item.vote_average);
        return rating >= parseFloat(advancedFilters.rating);
      });
    }

    // Sort results
    switch (advancedFilters.sortBy) {
      case 'rating':
        filtered.sort((a, b) => b.vote_average - a.vote_average);
        break;
      case 'latest':
        filtered.sort((a, b) => new Date(b.release_date || b.first_air_date) - new Date(a.release_date || a.first_air_date));
        break;
      case 'oldest':
        filtered.sort((a, b) => new Date(a.release_date || a.first_air_date) - new Date(b.release_date || b.first_air_date));
        break;
      default:
        filtered.sort((a, b) => b.popularity - a.popularity);
    }

    setFilteredResults(filtered);
  }, [results, advancedFilters]);

  // Enhanced Search Handler
  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    navigate(`/search?query=${searchInput}`);
    
    try {
      const data = await searchContent(searchInput);
      const processedResults = [
        ...data.movies,
        ...data.tvShows,
        ...data.anime,
        ...data.actors
      ];
      setResults(processedResults);
      setFilteredResults(processedResults);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterTabs = [
    { 
      id: 'all', 
      label: 'All', 
      icon: <Filter className="w-4 h-4" />,
      color: 'gold'
    },
    { 
      id: 'movies', 
      label: 'Movies', 
      icon: <Film className="w-4 h-4" />,
      color: 'red'
    },
    { 
      id: 'tv', 
      label: 'TV Shows', 
      icon: <Tv className="w-4 h-4" />,
      color: 'blue'
    },
    { 
      id: 'anime', 
      label: 'Anime', 
      icon: <Gamepad2 className="w-4 h-4" />,
      color: 'purple'
    },
    { 
      id: 'person', 
      label: 'Actors', 
      icon: <User2 className="w-4 h-4" />,
      color: 'green'
    }
  ];

  return (
    <div className="min-h-screen  bg-gradient-to-b from-black via-zinc-900 to-black pt-24">
      {/* Enhanced Filter Tabs */}
      <div className="max-w-7xl mt-4 mx-auto px-4 mb-8">
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="flex flex-wrap gap-2 bg-background/40 p-1.5 rounded-xl border border-gold-500/20">
            {filterTabs.map((tab) => (
              <motion.button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  px-4 py-2 rounded-lg text-sm transition-all relative
                  flex items-center gap-2 min-w-[100px] justify-center
                  ${activeTab === tab.id 
                    ? 'text-white' 
                    : 'text-gray-400 hover:text-white'
                  }
                `}
              >
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTab"
                    className={`
                      absolute inset-0 rounded-lg -z-10
                      bg-gradient-to-br
                      ${tab.id === 'all' && 'from-gold-500 to-gold-600'}
                      ${tab.id === 'movies' && 'from-red-500 to-red-600'}
                      ${tab.id === 'tv' && 'from-blue-500 to-blue-600'}
                      ${tab.id === 'anime' && 'from-purple-500 to-purple-600'}
                      ${tab.id === 'person' && 'from-green-500 to-green-600'}
                    `}
                    transition={{ 
                      type: "spring", 
                      bounce: 0.2, 
                      duration: 0.6 
                    }}
                  />
                )}
                {tab.icon}
                <span className="relative z-10 font-medium">{tab.label}</span>
                <span className="text-xs opacity-60 ml-1">
                  ({
                    tab.id === 'all' 
                      ? filteredResults.length
                      : filteredResults.filter(item => 
                          item.media_type === tab.id
                        ).length
                  })
                </span>
              </motion.button>
            ))}
          </div>

          <motion.button
            onClick={() => setShowFilters(!showFilters)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-xl
              transition-all duration-300
              ${showFilters 
                ? 'bg-gold-500 text-black' 
                : 'bg-background/40 text-gold-500 border border-gold-500/20'
              }
            `}
          >
            <SlidersIcon className="w-4 h-4" />
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </motion.button>
        </div>

        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="overflow-hidden"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4 
                            bg-background/40 p-4 rounded-xl border border-gold-500/20">
                {/* Year Filter */}
                <div className="space-y-2">
                  <label className="text-sm text-gold-500">Year</label>
                  <select
                    value={advancedFilters.year}
                    onChange={(e) => setAdvancedFilters({...advancedFilters, year: e.target.value})}
                    className="w-full bg-background/60 text-white rounded-lg px-3 py-2 
                             border border-gold-500/20"
                  >
                    <option value="">All Years</option>
                    {[...Array(30)].map((_, i) => (
                      <option key={i} value={2024 - i}>
                        {2024 - i}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Rating Filter */}
                <div className="space-y-2">
                  <label className="text-sm text-gold-500">Minimum Rating</label>
                  <select
                    value={advancedFilters.rating}
                    onChange={(e) => setAdvancedFilters({...advancedFilters, rating: e.target.value})}
                    className="w-full bg-background/60 text-white rounded-lg px-3 py-2 
                             border border-gold-500/20"
                  >
                    <option value="">Any Rating</option>
                    {[9, 8, 7, 6, 5].map((rating) => (
                      <option key={rating} value={rating}>
                        {rating}+ Stars
                      </option>
                    ))}
                  </select>
                </div>

                {/* Genre Filter */}
                <div className="space-y-2">
                  <label className="text-sm text-gold-500">Genre</label>
                  <select
                    value={advancedFilters.genre}
                    onChange={(e) => setAdvancedFilters({...advancedFilters, genre: e.target.value})}
                    className="w-full bg-background/60 text-white rounded-lg px-3 py-2 
                             border border-gold-500/20 hover:border-gold-500/40
                             transition-colors duration-300"
                  >
                    <option value="">All Genres</option>
                    <optgroup label="Movies & TV">
                      {genres.filter(g => !g.id.toString().includes('anime')).map(genre => (
                        <option key={genre.id} value={genre.id}>
                          {genre.name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Anime">
                      {genres.filter(g => g.id.toString().includes('anime')).map(genre => (
                        <option key={genre.id} value={genre.id}>
                          {genre.name.replace('Anime ', '')}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>

                {/* Sort By */}
                <div className="space-y-2">
                  <label className="text-sm text-gold-500">Sort By</label>
                  <select
                    value={advancedFilters.sortBy}
                    onChange={(e) => setAdvancedFilters({...advancedFilters, sortBy: e.target.value})}
                    className="w-full bg-background/60 text-white rounded-lg px-3 py-2 
                             border border-gold-500/20"
                  >
                    <option value="popularity">Popularity</option>
                    <option value="rating">Rating</option>
                    <option value="latest">Latest</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Enhanced Results Section */}
      <div className="max-w-7xl mx-auto  px-4 py-5">
        <AnimatePresence mode="wait">
          {isLoading ? (
            <LoadingGrid />
          ) : displayResults.length > 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4"
            >
              {displayResults.map((item, index) => (
                <ResultCard key={`${item.id}-${item.media_type}`} item={item} index={index} />
              ))}
            </motion.div>
          ) : (
            <NoResults query={searchQuery} />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Helper Components
const LoadingGrid = () => (
  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
    {[...Array(12)].map((_, index) => (
      <div key={index} className="relative">
        <div className="aspect-[2/3] rounded-xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-black/5 to-black/20 
                         animate-shimmer" 
               style={{ backgroundSize: '400% 100%' }}/>
          <div className="w-full h-full bg-zinc-900/50 animate-pulse" />
        </div>
        <div className="mt-2 space-y-2">
          <div className="h-4 bg-zinc-900/50 rounded animate-pulse w-3/4" />
          <div className="h-3 bg-zinc-900/50 rounded animate-pulse w-1/2" />
        </div>
      </div>
    ))}
  </div>
);

const NoResults = ({ query }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="text-center py-16"
  >
    <div className="inline-block p-4 rounded-full bg-gold-500/10 mb-4">
      <Search className="w-8 h-8 text-gold-500" />
    </div>
    <h2 className="text-2xl font-semibold text-white mb-2">
      No results found for "{query}"
    </h2>
    <p className="text-gray-400 max-w-md mx-auto">
      Try adjusting your search terms or filters to find what you're looking for.
    </p>
    <div className="mt-8 space-y-4">
      <h3 className="text-gold-500 font-medium">Suggestions:</h3>
      <ul className="text-gray-400 space-y-2">
        <li>• Check for spelling errors</li>
        <li>• Try using fewer or different keywords</li>
        <li>• Use more general terms</li>
        <li>• Remove filters to broaden your search</li>
      </ul>
    </div>
  </motion.div>
);

const ResultCard = ({ item, index }) => {
  const formatRating = (rating) => {
    if (typeof rating === 'number') {
      return rating.toFixed(1);
    }
    const numRating = parseFloat(rating);
    if (!isNaN(numRating)) {
      return numRating.toFixed(1);
    }
    return 'N/A';
  };

  const getPosterImage = (item) => {
    if (item.media_type === 'person') {
      return item.profile_path 
        ? `https://image.tmdb.org/t/p/w500${item.profile_path}`
        : 'https://via.placeholder.com/500x750/374151/FFFFFF?text=No+Image';
    }
    if (item.source === 'tmdb') {
      return item.poster_path 
        ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
        : 'https://via.placeholder.com/500x750/374151/FFFFFF?text=No+Poster';
    }
    if (item.source === 'consumet') {
      return item.image || 'https://via.placeholder.com/500x750/374151/FFFFFF?text=No+Image';
    }
    return 'https://via.placeholder.com/500x750/374151/FFFFFF?text=No+Image';
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: index * 0.05,
        ease: "easeOut"
      }
    }
  };

  const isActor = item.media_type === 'person';

  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.98 }}
    >
      <Link 
        to={
          item.type === 'person' 
            ? `/actor/${item.id}`
            : item.type === 'anime'
            ? `/watch/anime/${item.id}`
            : `/details/${item.type}/${item.id}`
        }
        className="block group relative overflow-hidden"
      >
        {/* Card Container */}
        <div className="relative bg-zinc-900/50 rounded-xl overflow-hidden
                      border border-zinc-800/50 backdrop-blur-sm
                      group-hover:border-gold-500/50 
                      group-hover:shadow-lg group-hover:shadow-gold-500/10
                      transition-all duration-300">
          {/* Image Container */}
          <div className="relative aspect-[2/3] overflow-hidden">
            <img
              src={getPosterImage(item)}
              alt={item.title || item.name}
              className={`w-full h-full transition-all duration-500
                       ${isActor ? 'object-top' : 'object-cover'}
                       group-hover:scale-110 group-hover:opacity-50`}
              loading="lazy"
            />
            
            {/* Overlay Gradient */}
            <div className="absolute inset-0 bg-gradient-to-t 
                          from-black via-black/50 to-transparent 
                          opacity-0 group-hover:opacity-100
                          transition-opacity duration-300" />

            {/* Content Type Badge */}
            <div className="absolute top-2 left-2 px-2 py-1 rounded-lg
                          text-xs font-medium backdrop-blur-md
                          bg-background/50 border border-white/10
                          text-white/90 tracking-wide
                          transform -translate-y-1 opacity-0
                          group-hover:translate-y-0 group-hover:opacity-100
                          transition-all duration-300">
              {isActor ? 'Actor' :
               item.media_type === 'movie' ? 'Movie' :
               item.media_type === 'tv' ? 'TV Show' : 'Anime'}
            </div>

            {/* Rating Badge - Only for non-actor content */}
            {!isActor && (
              <div className="absolute top-2 right-2 
                            flex items-center gap-1.5 px-2 py-1 
                            rounded-lg text-xs font-medium
                            bg-background/50 border border-white/10 backdrop-blur-md
                            transform translate-y-1 opacity-0
                            group-hover:translate-y-0 group-hover:opacity-100
                            transition-all duration-300 delay-75">
                <FaStar className="text-gold-500 w-3.5 h-3.5" />
                <span className="text-white/90">
                  {formatRating(item.vote_average || item.rating)}
                </span>
              </div>
            )}

            {/* Hover Content */}
            <div className="absolute inset-0 flex flex-col justify-end p-4
                          translate-y-6 opacity-0
                          group-hover:translate-y-0 group-hover:opacity-100
                          transition-all duration-300">
              {/* Title */}
              <h3 className="text-base font-semibold text-white mb-1
                           line-clamp-2 drop-shadow-lg">
                {item.title || item.name}
              </h3>

              {/* Info Row */}
              <div className="flex items-center gap-3 text-xs text-white/75">
                {isActor ? (
                  <span>{item.known_for_department}</span>
                ) : (
                  <>
                    <span>
                      {item.type === 'anime' 
                        ? (item.releaseDate || 'TBA')
                        : new Date(
                            item.release_date || 
                            item.first_air_date || 
                            ''
                          ).getFullYear() || 'TBA'
                      }
                    </span>
                    {item.genres && (
                      <span className="line-clamp-1">
                        {item.genres.slice(0, 2).join(' • ')}
                      </span>
                    )}
                  </>
                )}
              </div>

              {/* Play Icon or View Profile */}
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                            transform scale-0 opacity-0
                            group-hover:scale-100 group-hover:opacity-100
                            transition-all duration-300 delay-100">
                {isActor ? (
                  <User2 className="w-12 h-12 text-gold-500" />
                ) : (
                  <FaPlay className="w-12 h-12 text-gold-500" />
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default SearchPage;
