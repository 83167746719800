import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Play, Star, Clock, Bookmark, TrendingUp } from 'lucide-react';
import { useAnimeApi } from '../useAnimeApi.jsx';
import { AnimeModal } from '../modal/AnimeModal';

export const PremiumCard = ({ anime, index }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animeDetails, setAnimeDetails] = useState(null);
  const { getAnimeQtip } = useAnimeApi();
  const [isLoading, setIsLoading] = useState(false);

  const handleCardClick = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    try {
      setIsLoading(true);
      const response = await getAnimeQtip(anime.id.toString());
      
      if (response?.success) {
        setAnimeDetails(response.data.anime);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Failed to fetch anime details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: index * 0.1 }}
        onClick={handleCardClick}
        className="group relative cursor-pointer"
      >
        {/* Card Container */}
        <div className="relative aspect-[3/4] rounded-xl overflow-hidden bg-card-bg">
          {/* Premium Gradient Border */}
          <div className="absolute inset-0 rounded-xl p-[1px] bg-gradient-to-br from-[#6366f1]/30 via-[#8b5cf6]/30 to-[#d946ef]/30 opacity-0 
            group-hover:opacity-100 transition-all duration-500 z-10">
            <div className="absolute inset-0 rounded-xl bg-card-bg" />
          </div>

          {/* Main Image */}
          <div className="relative w-full h-full overflow-hidden">
            <img 
              src={anime.poster}
              alt={anime.name}
              className="w-full h-full object-cover transform group-hover:scale-110 
                transition-all duration-700 ease-out"
            />
            
            {/* Updated Overlay Gradient */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent 
              opacity-0 group-hover:opacity-100 transition-all duration-500" />

            {/* Content Overlay */}
            <div className="absolute inset-0 p-4 flex flex-col justify-between opacity-0 
              group-hover:opacity-100 transition-all duration-300">
              {/* Top Section */}
              <div className="flex justify-between items-start">
                {anime.rank && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: index * 0.1 + 0.2 }}
                    className="flex items-center gap-1 bg-background/50 backdrop-blur-md rounded-lg px-2 py-1"
                  >
                    <TrendingUp className="w-3 h-3 text-[#6366f1]" />
                    <span className="text-xs font-bold text-white">#{anime.rank}</span>
                  </motion.div>
                )}
                
                <motion.button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsBookmarked(!isBookmarked);
                  }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className={`p-2 rounded-full backdrop-blur-md transition-all duration-300 
                    ${isBookmarked ? 'bg-[#6366f1]/20 text-[#6366f1]' : 'bg-background/50 text-white hover:text-[#6366f1]'}`}
                >
                  <Bookmark className="w-4 h-4" fill={isBookmarked ? 'currentColor' : 'none'} />
                </motion.button>
              </div>

              {/* Centered Play Button */}
              <motion.div 
                className="absolute inset-0 flex items-center justify-center"
                initial={{ scale: 0 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <div className="p-4 rounded-full bg-[#6366f1]/20 backdrop-blur-md 
                  border border-[#6366f1]/20 group-hover:bg-[#6366f1]/30 transition-all duration-300">
                  <Play className="w-6 h-6 text-[#6366f1]"  />
                </div>
              </motion.div>

              {/* Updated Bottom Info - removed type */}
              <div className="flex flex-wrap gap-2">
                {anime.rating && (
                  <div className="flex items-center gap-1 bg-background/50 backdrop-blur-md px-2 py-1 rounded-lg">
                    <Star className="w-3 h-3 text-[#6366f1]" />
                    <span className="text-xs text-white">{anime.rating}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Updated Title Section */}
        <div className="mt-3 space-y-1.5">
          <h3 className="font-night text-base text-white group-hover:text-[#6366f1] 
            transition-colors duration-300 truncate">
            {anime.name}
          </h3>
          <div className="flex items-center gap-3">
            {anime.type && (
              <div className="flex items-center gap-2">
                <div className="w-1 h-1 rounded-full bg-[#6366f1]/50" />
                <span className="text-xs text-[#8b5cf6]">{anime.type}</span>
              </div>
            )}
            <div className="flex items-center gap-3">
              {anime.episodes && (
                <div className="flex items-center gap-2">
                  <div className="w-1 h-1 rounded-full bg-[#d946ef]/50" />
                  <span className="text-xs text-[#d946ef]">EP {anime.episodes.sub}</span>
                </div>
              )}
              {anime.duration && (
                <div className="flex items-center gap-2">
                  <Clock className="w-3 h-3 text-[#6366f1]" />
                  <span className="text-xs text-[#8b5cf6]">{anime.duration}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Premium Glow Effect */}
        <div className="absolute -inset-x-4 -inset-y-4 z-[-1] hidden group-hover:block 
          bg-gradient-conic from-[#6366f1]/10 via-[#8b5cf6]/10 to-[#d946ef]/10 opacity-0 
          group-hover:opacity-100 transition-all duration-500 blur-2xl rounded-full" />
      </motion.div>

      <AnimeModal
        anime={{
          ...anime,
          jname: animeDetails?.jname,
          synopsis: animeDetails?.description,
          genres: animeDetails?.genres,
          status: animeDetails?.status,
          aired: animeDetails?.aired,
          quality: animeDetails?.quality || 'HD',
          studio: animeDetails?.studios,
          rating: animeDetails?.malscore,
          type: animeDetails?.type,
          episodes: animeDetails?.episodes || anime.episodes,
        }}
        isOpen={isModalOpen && !!animeDetails}
        onClose={() => {
          setIsModalOpen(false);
          setAnimeDetails(null);
        }}
      />
    </>
  );
};
