import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Check, Clock, ChevronDown, Calendar, Star } from 'lucide-react';

const MobileEpisodeSelector = ({ seasons, episodes, currentSeason, currentEpisode, onSelect, watchedEpisodes }) => {
  const [showSeasonDropdown, setShowSeasonDropdown] = useState(false);

  return (
    <div className="flex flex-col">
      {/* Enhanced Season Dropdown */}
      <div className="relative mb-4">
        <button
          onClick={() => setShowSeasonDropdown(!showSeasonDropdown)}
          className="w-full flex items-center justify-between p-4 bg-background/40 
                   rounded-xl border border-gold-500/20 hover:border-gold-500/40
                   transition-all duration-300"
        >
          <div className="flex items-center gap-3">
            <Calendar className="w-5 h-5 text-gold-500" />
            <div className="text-left">
              <span className="text-white/50 text-xs">Currently Watching</span>
              <p className="text-white font-medium">Season {currentSeason}</p>
            </div>
          </div>
          <ChevronDown className={`w-5 h-5 text-gold-500 transition-transform duration-300
                               ${showSeasonDropdown ? 'rotate-180' : ''}`} />
        </button>

        {/* Season Dropdown Menu */}
        <AnimatePresence>
          {showSeasonDropdown && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute top-full left-0 right-0 mt-2 z-50 bg-background/95 
                       backdrop-blur-xl rounded-xl border border-white/10 overflow-hidden
                       shadow-xl shadow-black/50"
            >
              {seasons?.map(season => (
                <motion.button
                  key={season.season_number}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    onSelect({
                      season_number: season.season_number,
                      episode_number: 1
                    });
                    setShowSeasonDropdown(false);
                  }}
                  className={`w-full flex items-center gap-3 p-4 transition-all
                           ${currentSeason === season.season_number
                             ? 'bg-gold-500/20 text-gold-500'
                             : 'hover:bg-white/5 text-white/70'}`}
                >
                  <Calendar className="w-4 h-4" />
                  <div className="flex-1 text-left">
                    <span className="font-medium">Season {season.season_number}</span>
                    <div className="flex items-center gap-2 mt-1 text-xs text-white/40">
                      <span>{season.episode_count} Episodes</span>
                      {season.air_date && (
                        <>
                          <span className="w-1 h-1 rounded-full bg-white/20" />
                          <span>{new Date(season.air_date).getFullYear()}</span>
                        </>
                      )}
                    </div>
                  </div>
                  {currentSeason === season.season_number && (
                    <div className="w-2 h-2 rounded-full bg-gold-500" />
                  )}
                </motion.button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Episodes Grid */}
      <div className="grid gap-3">
        {episodes.map((episode) => {
          const isWatched = watchedEpisodes[`season${currentSeason}`]?.includes(episode.episode_number);
          const isActive = currentEpisode === episode.episode_number;

          return (
            <motion.button
              key={episode.episode_number}
              whileTap={{ scale: 0.98 }}
              onClick={() => onSelect({ ...episode, season_number: currentSeason })}
              className={`w-full flex items-stretch overflow-hidden rounded-xl border
                       transition-all duration-300 ${
                         isActive
                           ? 'bg-gold-500/20 border-gold-500'
                           : isWatched
                             ? 'bg-white/5 border-white/10'
                             : 'bg-background/40 border-white/5'
                       }`}
            >
              {/* Thumbnail */}
              <div className="relative w-32 h-20 shrink-0">
                <img
                  src={`https://image.tmdb.org/t/p/w300${episode.still_path}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div className={`absolute inset-0 flex items-center justify-center
                             ${isWatched ? 'bg-background/60' : 'bg-gradient-to-t from-black/80 to-transparent'}`}>
                  {isWatched ? (
                    <Check className="w-5 h-5 text-gold-500" />
                  ) : (
                    <Play className="w-5 h-5 text-white opacity-0 group-hover:opacity-100" />
                  )}
                </div>
              </div>

              {/* Episode Info */}
              <div className="flex-1 p-3 flex flex-col justify-between min-w-0">
                <div>
                  <div className="flex items-center gap-2">
                    <span className={`px-2 py-0.5 rounded-full text-xs font-medium
                                 ${isActive ? 'bg-gold-500 text-black' : 'bg-white/10 text-white/60'}`}>
                      EP {episode.episode_number}
                    </span>
                    {isActive && (
                      <span className="text-xs text-gold-500 flex items-center gap-1">
                        <div className="w-1.5 h-1.5 rounded-full bg-gold-500 animate-pulse" />
                        Now Playing
                      </span>
                    )}
                  </div>
                  <h4 className="text-sm font-medium text-white mt-1 line-clamp-1">
                    {episode.name}
                  </h4>
                </div>

                <div className="flex items-center gap-3 mt-2">
                  <div className="flex items-center gap-1.5">
                    <Clock className="w-3.5 h-3.5 text-white/40" />
                    <span className="text-xs text-white/40">{episode.runtime || 45}min</span>
                  </div>
                  {episode.vote_average > 0 && (
                    <div className="flex items-center gap-1.5">
                      <Star className="w-3.5 h-3.5 text-gold-500" />
                      <span className="text-xs text-white/40">{episode.vote_average.toFixed(1)}</span>
                    </div>
                  )}
                </div>
              </div>
            </motion.button>
          );
        })}
      </div>
    </div>
  );
};

export default MobileEpisodeSelector;
