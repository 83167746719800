import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Toaster, toast } from 'react-hot-toast';
import { 
  FaBalanceScale, 
  FaFileContract, 
  FaCheck,
  FaExclamationTriangle,
  FaCopyright,
  FaEnvelope
} from 'react-icons/fa';

const DMCA = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const DMCA_VERSION = '1.0';

  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 50 
    },
    visible: {
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  // DMCA Sections
  const dmcaSections = [
    {
      title: "Copyright Infringement Notification",
      icon: FaCopyright,
      description: "If you believe that your copyrighted work has been used or displayed on Streamify without proper authorization, you may submit a formal DMCA takedown notice."
    },
    {
      title: "Notice Requirements",
      icon: FaFileContract,
      description: "Your DMCA notice must include a detailed description of the copyrighted work, its location, contact information, and a statement of good faith belief of infringement."
    },
    {
      title: "Counter-Notification",
      icon: FaBalanceScale,
      description: "If you believe your content was wrongly removed, you may submit a counter-notification explaining why you believe the original takedown was incorrect."
    }
  ];

  // Handle DMCA Acceptance
  const handleAcceptDMCA = () => {
    if (isAccepted) return;

    // Mark DMCA policy as accepted
    localStorage.setItem('dmcaAccepted', 'true');
    localStorage.setItem('dmcaAcceptedVersion', DMCA_VERSION);
    
    // Show success toast
    toast.success('DMCA Policy Understood', {
      icon: <FaCheck className="text-green-500 text-xl" />,
      style: {
        background: '#2C3E50',
        color: '#ECF0F1',
        border: '2px solid #b8962e',
        borderRadius: '12px',
        padding: '16px',
      },
      duration: 2000,
    });

    // Set accepted state
    setIsAccepted(true);
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="relative bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6 overflow-hidden"
    >
      {/* Toast Notification */}
      <Toaster 
        position="top-right"
        reverseOrder={false}
      />

      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>

      {/* Animated Header */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 100, 
          damping: 10 
        }}
        className="text-center mb-12 relative z-10 mt-14"
      >
        <motion.h1 
          className="text-5xl font-bold mb-4 text-gold-500 tracking-wider"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ 
            type: "spring", 
            stiffness: 300, 
            damping: 10 
          }}
        >
          DMCA Policy
        </motion.h1>
        <motion.p 
          className="max-w-2xl mx-auto text-gold-300 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Protecting Intellectual Property at <span className="font-bold text-gold-500">Streamify</span>
        </motion.p>
      </motion.div>

      {/* Animated Sections */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-4xl mx-auto space-y-8 relative z-10"
      >
        {dmcaSections.map((section, index) => (
          <motion.div 
            key={index}
            variants={itemVariants}
            whileHover={{ 
              scale: 1.03,
              transition: { duration: 0.2 }
            }}
            className="
              bg-zinc-800/50 
              border border-gold-500/20 
              rounded-xl 
              p-6 
              shadow-lg 
              hover:shadow-2xl 
              transition-all 
              duration-300
            "
          >
            <div className="flex items-center mb-4">
              <section.icon className="text-gold-500 text-3xl mr-3" />
              <h2 className="text-xl font-semibold text-gold-500">{section.title}</h2>
            </div>
            <p className="text-gray-300">{section.description}</p>
          </motion.div>
        ))}

        {/* Contact Information Section */}
        <motion.div
          variants={itemVariants}
          className="
            bg-zinc-800/50 
            border border-gold-500/20 
            rounded-xl 
            p-6 
            shadow-lg 
            transition-all 
            duration-300
          "
        >
          <div className="flex items-center mb-4">
            <FaEnvelope className="text-gold-500 text-3xl mr-3" />
            <h2 className="text-xl font-semibold text-gold-500">Contact Information</h2>
          </div>
          <div className="text-gray-300">
            <p className="mb-4">
              For DMCA-related inquiries or to submit a copyright infringement notice, please contact us at:
            </p>
            <div className="bg-background/30 p-4 rounded-lg">
              <p className="flex items-center">
                <FaEnvelope className="mr-2 text-gold-500" />
                <span className="font-bold text-gold-400">dmca@streamify.com</span>
              </p>
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Footer Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 100, 
          delay: 0.5 
        }}
        className="text-center mt-12 max-w-2xl mx-auto relative z-10"
      >
        <motion.button
          onClick={handleAcceptDMCA}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isAccepted}
          className={`
            bg-gold-500 
            text-black 
            px-8 py-3 
            rounded-full 
            font-bold 
            text-lg
            hover:bg-gold-600
            transition-all
            duration-300
            shadow-lg
            hover:shadow-xl
            ${isAccepted ? 'opacity-50 cursor-not-allowed' : ''}
          `}
        >
          {isAccepted ? 'Understood' : 'I Understand'}
        </motion.button>
        <p className="text-gold-300 mt-4 text-sm">
          By clicking "I Understand", you acknowledge the DMCA Policy
        </p>
      </motion.div>
    </motion.div>
  );
};

export default DMCA;