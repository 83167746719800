import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSearch, FaKeyboard } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import SearchModal from './SearchModal';
import useSearchShortcuts from '../hooks/useSearchShortcuts';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const searchInputRef = useRef(null);
  const location = useLocation();

  // Create a callback for toggling the search modal
  const toggleSearchModal = useCallback((forcedState) => {
    setShowSearchModal(prev => typeof forcedState === 'boolean' ? forcedState : !prev);
  }, []);

  // Use our custom hook for search shortcuts
  useSearchShortcuts(toggleSearchModal, showSearchModal);

  // Function to check if a nav item is active
  const isActive = useCallback((path) => {
    // Special case for home route
    if (path === '/home' && (location.pathname === '/' || location.pathname === '/home')) {
      return true;
    }
    
    // For other routes, check if the current path starts with the nav item path
    return location.pathname.startsWith(path);
  }, [location.pathname]);

  // Navigation items with active state styling
  const navigationItems = useMemo(() => [
    { path: '/home', label: 'Home' },
    { path: '/movies', label: 'Movies' },
    { path: '/tv', label: 'TV Shows' },
    { path: '/anime', label: 'Anime' },
    { path: '/filter', label: 'Filter' },
    { path: '/watchlist', label: 'Watchlist'}
  ], []);

  // Dropdown Menu Animation Variants
  const menuVariants = {
    hidden: { 
      opacity: 0, 
      y: -20,
      scale: 0.95
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  // Active indicator animation variants
  const activeIndicatorVariants = {
    inactive: {
      width: 0,
      opacity: 0
    },
    active: {
      width: '100%',
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 26
      }
    }
  };

  return (
    <>
      <div className="hidden md:flex w-full justify-center px-2 sm:px-4 lg:px-6 fixed top-0 left-0 z-50">
        <nav className={`
          mt-2 sm:mt-3 md:mt-4 w-full 
          max-w-[95%] sm:max-w-[90%] md:max-w-7xl
          bg-background/40 backdrop-blur-md 
          text-gold-300
          p-2 sm:p-3 md:p-4
          flex flex-wrap justify-between 
          items-center
          rounded-xl sm:rounded-2xl
          border border-gold-500/10
          shadow-lg shadow-black/20
          transition-all duration-300
          hover:border-gold-500/20 hover:bg-background/50
        `}>
          {/* Left Section - Updated for better mobile handling */}
          <div className="flex items-center justify-between w-full md:w-auto gap-2 sm:gap-4">
            {/* Hamburger - Adjusted for touch targets */}
            <motion.button
              onClick={() => setIsOpen(!isOpen)}
              className="
                text-gold-500 p-1.5 sm:p-2 
                rounded-lg focus:outline-none 
                transform flex flex-col 
                justify-center items-center 
                space-y-1 hover:text-gold-600
                md:hidden
              "
              initial={false}
              animate={isOpen ? "open" : "closed"}
            >
              <motion.div
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: 45, y: 8 }
                }}
                transition={{ 
                  duration: 0.1,
                  type: "tween",
                  ease: "easeOut"
                }}
                className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
              />
              <motion.div
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 }
                }}
                transition={{ 
                  duration: 0.1,
                  ease: "linear"
                }}
                className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
              />
              <motion.div
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: -45, y: -8 }
                }}
                transition={{ 
                  duration: 0.1,
                  type: "tween",
                  ease: "easeOut"
                }}
                className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
              />
            </motion.button>

            {/* Logo - Responsive text size */}
            <Link 
              to="/" 
              className="
                text-gold-500 font-bold 
                text-xl sm:text-2xl md:text-2xl
                hover:text-gold-400 
                transition-colors duration-300
              "
            >
              Streamify
            </Link>
          </div>

          {/* Navigation Links - Desktop with Active State */}
          <div className="hidden md:flex items-center space-x-6 mx-4">
            {navigationItems.map((item) => {
              const active = isActive(item.path);
              return (
                <div key={item.path} className="relative flex flex-col items-center">
                  <Link 
                    to={item.path} 
                    className={`
                      relative z-10
                      px-2 py-1
                      text-sm lg:text-base
                      transition-all duration-300
                      ${active 
                        ? 'text-gold-400 font-medium' 
                        : 'text-gold-500/70 hover:text-gold-400'}
                    `}
                  >
                    {item.label}
                  </Link>
                  
                  {/* Premium Active Indicator - Animated Underline */}
                  <motion.div
                    className="absolute bottom-[-5px] left-0 h-[3px] bg-gradient-to-r from-gold-500 to-amber-300 rounded-full shadow-gold"
                    initial="inactive"
                    animate={active ? "active" : "inactive"}
                    variants={activeIndicatorVariants}
                  />
                </div>
              );
            })}
          </div>

          {/* Mobile Menu Dropdown - Improved positioning with Active State */}
          <AnimatePresence>
            {isOpen && (
              <motion.div 
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={menuVariants}
                className="
                  absolute top-full mt-2
                  left-2 right-2 sm:left-4 sm:right-auto
                  bg-background/90 backdrop-blur-lg 
                  text-gold-300 rounded-xl
                  shadow-2xl sm:w-56 py-2 z-40
                  border border-gold-500/20
                  md:hidden
                "
              >
                {navigationItems.map((item) => {
                  const active = isActive(item.path);
                  return (
                    <Link 
                      key={item.path}
                      to={item.path} 
                      className={`
                        relative block px-4 py-2 
                        transition-colors
                        ${active 
                          ? 'bg-gold-500/20 text-gold-300 font-medium' 
                          : 'hover:bg-gold-500/10 hover:text-gold-400'}
                      `}
                    >
                      <div className="flex items-center">
                        {/* Active dot indicator for mobile */}
                        {active && (
                          <motion.div 
                            className="absolute left-1 top-1/2 -translate-y-1/2 w-1.5 h-1.5 rounded-full bg-gold-500"
                            layoutId="activeMobileDot"
                            transition={{
                              type: "spring",
                              stiffness: 300,
                              damping: 30
                            }}
                          />
                        )}
                        <span className={active ? 'pl-2' : ''}>{item.label}</span>
                      </div>
                    </Link>
                  );
                })}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Search Form - Now just a button that opens the modal */}
          <div 
            className="
              relative order-last md:order-none 
              w-full md:w-auto md:flex-1
              max-w-2xl mx-auto
              mt-2 md:mt-0 
              px-2 sm:px-4
            "
          >
            <motion.div 
              onClick={() => toggleSearchModal(true)}
              className="
                flex items-center 
                bg-background/40 text-black 
                rounded-lg sm:rounded-xl
                px-2 sm:px-4 py-1.5 sm:py-2
                shadow-sm border border-gold-500/20 
                backdrop-blur-sm
                transition-all duration-300
                hover:border-gold-500/30
                md:mx-4 lg:mx-8
                cursor-pointer
                navbar-search-trigger
              "
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ 
                type: "spring", 
                stiffness: 300, 
                damping: 20 
              }}
            >
              <FaSearch className="mr-2 text-gold-600 text-sm sm:text-base" />
              <div className="bg-transparent text-gold-500 placeholder-gray-500 w-full text-sm sm:text-base opacity-70">
                Search movies, shows and more...
              </div>
              
              {/* Shortcut Indicator - Hide on mobile */}
              <div className="hidden sm:flex items-center ml-2 bg-gold-500/20 text-gold-400 px-2 py-1 rounded-md text-xs font-semibold">
                <FaKeyboard className="mr-1" />
                Ctrl+K
              </div>
            </motion.div>
          </div>

          {/* Login Button - Responsive styling */}
          <Link 
            to="/login" 
            className="
              hidden md:flex items-center
              bg-background/40 border border-gold-500/20
              text-gold-500 rounded-lg sm:rounded-xl
              px-4 sm:px-6 py-1.5 sm:py-2
              text-sm sm:text-base
              hover:bg-gold-500 hover:text-black 
              transition-all duration-300
              shadow-sm
              whitespace-nowrap
            "
          >
            Login →
          </Link>

          {/* Mobile Login Button */}
          <Link 
            to="/login" 
            className="
              md:hidden
              bg-background/40 border border-gold-500/20
              text-gold-500 rounded-lg
              px-3 py-1.5
              text-sm
              hover:bg-gold-500 hover:text-black 
              transition-all duration-300
              shadow-sm
              whitespace-nowrap
            "
          >
            Login
          </Link>
        </nav>
      </div>

      {/* Search Modal Component */}
      <SearchModal 
        isOpen={showSearchModal} 
        onClose={() => toggleSearchModal(false)}
      />
    </>
  );
};

export default NavBar;

