import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, X, Star, Command, Clock, Loader2, Tv, Film, PlaySquare, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const getTypeIcon = (type) => {
  switch (type.toLowerCase()) {
    case 'tv':
      return <Tv className="w-3.5 h-3.5" />;
    case 'movie':
      return <Film className="w-3.5 h-3.5" />;
    case 'ova':
      return <PlaySquare className="w-3.5 h-3.5" />;
    default:
      return <Sparkles className="w-3.5 h-3.5" />;
  }
};

export const SearchBar = ({ 
  onSearch, 
  suggestions, 
  isLoading, 
  error,
  initialValue = '',
  onSuggestionClick 
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef(null);

  const handleInputChange = (value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleSuggestionSelect = (id) => {
    if (onSuggestionClick) {
      onSuggestionClick(id);
    } else {
      navigate(`/anime/${id}`);
      setSearchQuery('');
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
      if (e.key === 'Escape') {
        searchInputRef.current?.blur();
        setSearchQuery('');
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Update search query when initialValue changes
  useEffect(() => {
    setSearchQuery(initialValue);
  }, [initialValue]);

  return (
    <div className="relative w-full">
      {/* Search Input */}
      <div className={`
        relative flex items-center
        bg-[#0a0a0f]/40 backdrop-blur-xl
        rounded-2xl transition-all duration-300
        ${isFocused 
          ? 'shadow-[0_0_20px_rgba(99,102,241,0.3)] border border-[#6366f1]/50' 
          : 'shadow-lg shadow-black/10 border border-[#6366f1]/10 hover:border-[#6366f1]/30'
        }
      `}>
        {isLoading ? (
          <Loader2 className="w-5 h-5 ml-4 animate-spin text-primary" />
        ) : (
          <Search className={`
            w-5 h-5 ml-4 transition-colors duration-300
            ${isFocused ? 'text-[#6366f1]' : 'text-gray-400'}
          `} />
        )}
        <input
          ref={searchInputRef}
          type="text"
          value={searchQuery}
          onChange={(e) => handleInputChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder="Search by title, genre, or year..."
          className="w-full px-4 py-4 bg-transparent text-white placeholder-gray-400 
            focus:outline-none text-sm font-medium"
        />
        {searchQuery && (
          <button
            onClick={() => handleInputChange('')}
            className="p-2 mr-2 hover:bg-[#6366f1]/10 rounded-lg transition-colors"
          >
            <X className="w-4 h-4 text-gray-400 hover:text-[#6366f1]" />
          </button>
        )}
        
        <div className="hidden md:flex items-center gap-1 mr-4 px-2 py-1.5 rounded-lg 
          bg-[#6366f1]/5 border border-[#6366f1]/10">
          <Command className="w-3.5 h-3.5 text-[#6366f1]/70" />
          <span className="text-xs font-medium text-[#6366f1]/70">K</span>
        </div>

        {error && (
          <div className="absolute top-full mt-2 w-full p-3 bg-red-500/10 border border-red-500/20 
            rounded-lg text-red-500 text-sm">
            {error}
          </div>
        )}
      </div>

      {/* Enhanced Search Results */}
      <AnimatePresence>
        {searchQuery && !error && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute w-full mt-3 bg-[#0a0a1f]/95 backdrop-blur-xl rounded-2xl 
              border border-[#6366f1]/20 shadow-[0_8px_32px_rgba(99,102,241,0.15)]
              overflow-hidden z-50"
          >
            {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <Loader2 className="w-6 h-6 animate-spin text-primary" />
              </div>
            ) : suggestions.length > 0 ? (
              <div className="max-h-[450px] overflow-y-auto scrollbar-thin divide-y divide-white/5">
                {suggestions.map((suggestion, index) => (
                  <motion.div
                    key={suggestion.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.05 }}
                    onClick={() => handleSuggestionSelect(suggestion.id)}
                    className="group relative cursor-pointer"
                  >
                    <div className="relative flex items-start gap-4 p-4 hover:bg-[#6366f1]/5
                      transition-all duration-300"
                    >
                      {/* Enhanced Poster */}
                      <div className="relative flex-shrink-0 w-[72px] h-[96px]">
                        <div className="absolute inset-0 rounded-lg overflow-hidden">
                          <img
                            src={suggestion.poster}
                            alt={suggestion.name}
                            className="w-full h-full object-cover transition-transform duration-500
                              group-hover:scale-110"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent 
                            opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                        </div>
                        
                        {/* Poster Border Glow */}
                        <div className="absolute -inset-[1px] rounded-lg bg-gradient-to-br from-primary/50 via-secondary/50 to-accent/50 
                          opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-[-1]" />
                      </div>

                      {/* Enhanced Info */}
                      <div className="flex-1 min-w-0 space-y-2">
                        <div className="space-y-1">
                          <h3 className="font-medium text-white truncate group-hover:text-[#6366f1]
                            transition-colors duration-300">
                            {suggestion.name}
                          </h3>
                          <p className="text-sm text-[#8b5cf6]/80 truncate">
                            {suggestion.jname}
                          </p>
                        </div>
                        
                        {/* Enhanced Tags */}
                        <div className="flex flex-wrap gap-2">
                          {suggestion.moreInfo.map((info, idx) => (
                            <span
                              key={idx}
                              className="inline-flex items-center gap-1.5 px-2.5 py-1 rounded-lg text-xs
                                font-medium transition-colors duration-300"
                              style={{
                                backgroundColor: `rgba(${
                                  idx === 0 ? '99,102,241' : 
                                  idx === 1 ? '139,92,246' : '217,70,239'
                                }, 0.1)`,
                                color: idx === 0 ? '#6366f1' : 
                                       idx === 1 ? '#8b5cf6' : '#d946ef'
                              }}
                            >
                              {idx === 0 && <Clock className="w-3.5 h-3.5" />}
                              {idx === 1 && getTypeIcon(info)}
                              {idx === 2 && <Star className="w-3.5 h-3.5" />}
                              <span className="truncate max-w-[100px]">{info}</span>
                            </span>
                          ))}
                        </div>
                      </div>

                      {/* Enhanced Hover Indicator */}
                      <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b 
                        from-primary via-secondary to-accent scale-y-0 group-hover:scale-y-100 
                        transition-transform duration-300 origin-top" />
                    </div>
                  </motion.div>
                ))}
              </div>
            ) : (
              <div className="p-8 text-center text-gray-400">
                No results found
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
