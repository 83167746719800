import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchActorDetails, fetchActorCredits } from '../services/api';
import { 
  Calendar, 
  MapPin, 
  Star, 
  Film, 
  Tv, 
  ChevronDown,
  Instagram,
  Twitter,
  Facebook,
  Globe,
  User2
} from 'lucide-react';

const ActorPage = () => {
  const { id } = useParams();
  const [actor, setActor] = useState(null);
  const [credits, setCredits] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isFullBiographyVisible, setIsFullBiographyVisible] = useState(false);
  const [showScrollHint, setShowScrollHint] = useState(true);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [actorData, creditsData] = await Promise.all([
          fetchActorDetails(id),
          fetchActorCredits(id)
        ]);
        
        // Process actor data
        setActor({
          ...actorData,
          biography: actorData.biography || 'No biography available.',
          profile_path: actorData.profile_path || null,
          known_for_department: actorData.known_for_department || 'Acting'
        });

        // Process and deduplicate credits
        const uniqueCredits = Object.values(
          creditsData.cast.reduce((acc, curr) => {
            if (!acc[curr.id] && curr.poster_path) {
              acc[curr.id] = {
                ...curr,
                media_type: curr.media_type || (curr.first_air_date ? 'tv' : 'movie')
              };
            }
            return acc;
          }, {})
        ).sort((a, b) => {
          const dateA = new Date(a.release_date || a.first_air_date || '0');
          const dateB = new Date(b.release_date || b.first_air_date || '0');
          return dateB - dateA;
        });

        setCredits({ cast: uniqueCredits });
        setIsContentLoading(false);
      } catch (error) {
        console.error('Error fetching actor data:', error);
        setCredits({ cast: [] });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Early return conditions
  if (isLoading) {
    return (
      <div className="min-h-screen bg-background/95 flex items-center justify-center pt-20">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-gold-500 border-t-transparent rounded-full animate-spin mb-4" />
          <p className="text-gold-500">Loading actor details...</p>
        </div>
      </div>
    );
  }

  if (!actor) {
    return (
      <div className="min-h-screen bg-background/95 flex items-center justify-center pt-20">
        <div className="text-center">
          <User2 className="w-16 h-16 text-gold-500 mb-4" />
          <h2 className="text-xl text-white mb-2">Actor Not Found</h2>
          <p className="text-gray-400">This actor profile is not available.</p>
        </div>
      </div>
    );
  }

  // Filter credits based on active tab
  const filteredCredits = credits?.cast.filter(credit => {
    if (activeTab === 'all') return true;
    return credit.media_type === activeTab;
  }) || [];

  const socialLinks = {
    instagram: actor.external_ids?.instagram_id ? `https://instagram.com/${actor.external_ids.instagram_id}` : null,
    twitter: actor.external_ids?.twitter_id ? `https://twitter.com/${actor.external_ids.twitter_id}` : null,
    facebook: actor.external_ids?.facebook_id ? `https://facebook.com/${actor.external_ids.facebook_id}` : null,
    website: actor.homepage || null
  };

  const renderCreditsGrid = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4"
    >
      {filteredCredits.map((credit, index) => (
        <motion.div
          key={`${credit.id}-${credit.media_type}`}
          variants={itemVariants}
          layout
          className="relative"
        >
          <Link 
            to={`/details/${credit.media_type}/${credit.id}`}
            className="block group"
          >
            <div className="relative aspect-[2/3] rounded-xl overflow-hidden bg-zinc-900">
              <img
                src={`https://image.tmdb.org/t/p/w500${credit.poster_path}`}
                alt={credit.title || credit.name}
                className="w-full h-full object-cover"
              />
              
              <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent 
                            opacity-0 group-hover:opacity-100 transition-all duration-300">
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h3 className="text-sm font-semibold text-white mb-1 line-clamp-2">
                    {credit.title || credit.name}
                  </h3>
                  {credit.character && (
                    <p className="text-xs text-gray-400 mb-2">as {credit.character}</p>
                  )}
                  <div className="flex items-center gap-2">
                    {credit.media_type === 'movie' ? (
                      <Film className="w-4 h-4 text-gold-500" />
                    ) : (
                      <Tv className="w-4 h-4 text-gold-500" />
                    )}
                    <span className="text-xs text-gold-400">
                      {new Date(credit.release_date || credit.first_air_date).getFullYear() || 'TBA'}
                    </span>
                    {credit.vote_average > 0 && (
                      <div className="flex items-center gap-1">
                        <Star className="w-4 h-4 text-gold-500" />
                        <span className="text-xs text-gold-400">
                          {credit.vote_average.toFixed(1)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </motion.div>
      ))}
    </motion.div>
  );

  const renderLoadingSkeleton = () => (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
      {[...Array(12)].map((_, i) => (
        <div key={i} className="animate-pulse">
          <div className="bg-zinc-800/50 aspect-[2/3] rounded-xl"></div>
        </div>
      ))}
    </div>
  );

  const renderActorInfo = () => (
    <div className="relative">
      {/* Background Image with Blur */}
      <div className="absolute inset-0 opacity-20">
        <img 
          src={`https://image.tmdb.org/t/p/original${actor.profile_path}`}
          alt=""
          className="w-full h-full object-cover filter blur-xl"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/80 to-transparent" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-8 py-8">
            {/* Left Column - Image and Stats */}
            <div className="w-full lg:w-[380px] shrink-0 space-y-6">
              {/* Profile Image Card */}
              <motion.div 
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                className="rounded-2xl overflow-hidden shadow-2xl border border-white/10 
                          bg-background/40 backdrop-blur-md group relative"
              >
                <div className="relative aspect-[3/4]">
                  <img 
                    src={`https://image.tmdb.org/t/p/original${actor.profile_path}`}
                    alt={actor.name}
                    className="w-full h-full object-cover transition-transform duration-500 
                             group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
                </div>

                {/* Hover Info Overlay */}
                <div className="absolute inset-0 bg-background/80 opacity-0 group-hover:opacity-100 
                              transition-opacity duration-300 flex flex-col justify-end p-6">
                  <div className="space-y-4">
                    {actor.also_known_as?.length > 0 && (
                      <div>
                        <h4 className="text-gold-500 text-sm font-medium mb-2">Also Known As</h4>
                        <div className="flex flex-wrap gap-2">
                          {actor.also_known_as.slice(0, 3).map((name, index) => (
                            <span key={index} className="text-xs bg-white/10 px-2 py-1 rounded-full text-white">
                              {name}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {actor.birthday && (
                      <div>
                        <h4 className="text-gold-500 text-sm font-medium mb-2">Age</h4>
                        <span className="text-white">
                          {calculateAge(actor.birthday, actor.deathday)} years old
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>

              {/* Quick Stats Panel */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-background/40 backdrop-blur-md rounded-xl border border-white/10 
                           overflow-hidden hover:border-gold-500/30 transition-all duration-300"
              >
                <div className="p-6 space-y-6">
                  <div className="flex items-center gap-3">
                    <div className="p-2 rounded-full bg-gold-500/10">
                      <Star className="w-5 h-5 text-gold-500" />
                    </div>
                    <h3 className="text-xl font-semibold text-white">Career Overview</h3>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <StatsCard
                      label="Movies"
                      value={credits.cast.filter(c => c.media_type === 'movie').length}
                      icon={<Film className="w-5 h-5 text-gold-500" />}
                    />
                    <StatsCard
                      label="TV Shows"
                      value={credits.cast.filter(c => c.media_type === 'tv').length}
                      icon={<Tv className="w-5 h-5 text-gold-500" />}
                    />
                    <StatsCard
                      label="Popularity"
                      value={actor.popularity?.toFixed(1)}
                      icon={<Star className="w-5 h-5 text-gold-500" />}
                    />
                    <StatsCard
                      label="Total Credits"
                      value={credits.cast.length}
                      icon={<Film className="w-5 h-5 text-gold-500" />}
                    />
                  </div>
                </div>
              </motion.div>

              {/* Social Links */}
              {Object.values(socialLinks).some(value => value) && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="bg-background/40 backdrop-blur-md rounded-xl border border-white/10 
                             overflow-hidden hover:border-gold-500/30 transition-all duration-300"
                >
                  <div className="p-6 space-y-6">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-full bg-gold-500/10">
                        <Globe className="w-5 h-5 text-gold-500" />
                      </div>
                      <h3 className="text-xl font-semibold text-white">Connect</h3>
                    </div>
                    <div className="grid gap-3">
                      {renderSocialLinks()}
                    </div>
                  </div>
                </motion.div>
              )}
            </div>

            {/* Right Column - Bio and Details */}
            <div className="flex-1 space-y-6">
              <div className="space-y-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <h1 className="text-4xl lg:text-5xl font-bold">
                    <span className="bg-gradient-to-r from-gold-400 to-gold-600 
                                   bg-clip-text text-transparent">
                      {actor.name}
                    </span>
                  </h1>
                  
                  {/* Personal Info Tags */}
                  <div className="flex flex-wrap gap-3 mt-4">
                    {renderPersonalInfoTags()}
                  </div>
                </motion.div>

                {/* Biography Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                  className="bg-background/40 backdrop-blur-md rounded-xl border border-white/10 overflow-hidden"
                >
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gold-400 mb-4 flex items-center gap-2">
                      <User2 className="w-5 h-5" />
                      Biography
                    </h3>
                    <BiographySection 
                      biography={actor.biography}
                      isExpanded={isFullBiographyVisible}
                      onToggle={() => setIsFullBiographyVisible(!isFullBiographyVisible)}
                    />
                  </div>
                </motion.div>

                {/* Career Highlights */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="bg-background/40 backdrop-blur-md rounded-xl border border-white/10 overflow-hidden"
                >
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gold-400 mb-4">Career Highlights</h3>
                    <CareerHighlights credits={credits.cast} />
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Helper Components
  const StatsCard = ({ label, value, icon }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="relative group bg-gradient-to-br from-white/5 to-white/0 
                 rounded-lg p-4 border border-white/10 backdrop-blur-sm
                 hover:border-gold-500/50 transition-all duration-300
                 hover:shadow-lg hover:shadow-gold-500/10"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-gold-500/0 to-gold-500/0 
                    group-hover:from-gold-500/10 group-hover:to-transparent
                    rounded-lg transition-all duration-300" />
      <div className="relative z-10 flex flex-col items-center gap-2">
        <div className="p-2 rounded-full bg-gold-500/10 group-hover:bg-gold-500/20 
                      transition-all duration-300">
          {icon}
        </div>
        <span className="text-2xl font-bold text-white group-hover:text-gold-500 
                        transition-colors duration-300">
          {value}
        </span>
        <span className="text-xs text-gray-400 group-hover:text-gray-300
                        transition-colors duration-300">
          {label}
        </span>
      </div>
    </motion.div>
  );

  const renderSocialLinks = () => {
    const socialButtonConfig = {
      instagram: {
        gradient: "from-purple-600 to-pink-500 hover:from-purple-500 hover:to-pink-400",
        hoverBg: "hover:shadow-purple-500/20",
        icon: <Instagram className="w-5 h-5" />,
        label: "Follow on Instagram"
      },
      twitter: {
        gradient: "from-blue-500 to-blue-400 hover:from-blue-400 hover:to-blue-300",
        hoverBg: "hover:shadow-blue-500/20",
        icon: <Twitter className="w-5 h-5" />,
        label: "Follow on Twitter"
      },
      facebook: {
        gradient: "from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400",
        hoverBg: "hover:shadow-blue-500/20",
        icon: <Facebook className="w-5 h-5" />,
        label: "Follow on Facebook"
      },
      website: {
        gradient: "from-gold-500 to-gold-400 hover:from-gold-400 hover:to-gold-300",
        hoverBg: "hover:shadow-gold-500/20",
        icon: <Globe className="w-5 h-5" />,
        label: "Visit Website"
      }
    };

    return Object.entries(socialLinks).map(([platform, url]) => {
      if (!url) return null;
      const config = socialButtonConfig[platform];
      
      return (
        <motion.a
          key={platform}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={`
            flex items-center gap-3 px-4 py-3 rounded-xl
            bg-gradient-to-br ${config.gradient}
            shadow-lg hover:shadow-xl ${config.hoverBg}
            transition-all duration-300
            ${platform === 'website' ? 'text-black' : 'text-white'}
          `}
        >
          <div className="p-2 rounded-full bg-white/20">
            {config.icon}
          </div>
          <div className="flex flex-col">
            <span className="text-xs opacity-80">
              {platform === 'website' ? 'Official' : 'Social'}
            </span>
            <span className="font-medium">
              {config.label}
            </span>
          </div>
        </motion.a>
      );
    }).filter(Boolean);
  };

  const BiographySection = ({ biography, isExpanded, onToggle }) => (
    <div className="space-y-4">
      <motion.div
        initial={false}
        animate={{ height: isExpanded ? 'auto' : '150px' }}
        className="relative overflow-hidden"
      >
        <p className="text-gray-300 leading-relaxed whitespace-pre-line">
          {biography}
        </p>
        {!isExpanded && (
          <div className="absolute bottom-0 left-0 right-0 h-24 
                         bg-gradient-to-t from-black to-transparent" />
        )}
      </motion.div>
      <button
        onClick={onToggle}
        className="flex items-center gap-2 text-gold-500 hover:text-gold-400"
      >
        {isExpanded ? 'Show Less' : 'Read More'}
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="w-4 h-4" />
        </motion.div>
      </button>
    </div>
  );

  const CareerHighlights = ({ credits }) => {
    const topRatedWorks = [...credits]
      .sort((a, b) => b.vote_average - a.vote_average)
      .slice(0, 3);

    return (
      <div className="grid gap-4">
        {topRatedWorks.map((work) => (
          <Link
            key={work.id}
            to={`/details/${work.media_type}/${work.id}`}
            className="flex gap-4 p-3 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
          >
            <img
              src={`https://image.tmdb.org/t/p/w92${work.poster_path}`}
              alt={work.title || work.name}
              className="w-16 h-24 object-cover rounded-md"
            />
            <div className="flex-1">
              <h4 className="text-white font-medium">{work.title || work.name}</h4>
              <p className="text-sm text-gray-400 mt-1">as {work.character}</p>
              <div className="flex items-center gap-2 mt-2">
                <Star className="w-4 h-4 text-gold-500" />
                <span className="text-gold-400">{work.vote_average.toFixed(1)}</span>
                <span className="text-gray-500">•</span>
                <span className="text-gray-400 text-sm">
                  {new Date(work.release_date || work.first_air_date).getFullYear()}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  // Helper function to calculate age
  const calculateAge = (birthday, deathday = null) => {
    const birthDate = new Date(birthday);
    const endDate = deathday ? new Date(deathday) : new Date();
    const age = endDate.getFullYear() - birthDate.getFullYear();
    const m = endDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && endDate.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  const renderFilmographySection = () => (
    <div className="container mx-auto px-4">
      <div className="bg-zinc-900/50 rounded-2xl p-6 backdrop-blur-xl border border-white/10">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gold-500">Filmography</h2>
            <p className="text-gray-400 text-sm mt-1">
              {credits.cast.length} Credits in Total
            </p>
          </div>
          
          {/* Enhanced Filter Tabs */}
          <div className="flex gap-2 bg-background/20 p-1 rounded-xl border border-white/10">
            {[
              { id: 'all', label: 'All', count: credits.cast.length },
              { id: 'movies', label: 'Movies', count: credits.cast.filter(c => c.media_type === 'movie').length },
              { id: 'tv', label: 'TV Shows', count: credits.cast.filter(c => c.media_type === 'tv').length }
            ].map(tab => (
              <motion.button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 rounded-lg text-sm transition-all relative
                  ${activeTab === tab.id ? 'text-black' : 'text-gray-400 hover:text-white'}`}
              >
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTabBackground"
                    className="absolute inset-0 bg-gold-500 rounded-lg -z-10"
                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                  />
                )}
                <span className="relative z-10">{tab.label} ({tab.count})</span>
              </motion.button>
            ))}
          </div>
        </div>

        <AnimatePresence mode="wait">
          {isContentLoading ? (
            <motion.div
              key="skeleton"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {renderLoadingSkeleton()}
            </motion.div>
          ) : (
            <motion.div
              key="grid"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {renderCreditsGrid()}
            </motion.div>
          )}
        </AnimatePresence>

        {/* No Results Message */}
        {!isContentLoading && filteredCredits.length === 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center py-12"
          >
            <div className="inline-block p-4 rounded-full bg-gold-500/10 mb-4">
              <Film className="w-6 h-6 text-gold-500" />
            </div>
            <h3 className="text-white font-medium mb-2">No {activeTab} Found</h3>
            <p className="text-gray-400 text-sm">
              {actor.name} has no {activeTab === 'all' ? 'credited roles' : activeTab} available.
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );

  const renderPersonalInfoTags = () => {
    const tags = [];

    if (actor.birthday) {
      tags.push({
        icon: <Calendar className="w-4 h-4 text-gold-500" />,
        label: 'Born',
        value: new Date(actor.birthday).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      });
    }

    if (actor.deathday) {
      tags.push({
        icon: <Calendar className="w-4 h-4 text-gold-500" />,
        label: 'Died',
        value: new Date(actor.deathday).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      });
    }

    if (actor.place_of_birth) {
      tags.push({
        icon: <MapPin className="w-4 h-4 text-gold-500" />,
        label: 'From',
        value: actor.place_of_birth
      });
    }

    if (actor.known_for_department) {
      tags.push({
        icon: <Film className="w-4 h-4 text-gold-500" />,
        label: 'Known for',
        value: actor.known_for_department
      });
    }

    return (
      <div className="flex flex-wrap gap-3">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center gap-2 bg-background/40 backdrop-blur-sm 
                     px-3 py-2 rounded-lg border border-white/10"
          >
            {tag.icon}
            <span className="text-sm text-gray-400">{tag.label}:</span>
            <span className="text-sm text-white">{tag.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-zinc-900 to-black">
      <div className="pt-20 pb-12 space-y-8">
        {renderActorInfo()}
        {renderFilmographySection()}
      </div>
    </div>
  );
};

export default ActorPage;
