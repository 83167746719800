import { useState } from 'react';

const API_BASE = process.env.REACT_APP_ANI_API;

export const useAnimeInfo = () => {
  const [error, setError] = useState(null);

  const getAnimeInfo = async (id) => {
    try {
      const response = await fetch(`${API_BASE}/anime/${id}`);
      const data = await response.json();
      return data;
    } catch {
      setError('Failed to fetch anime details');
      return null;
    }
  };

  return { getAnimeInfo, error };
};
