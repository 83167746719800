import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {  
  FaSearch, 
  FaStar, 
  FaTimes,
  FaHistory,
  FaArrowRight,
  FaTrophy,
  FaFilter
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { getSearchSuggestions } from '../services/search';
import { fetchGenres } from '../services/api';

const SearchModal = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [recentSearches, setRecentSearches] = useState(() => {
    const saved = localStorage.getItem('recentSearches');
    return saved ? JSON.parse(saved) : [];
  });
  const [genreMap, setGenreMap] = useState({});
  
  const navigate = useNavigate();
  const modalSearchInputRef = useRef(null);
  const searchContainerRef = useRef(null);

  // Save recent searches to localStorage
  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }, [recentSearches]);

  // Handle clicks outside the search modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current && 
        !searchContainerRef.current.contains(event.target) && 
        !event.target.closest('.navbar-search-trigger')
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Keep the Escape key handler but remove the Ctrl+K handler since it's now in NavBar
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Close modal on Escape key
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
      
      // Handle arrow navigation in search results
      if (isOpen && suggestions.length > 0) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedSuggestionIndex(prev => 
            prev < suggestions.length - 1 ? prev + 1 : 0
          );
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedSuggestionIndex(prev => 
            prev > 0 ? prev - 1 : suggestions.length - 1
          );
        } else if (e.key === 'Enter' && selectedSuggestionIndex >= 0) {
          e.preventDefault();
          handleSuggestionClick(
            suggestions[selectedSuggestionIndex].id, 
            suggestions[selectedSuggestionIndex].media_type
          );
        }
      }
    };

    // Only add the event listener when the modal is open
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, suggestions, selectedSuggestionIndex]);

  // Focus the modal search input when the modal opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        modalSearchInputRef.current?.focus();
      }, 100);
    } else {
      // Reset search state when modal closes
      setSearchQuery('');
      setSuggestions([]);
      setSelectedSuggestionIndex(-1);
    }
  }, [isOpen]);

  // Handle body scroll lock when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  // Fetch genres on component mount
  useEffect(() => {
    const loadGenres = async () => {
      const genres = await fetchGenres();
      const genreMapping = {};
      
      genres.forEach(genre => {
        genreMapping[genre.id] = genre.name;
      });
      
      setGenreMap(genreMapping);
    };
    
    loadGenres();
  }, []);

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSelectedSuggestionIndex(-1);
    
    if (query) {
      const results = await getSearchSuggestions(query);
      setSuggestions(results.slice(0, 7)); // Limit suggestions to 7
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (id, mediaType) => {
    // Add to recent searches
    const searchItem = suggestions.find(item => item.id === id);
    if (searchItem) {
      const newSearch = {
        id: searchItem.id,
        title: searchItem.title || searchItem.name,
        media_type: searchItem.media_type,
        timestamp: new Date().getTime()
      };
      
      setRecentSearches(prev => {
        const filtered = prev.filter(item => item.id !== newSearch.id);
        return [newSearch, ...filtered].slice(0, 5);
      });
    }
    
    if (mediaType === 'person') {
      navigate(`/actor/${id}`);
    } else {
      navigate(`/details/${mediaType}/${id}`);
    }
    
    // Close modal and reset state
    onClose();
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Add search query to recent searches
      const newSearch = {
        id: `search-${Date.now()}`,
        title: searchQuery,
        media_type: 'search',
        timestamp: new Date().getTime()
      };
      
      setRecentSearches(prev => {
        const filtered = prev.filter(item => item.title !== searchQuery);
        return [newSearch, ...filtered].slice(0, 5);
      });
      
      navigate(`/search?query=${searchQuery}`);
      onClose();
    }
  };

  const handleClearRecentSearches = () => {
    setRecentSearches([]);
  };

  const handleRecentSearchClick = (search) => {
    if (search.media_type === 'search') {
      navigate(`/search?query=${search.title}`);
    } else if (search.media_type === 'person') {
      navigate(`/actor/${search.id}`);
    } else {
      navigate(`/details/${search.media_type}/${search.id}`);
    }
    onClose();
  };

  // Search Modal Animation Variants
  const modalVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.9,
      y: -20
    },
    visible: { 
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300
      }
    },
    exit: { 
      opacity: 0,
      scale: 0.9,
      y: -20,
      transition: {
        duration: 0.2
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.2 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.2 }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Modal Overlay */}
          <motion.div 
            className="fixed inset-0 bg-black/80 backdrop-blur-md z-50"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            onClick={onClose}
          />

          {/* Search Modal Container */}
          <motion.div 
            ref={searchContainerRef}
            className="fixed inset-x-0 mx-auto top-[10vh] w-full max-w-4xl z-50 px-4 sm:px-6 lg:px-8 search-modal-container"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
          >
            <div className="bg-gradient-to-b from-background via-background/95 to-background/90 rounded-2xl shadow-2xl border border-gold-500/30 overflow-hidden">
              {/* Search Header */}
              <div className="border-b border-gold-500/20 p-4 pb-3 sticky top-0 bg-background z-10">
                <form onSubmit={handleSearchSubmit} className="relative">
                  <div className="flex items-center bg-background/60 rounded-xl border border-gold-500/30 px-4 py-3 focus-within:ring-2 focus-within:ring-gold-500/50 transition-shadow">
                    <FaSearch className="text-gold-500 mr-3 text-xl" />
                    <input
                      ref={modalSearchInputRef}
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder="Search for movies, TV shows, people..."
                      className="bg-transparent text-gold-300 placeholder-gold-600/50 focus:outline-none w-full text-lg"
                    />
                    {searchQuery && (
                      <motion.button
                        type="button"
                        onClick={() => {
                          setSearchQuery('');
                          setSuggestions([]);
                          modalSearchInputRef.current?.focus();
                        }}
                        className="text-gold-400 hover:text-gold-300 p-1"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FaTimes />
                      </motion.button>
                    )}
                  </div>
                </form>
                
                {/* Search shortcuts and tips */}
                <div className="flex flex-wrap items-center gap-2 pt-3 text-xs text-gold-500/70">
                  <span className="bg-gold-500/10 px-2 py-1 rounded-md">
                    Press <kbd className="font-semibold">↑</kbd> <kbd className="font-semibold">↓</kbd> to navigate
                  </span>
                  <span className="bg-gold-500/10 px-2 py-1 rounded-md">
                    Press <kbd className="font-semibold">Enter</kbd> to select
                  </span>
                  <span className="bg-gold-500/10 px-2 py-1 rounded-md">
                    Press <kbd className="font-semibold">Esc</kbd> to close
                  </span>
                </div>
              </div>

              {/* Search Content */}
              <div className="max-h-[60vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gold-500/50 search-modal-content">
                <div className="p-3">
                  {/* Suggestions */}
                  {suggestions.length > 0 && (
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h3 className="text-gold-400 font-semibold px-2">Search Results</h3>
                        <span className="text-xs text-gold-500/70 bg-gold-500/10 px-2 py-0.5 rounded-full">
                          {suggestions.length} results
                        </span>
                      </div>
                      
                      <div className="space-y-2">
                        {suggestions.map((suggestion, index) => (
                          <motion.div
                            key={suggestion.id}
                            className={`
                              flex p-3 rounded-xl cursor-pointer
                              transition-all duration-200 ease-in-out
                              ${selectedSuggestionIndex === index ? 'bg-gold-500 text-zinc-900' : 'hover:bg-gold-500/20'}
                            `}
                            onClick={() => handleSuggestionClick(suggestion.id, suggestion.media_type)}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ 
                              opacity: 1, 
                              y: 0,
                              transition: { delay: index * 0.03 }
                            }}
                            whileHover={{ 
                              scale: 1.01,
                              backgroundColor: selectedSuggestionIndex === index ? 'rgba(216, 180, 54, 1)' : 'rgba(216, 180, 54, 0.2)'
                            }}
                            whileTap={{ scale: 0.98 }}
                          >
                            {/* Updated Poster Design - Removed badges */}
                            {(suggestion.poster_path || suggestion.profile_path) ? (
                              <div className="relative min-w-[5rem] w-20 h-28 mr-4 overflow-hidden rounded-lg shadow-md group">
                                <motion.img
                                  initial={{ scale: 1.1, opacity: 0 }}
                                  animate={{ scale: 1, opacity: 1 }}
                                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                                  src={`https://image.tmdb.org/t/p/w154${suggestion.profile_path || suggestion.poster_path}`}
                                  alt={suggestion.title || suggestion.name}
                                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div className={`absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent`}></div>
                              </div>
                            ) : (
                              <div className="min-w-[5rem] w-20 h-28 mr-4 bg-gradient-to-br from-gray-700 to-gray-900 rounded-lg flex items-center justify-center">
                                <span className="text-3xl text-gold-500/50 font-bold">
                                  {(suggestion.title || suggestion.name || '?').charAt(0)}
                                </span>
                              </div>
                            )}
                            
                            {/* Content Details - Reorganized layout */}
                            <div className="flex flex-col flex-grow">
                              {/* Title row with media type badge */}
                              <div className="flex items-center justify-between">
                                <div className="flex items-center flex-wrap gap-2">
                                  <span className={`font-semibold text-lg ${selectedSuggestionIndex === index ? 'text-zinc-900' : 'text-gold-300'}`}>
                                    {suggestion.title || suggestion.name}
                                  </span>
                                  
                                  {/* Media Type Badge next to title */}
                                  <span className={`
                                    px-2 py-0.5 rounded-full text-xs font-medium
                                    ${suggestion.media_type === 'movie' ? 'bg-blue-500/30 text-blue-300' :
                                    suggestion.media_type === 'tv' ? 'bg-green-500/30 text-green-300' : 
                                    'bg-purple-500/30 text-purple-300'}
                                    ${selectedSuggestionIndex === index ? 'opacity-80' : ''}
                                  `}>
                                    {suggestion.media_type === 'movie' ? 'Movie' :
                                    suggestion.media_type === 'tv' ? 'TV' : 'Person'}
                                  </span>
                                </div>
                                
                                <motion.button 
                                  whileHover={{ scale: 1.2, rotate: -10 }}
                                  whileTap={{ scale: 0.9 }}
                                  className={`${selectedSuggestionIndex === index ? 'text-zinc-900' : 'text-gold-400'} hover:text-gold-300`}
                                >
                                  <FaArrowRight />
                                </motion.button>
                              </div>

                              {/* Year and Rating row placed below title */}
                              <div className="flex items-center mt-1 space-x-3 text-xs">
                                {/* Release Year */}
                                {(suggestion.release_date || suggestion.first_air_date) && (
                                  <span className={selectedSuggestionIndex === index ? 'text-zinc-800 font-medium' : 'text-gold-500/70'}>
                                    {suggestion.release_date ? suggestion.release_date.split('-')[0] : 
                                     suggestion.first_air_date ? suggestion.first_air_date.split('-')[0] : ''}
                                  </span>
                                )}
                                
                                {/* Rating with star icon */}
                                {suggestion.vote_average > 0 && suggestion.media_type !== 'person' && (
                                  <div className={`flex items-center ${selectedSuggestionIndex === index ? 'text-zinc-800' : 'text-yellow-400'}`}>
                                    <FaStar className="mr-1 text-xs" />
                                    <span className="font-medium">
                                      {suggestion.vote_average.toFixed(1)}
                                    </span>
                                  </div>
                                )}
                              </div>
                              
                              {/* Description or Overview */}
                              {suggestion.short_overview && (
                                <p className={`text-xs mt-1 line-clamp-2 ${selectedSuggestionIndex === index ? 'text-zinc-800' : 'text-gold-500/70'}`}>
                                  {suggestion.short_overview}
                                </p>
                              )}
                              
                              {/* Genres Section */}
                              <div className="flex items-center mt-1.5">
                                {/* Person-specific info */}
                                {suggestion.media_type === 'person' && (
                                  <span className={`text-xs ${selectedSuggestionIndex === index ? 'text-zinc-900' : 'text-gold-500/70'}`}>
                                    {suggestion.known_for_department || 'Actor'}
                                  </span>
                                )}
                                
                                {/* Genre badges */}
                                {suggestion.genre_ids && suggestion.genre_ids.length > 0 && (
                                  <div className="flex flex-wrap gap-1">
                                    {suggestion.genre_ids.slice(0, 3).map((genreId) => (
                                      <span 
                                        key={genreId} 
                                        className={`text-[10px] px-2 py-0.5 rounded-full border 
                                        ${selectedSuggestionIndex === index 
                                          ? 'bg-zinc-800 text-white border-zinc-700' 
                                          : 'bg-gold-500/10 text-gold-300 border-gold-500/20'}`}
                                      >
                                        {genreMap[genreId] || 'Genre'}
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </motion.div>
                        ))}
                        
                        {/* View All Results Button */}
                        <motion.button
                          onClick={() => {
                            navigate(`/search?query=${searchQuery}`);
                            onClose();
                          }}
                          className="w-full mt-4 text-center py-3 px-4 rounded-xl font-semibold
                            bg-gradient-to-r from-gold-600 to-gold-400 text-zinc-900
                            hover:from-gold-500 hover:to-gold-300 transition-all duration-300
                            shadow-lg flex items-center justify-center"
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
                        >
                          View All Results <FaArrowRight className="ml-2" />
                        </motion.button>
                      </div>
                    </div>
                  )}

                  {/* Recent Searches */}
                  {suggestions.length === 0 && recentSearches.length > 0 && (
                    <div className="mb-6">
                      <div className="flex items-center justify-between mb-3">
                        <h3 className="text-gold-400 font-semibold px-2">Recent Searches</h3>
                        <button 
                          onClick={handleClearRecentSearches}
                          className="text-xs text-gold-500/70 hover:text-gold-500 transition-colors"
                        >
                          Clear All
                        </button>
                      </div>
                      
                      <div className="space-y-2">
                        {recentSearches.map((search, index) => (
                          <motion.div
                            key={search.id}
                            onClick={() => handleRecentSearchClick(search)}
                            className="flex items-center p-3 rounded-xl cursor-pointer hover:bg-gold-500/20 transition-colors"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ 
                              opacity: 1, 
                              x: 0,
                              transition: { delay: index * 0.05 }
                            }}
                            whileHover={{ x: 5 }}
                          >
                            <div className="w-8 h-8 rounded-full bg-gold-500/20 flex items-center justify-center mr-3">
                              <FaHistory className="text-gold-500" />
                            </div>
                            <div className="flex-grow">
                              <div className="text-gold-300">{search.title}</div>
                              <div className="text-xs text-gold-500/70">
                                {search.media_type === 'search' ? 'Search Query' : 
                                 search.media_type === 'person' ? 'Person' :
                                 search.media_type === 'movie' ? 'Movie' : 'TV Show'}
                              </div>
                            </div>
                            <span className="text-gold-500/50 text-xs">
                              {new Date(search.timestamp).toLocaleDateString()}
                            </span>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Quick Links - When no suggestions or query */}
                  {suggestions.length === 0 && !searchQuery && (
                    <div className="mt-3">
                      <h3 className="text-gold-400 font-semibold px-2 mb-3">Quick Links</h3>
                      
                      <div className="grid grid-cols-2 gap-3">
                        <motion.div
                          whileHover={{ scale: 1.02, y: -2 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => {
                            navigate('/trending');
                            onClose();
                          }}
                          className="bg-gradient-to-br from-red-500/20 to-amber-500/20 p-4 rounded-xl cursor-pointer flex items-center border border-gold-500/10 hover:border-gold-500/30 transition-all"
                        >
                          <div className="w-12 h-12 rounded-full bg-gradient-to-br from-red-500 to-amber-500 flex items-center justify-center mr-3 shadow-lg">
                            <FaTrophy className="text-white text-xl" />
                          </div>
                          <div>
                            <h4 className="font-semibold text-gold-300">Trending</h4>
                            <p className="text-xs text-gold-400/70">Popular right now</p>
                          </div>
                        </motion.div>

                        <motion.div
                          whileHover={{ scale: 1.02, y: -2 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => {
                            navigate('/filter');
                            onClose();
                          }}
                          className="bg-gradient-to-br from-purple-500/20 to-blue-500/20 p-4 rounded-xl cursor-pointer flex items-center border border-gold-500/10 hover:border-gold-500/30 transition-all"
                        >
                          <div className="w-12 h-12 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center mr-3 shadow-lg">
                            <FaFilter className="text-white text-xl" />
                          </div>
                          <div>
                            <h4 className="font-semibold text-gold-300">Advanced Filter</h4>
                            <p className="text-xs text-gold-400/70">Discover content</p>
                          </div>
                        </motion.div>

                        <motion.div
                          whileHover={{ scale: 1.02, y: -2 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => {
                            navigate('/movies');
                            onClose();
                          }}
                          className="bg-gradient-to-br from-blue-500/20 to-cyan-500/20 p-4 rounded-xl cursor-pointer flex items-center border border-gold-500/10 hover:border-gold-500/30 transition-all"
                        >
                          <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-cyan-500 flex items-center justify-center mr-3 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h18M3 16h18" />
                            </svg>
                          </div>
                          <div>
                            <h4 className="font-semibold text-gold-300">Movies</h4>
                            <p className="text-xs text-gold-400/70">Browse all films</p>
                          </div>
                        </motion.div>

                        <motion.div
                          whileHover={{ scale: 1.02, y: -2 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => {
                            navigate('/tv');
                            onClose();
                          }}
                          className="bg-gradient-to-br from-green-500/20 to-emerald-500/20 p-4 rounded-xl cursor-pointer flex items-center border border-gold-500/10 hover:border-gold-500/30 transition-all"
                        >
                          <div className="w-12 h-12 rounded-full bg-gradient-to-br from-green-500 to-emerald-500 flex items-center justify-center mr-3 shadow-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                          </div>
                          <div>
                            <h4 className="font-semibold text-gold-300">TV Shows</h4>
                            <p className="text-xs text-gold-400/70">Series & episodes</p>
                          </div>
                        </motion.div>
                      </div>

                      {/* Popular Categories */}
                      <div className="mt-5">
                        <h3 className="text-gold-400 font-semibold px-2 mb-3">Popular Categories</h3>
                        <div className="flex flex-wrap gap-2">
                          {[
                            { name: 'Action', color: 'from-red-600 to-red-400' },
                            { name: 'Comedy', color: 'from-yellow-500 to-yellow-300' },
                            { name: 'Drama', color: 'from-blue-600 to-blue-400' },
                            { name: 'Horror', color: 'from-purple-600 to-purple-400' },
                            { name: 'Sci-Fi', color: 'from-cyan-500 to-cyan-300' },
                            { name: 'Animation', color: 'from-green-500 to-green-300' }
                          ].map(category => (
                            <motion.div 
                              key={category.name}
                              className={`px-4 py-2 rounded-full cursor-pointer bg-gradient-to-r ${category.color} text-black font-medium text-sm`}
                              whileHover={{ scale: 1.05, y: -2 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() => {
                                navigate(`/category/${category.name.toLowerCase()}`);
                                onClose();
                              }}
                            >
                              {category.name}
                            </motion.div>
                          ))}
                        </div>
                      </div>

                      {/* Search Tips */}
                      <div className="mt-6 bg-gold-500/10 rounded-xl p-4 border border-gold-500/20">
                        <h4 className="text-gold-400 font-semibold mb-2">Search Tips</h4>
                        <ul className="text-xs text-gold-500/70 space-y-1">
                          <li>• Try searching for titles, genres, actors, or directors</li>
                          <li>• Use more specific terms for better results</li>
                          <li>• You can search by year, e.g. "Action movies 2023"</li>
                          <li>• Press Ctrl+K (or Cmd+K) anywhere to open this search</li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default SearchModal;