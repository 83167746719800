import React, { useState, useEffect } from 'react';
import { 
  fetchZoroAnimeInfo, 
  searchZoroAnime, 
  getZoroEpisodeSources,
  getZoroEpisodeServers
} from '../services/zoroConsumet';

const TestZoro = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedAnime, setSelectedAnime] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [streamingSources, setStreamingSources] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle search
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      const results = await searchZoroAnime(searchQuery);
      setSearchResults(results);
    } catch (err) {
      setError('Error searching anime: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle anime selection
  const handleAnimeSelect = async (animeId) => {
    setLoading(true);
    setError(null);
    
    try {
      const animeInfo = await fetchZoroAnimeInfo(animeId);
      setSelectedAnime(animeInfo);
      setEpisodes(animeInfo.episodes || []);
    } catch (err) {
      setError('Error fetching anime info: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle episode selection
  const handleEpisodeSelect = async (episodeId) => {
    setLoading(true);
    setError(null);
    
    try {
      const [sources, servers] = await Promise.all([
        getZoroEpisodeSources(episodeId),
        getZoroEpisodeServers(episodeId)
      ]);
      
      setSelectedEpisode({ id: episodeId, sources, servers });
      setStreamingSources({ sources, servers });
    } catch (err) {
      setError('Error fetching episode sources: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <h1 className="text-3xl font-bold mb-8">Zoro API Test Page</h1>

      {/* Search Form */}
      <form onSubmit={handleSearch} className="mb-8">
        <div className="flex gap-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search anime..."
            className="flex-1 p-2 rounded bg-gray-800 text-white"
          />
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </form>

      {/* Error Display */}
      {error && (
        <div className="p-4 mb-4 bg-red-500/20 border border-red-500 rounded">
          {error}
        </div>
      )}

      {/* Search Results */}
      {searchResults.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Search Results</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {searchResults.map((anime) => (
              <div
                key={anime.id}
                className="p-4 bg-gray-800 rounded cursor-pointer hover:bg-gray-700"
                onClick={() => handleAnimeSelect(anime.id)}
              >
                <img
                  src={anime.image}
                  alt={anime.title}
                  className="w-full h-48 object-cover rounded mb-2"
                />
                <h3 className="font-bold">{anime.title}</h3>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Selected Anime Details */}
      {selectedAnime && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Anime Details</h2>
          <div className="bg-gray-800 p-4 rounded">
            <div className="flex gap-4 mb-4">
              <img
                src={selectedAnime.image}
                alt={selectedAnime.title}
                className="w-48 h-72 object-cover rounded"
              />
              <div>
                <h3 className="text-xl font-bold mb-2">{selectedAnime.title}</h3>
                <p className="mb-2">{selectedAnime.description}</p>
                <div className="flex gap-2 flex-wrap">
                  {selectedAnime.genres?.map((genre) => (
                    <span
                      key={genre}
                      className="px-2 py-1 bg-blue-600 rounded text-sm"
                    >
                      {genre}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* Episodes List */}
            <div>
              <h3 className="text-xl font-bold mb-2">Episodes</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2">
                {episodes.map((episode) => (
                  <button
                    key={episode.id}
                    onClick={() => handleEpisodeSelect(episode.id)}
                    className={`p-2 rounded ${
                      selectedEpisode?.id === episode.id
                        ? 'bg-blue-600'
                        : 'bg-gray-700 hover:bg-gray-600'
                    }`}
                  >
                    Episode {episode.number}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Streaming Sources */}
      {streamingSources && (
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Streaming Sources</h2>
          <div className="bg-gray-800 p-4 rounded">
            <pre className="overflow-x-auto">
              {JSON.stringify(streamingSources, null, 2)}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestZoro;
