import { useEffect } from 'react';

/**
 * Custom hook to handle search keyboard shortcuts
 * @param {Function} toggleSearch - Function to toggle the search modal
 * @param {boolean} isOpen - Whether the search modal is currently open
 */
const useSearchShortcuts = (toggleSearch, isOpen = false) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Toggle search modal with Ctrl+K or Cmd+K
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        e.preventDefault();
        toggleSearch();
      }
      
      // Close modal with Escape
      if (e.key === 'Escape' && isOpen) {
        toggleSearch(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleSearch, isOpen]);
};

export default useSearchShortcuts;
