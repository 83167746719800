import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast, Toaster } from 'react-hot-toast';
import { 
  FaUser, 
  FaLock, 
  FaEnvelope, 
  FaEye, 
  FaEyeSlash, 
  FaCheck,
  FaTimes,
  FaShieldAlt,
  FaUserPlus,
  FaArrowRight
} from 'react-icons/fa';

const Signup = () => {
  // Form state
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  
  // UI state
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1); // Multi-step form: 1=basic info, 2=preferences
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  
  // Password validation state
  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    hasMinLength: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false
  });

  const navigate = useNavigate();
  const usernameRef = useRef(null);
  
  // Toast styling
  const toastOptions = {
    success: {
      style: {
        background: 'rgba(15, 15, 20, 0.9)',
        color: '#10b981',
        border: '1px solid #10b981',
        borderRadius: '12px',
        padding: '16px',
        backdropFilter: 'blur(10px)'
      },
      iconTheme: {
        primary: '#10b981',
        secondary: '#FFFAEE',
      },
      duration: 4000
    },
    error: {
      style: {
        background: 'rgba(15, 15, 20, 0.9)',
        color: '#ef4444',
        border: '1px solid #ef4444',
        borderRadius: '12px',
        padding: '16px',
        backdropFilter: 'blur(10px)'
      },
      iconTheme: {
        primary: '#ef4444',
        secondary: '#FFFAEE',
      },
      duration: 4000
    },
    info: {
      style: {
        background: 'rgba(15, 15, 20, 0.9)',
        color: '#a89048',
        border: '1px solid #a89048',
        borderRadius: '12px',
        padding: '16px',
        backdropFilter: 'blur(10px)'
      },
      iconTheme: {
        primary: '#a89048',
        secondary: '#FFFAEE',
      },
      duration: 3000
    }
  };

  // Animation variants
  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.4
      }
    }
  };

  // Auto-focus first input field on load
  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  // Password strength validator
  useEffect(() => {
    const { password } = formData;
    
    // Check password criteria
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    
    // Calculate score (0-4)
    let score = 0;
    if (hasMinLength) score++;
    if (hasUpperCase) score++;
    if (hasNumber) score++;
    if (hasSpecialChar) score++;
    
    setPasswordStrength({
      score,
      hasMinLength,
      hasUpperCase,
      hasNumber,
      hasSpecialChar
    });
    
  }, [formData.password]);

  // Form handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNextStep = () => {
    // Validate first step
    if (!formData.username.trim()) {
      toast.error('Please enter a username', toastOptions.error);
      return;
    }
    
    if (!formData.email.trim()) {
      toast.error('Please enter an email address', toastOptions.error);
      return;
    }
    
    // Email regex validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address', toastOptions.error);
      return;
    }
    
    // Password validation
    if (passwordStrength.score < 3) {
      toast.error('Please create a stronger password', toastOptions.error);
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match', toastOptions.error);
      return;
    }
    
    // Proceed to next step
    setStep(2);
    toast.success('Great! Just a few more details', toastOptions.success);
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!acceptedTerms) {
      toast.error('Please accept the terms & conditions', toastOptions.error);
      return;
    }
    
    setIsSubmitting(true);
    
    // Simulate API call
    setTimeout(() => {
      try {
        // Create user object
        const userInfo = {
          username: formData.username,
          email: formData.email,
          recoveryCode: generateRecoveryCode(),
          profilePicture: `https://ui-avatars.com/api/?name=${encodeURIComponent(formData.username)}&background=2C3E50&color=ECF0F1&bold=true`,
          joinDate: new Date().toISOString(),
          subscription: 'basic'
        };
        
        // Store in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        
        // Show success toast
        toast.success('Account created successfully!', toastOptions.success);
        
        // Redirect after toast (with delay)
        setTimeout(() => {
          navigate('/profile');
        }, 1500);
        
      } catch (error) {
        console.error('Signup error:', error);
        toast.error('Something went wrong. Please try again.', toastOptions.error);
        setIsSubmitting(false);
      }
    }, 1500);
  };
  
  // Generate a recovery code
  const generateRecoveryCode = () => {
    const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Omitting confusing chars like O, 0, 1, I
    return Array.from(
      crypto.getRandomValues(new Uint32Array(8)), 
      x => chars[x % chars.length]
    ).join('');
  };
  
  // Password strength indicator
  const PasswordStrengthMeter = () => {
    const getColor = () => {
      if (passwordStrength.score === 0) return 'bg-zinc-700';
      if (passwordStrength.score === 1) return 'bg-red-500';
      if (passwordStrength.score === 2) return 'bg-yellow-500';
      if (passwordStrength.score === 3) return 'bg-blue-500';
      return 'bg-green-500';
    };
    
    const getLabel = () => {
      if (passwordStrength.score === 0) return '';
      if (passwordStrength.score === 1) return 'Weak';
      if (passwordStrength.score === 2) return 'Fair';
      if (passwordStrength.score === 3) return 'Good';
      return 'Strong';
    };
    
    return (
      <div className="mt-2">
        <div className="flex justify-between items-center">
          <div className="w-full bg-zinc-800 rounded-full h-1.5 mr-2">
            <div className={`h-1.5 rounded-full transition-all duration-500 ${getColor()}`} 
                 style={{ width: `${passwordStrength.score * 25}%` }}></div>
          </div>
          <div className="text-xs min-w-[40px] text-right font-medium" 
               style={{ color: getColor() === 'bg-zinc-700' ? '#525252' : '' }}>
            {getLabel()}
          </div>
        </div>
      </div>
    );
  };

  // Background particles effect
  const BackgroundEffect = () => (
    <motion.div 
      className="absolute inset-0 z-0 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {[...Array(20)].map((_, i) => (
        <motion.div 
          key={i}
          className="absolute bg-gold-500/10 rounded-full"
          initial={{ 
            scale: 0, 
            top: `${Math.random() * 100}%`, 
            left: `${Math.random() * 100}%` 
          }}
          animate={{ 
            scale: [0, 2, 0],
            rotate: 360
          }}
          transition={{
            duration: Math.random() * 10 + 5,
            repeat: Infinity,
            delay: Math.random() * 2
          }}
          style={{
            width: `${Math.random() * 200 + 50}px`,
            height: `${Math.random() * 200 + 50}px`
          }}
        />
      ))}
    </motion.div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center relative bg-gradient-to-br from-black via-gray-900 to-black overflow-hidden">
      {/* Toast notifications */}
      <Toaster position="top-right" />
      
      {/* Animated background */}
      <BackgroundEffect />
      
      {/* Main content */}
      <motion.div 
        className="relative z-10 w-full max-w-lg p-8 bg-background/60 backdrop-blur-xl rounded-2xl shadow-2xl border border-gold-500/20 mt-14 mx-4"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        {/* Header */}
        <motion.div 
          className="text-center mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gold-400 to-gold-600">
            Create Account
          </h1>
          <p className="text-gold-300 mt-2">Join Streamify and start streaming</p>
        </motion.div>

        {/* Form content */}
        <AnimatePresence mode="wait">
          {isSubmitting ? (
            <motion.div 
              key="loading"
              className="flex flex-col items-center justify-center py-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="w-16 h-16 border-t-4 border-gold-500 border-solid rounded-full animate-spin mb-4"></div>
              <p className="text-gold-300 text-lg">Creating your account...</p>
            </motion.div>
          ) : (
            <motion.form 
              key={`signup-step-${step}`}
              onSubmit={step === 1 ? handleNextStep : handleSubmit}
              variants={fadeVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="space-y-6"
            >
              {step === 1 ? (
                // Step 1: Account information
                <>
                  {/* Username field */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaUser className="text-gold-500" />
                    </div>
                    <input
                      type="text"
                      name="username"
                      ref={usernameRef}
                      value={formData.username}
                      onChange={handleChange}
                      placeholder="Username"
                      className="w-full pl-10 pr-4 py-3 bg-background/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                    />
                  </div>

                  {/* Email field */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaEnvelope className="text-gold-500" />
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                      className="w-full pl-10 pr-4 py-3 bg-background/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                    />
                  </div>

                  {/* Password field */}
                  <div className="space-y-2">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FaLock className="text-gold-500" />
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Password"
                        className="w-full pl-10 pr-10 py-3 bg-background/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                      />
                      <div 
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash className="text-gold-500" /> : <FaEye className="text-gold-500" />}
                      </div>
                    </div>
                    
                    {/* Password strength meter */}
                    {formData.password && <PasswordStrengthMeter />}
                    
                    {/* Password requirements */}
                    {formData.password && (
                      <div className="grid grid-cols-2 gap-2 text-xs mt-2">
                        <div className="flex items-center">
                          {passwordStrength.hasMinLength ? 
                            <FaCheck className="text-green-500 mr-1" /> : 
                            <FaTimes className="text-red-500 mr-1" />}
                          <span className={passwordStrength.hasMinLength ? "text-green-500" : "text-gray-400"}>
                            8+ Characters
                          </span>
                        </div>
                        <div className="flex items-center">
                          {passwordStrength.hasUpperCase ? 
                            <FaCheck className="text-green-500 mr-1" /> : 
                            <FaTimes className="text-red-500 mr-1" />}
                          <span className={passwordStrength.hasUpperCase ? "text-green-500" : "text-gray-400"}>
                            Uppercase Letter
                          </span>
                        </div>
                        <div className="flex items-center">
                          {passwordStrength.hasNumber ? 
                            <FaCheck className="text-green-500 mr-1" /> : 
                            <FaTimes className="text-red-500 mr-1" />}
                          <span className={passwordStrength.hasNumber ? "text-green-500" : "text-gray-400"}>
                            Number
                          </span>
                        </div>
                        <div className="flex items-center">
                          {passwordStrength.hasSpecialChar ? 
                            <FaCheck className="text-green-500 mr-1" /> : 
                            <FaTimes className="text-red-500 mr-1" />}
                          <span className={passwordStrength.hasSpecialChar ? "text-green-500" : "text-gray-400"}>
                            Special Character
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Confirm Password field */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaShieldAlt className="text-gold-500" />
                    </div>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      placeholder="Confirm Password"
                      className={`w-full pl-10 pr-10 py-3 bg-background/40 border ${
                        formData.confirmPassword && formData.password !== formData.confirmPassword
                          ? 'border-red-500'
                          : 'border-gold-500/30'
                      } rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300`}
                    />
                    <div 
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <FaEyeSlash className="text-gold-500" /> : <FaEye className="text-gold-500" />}
                    </div>
                  </div>
                  
                  {/* Password match indicator */}
                  {formData.confirmPassword && (
                    <div className="flex items-center text-xs">
                      {formData.password === formData.confirmPassword ? (
                        <>
                          <FaCheck className="text-green-500 mr-1" />
                          <span className="text-green-500">Passwords match</span>
                        </>
                      ) : (
                        <>
                          <FaTimes className="text-red-500 mr-1" />
                          <span className="text-red-500">Passwords don't match</span>
                        </>
                      )}
                    </div>
                  )}

                  {/* Continue button */}
                  <motion.button 
                    type="submit"
                    className="w-full py-3 bg-gold-500 rounded-xl text-black font-semibold hover:bg-gold-600 transition-all duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <span>Continue</span>
                    <FaArrowRight className="ml-2" />
                  </motion.button>
                </>
              ) : (
                // Step 2: Terms & Final Signup
                <>
                  {/* Account summary */}
                  <div className="bg-background/60 p-4 rounded-xl border border-gold-500/20">
                    <h3 className="text-gold-400 font-medium mb-2">Account Summary</h3>
                    <div className="flex items-center mb-2">
                      <FaUser className="text-gold-500 mr-2" />
                      <span className="text-white">{formData.username}</span>
                    </div>
                    <div className="flex items-center">
                      <FaEnvelope className="text-gold-500 mr-2" />
                      <span className="text-white">{formData.email}</span>
                    </div>
                  </div>
                  
                  {/* Terms and conditions */}
                  <div className="p-4 bg-black/20 backdrop-blur rounded-xl border border-gold-500/10 max-h-[120px] overflow-y-auto scrollbar-thin scrollbar-thumb-gold-500/50 scrollbar-track-white/5">
                    <h3 className="text-gold-400 font-medium mb-2">Terms & Conditions</h3>
                    <p className="text-xs text-gray-300">
                      By creating an account with Streamify, you agree to our Terms of Service and Privacy Policy. 
                      We respect your privacy and are committed to protecting your personal data. 
                      Your information will be used to personalize your streaming experience and provide 
                      you with content recommendations. You can update your preferences anytime in your account settings.
                    </p>
                  </div>

                  {/* Terms acceptance checkbox */}
                  <div className="flex items-start space-x-2">
                    <div className="flex h-6 items-center">
                      <input
                        id="terms"
                        type="checkbox"
                        checked={acceptedTerms}
                        onChange={() => setAcceptedTerms(!acceptedTerms)}
                        className="h-4 w-4 rounded border-gold-500/30 bg-black/50 text-gold-500 focus:ring-2 focus:ring-gold-500"
                      />
                    </div>
                    <label htmlFor="terms" className="text-sm text-gray-300">
                      I accept the <Link to="/terms" className="text-gold-500 hover:text-gold-400">Terms of Service</Link> and <Link to="/privacy" className="text-gold-500 hover:text-gold-400">Privacy Policy</Link>
                    </label>
                  </div>

                  {/* Create Account button */}
                  <motion.button 
                    type="submit"
                    className="w-full py-3 bg-gold-500 rounded-xl text-black font-semibold hover:bg-gold-600 transition-all duration-300 flex items-center justify-center gap-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    disabled={!acceptedTerms}
                  >
                    <FaUserPlus />
                    <span>Create Account</span>
                  </motion.button>
                  
                  {/* Back button */}
                  <div className="text-center">
                    <button 
                      type="button"
                      onClick={() => setStep(1)}
                      className="text-gold-400 hover:text-gold-300 text-sm"
                    >
                      Go Back
                    </button>
                  </div>
                </>
              )}
              
              {/* Form footer */}
              <div className="mt-6 text-center border-t border-white/10 pt-4">
                <p className="text-gray-400 text-sm">
                  Already have an account? {" "}
                  <Link to="/login" className="text-gold-500 hover:text-gold-400">
                    Log In
                  </Link>
                </p>
              </div>
            </motion.form>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default Signup;
